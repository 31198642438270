import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination2";
import {getPartnerMctSttl} from "../../redux/reducers/deposit/PartnerMctSttlReducer";
import PartnerMctSttl from "../../components/deposit/PartnerMctSttlList";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {insertAdminLog} from "../../lib/api";

const PartnerMctSttlContainer = ({userId}) => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const userInfo = useSelector((state) => state.userInfo)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.partnerMctSttl)
    const userState = useSelector((state) => state.userInfoCombine)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [userGbCd, setUserGbCd] = useState("");
    const [dateType, setDateType] = useState(0);

    const [userGbList, setUserGbList] = useState([]);
    const [selectedNos, setSelectedNos] = useState([]);

    const getCmmnCd = () => {
        if(userGbList.length === 0){
            axios.post('/partner/admin/cmmnCdList', {
                cmmnCdId : '0029',
            }).then((res) => {
                setUserGbList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getPartnerMctSttl({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: (userId && userId !== "")? '2': initialState.searchGbCd,
            selectedNos: selectedNos,
            userId: userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
        setSearchQuery(userId)

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfo.id,
            logContent: "포인트내역"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getPartnerMctSttl({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            selectedNos: selectedNos,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getPartnerMctSttl({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            selectedNos: selectedNos,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const onClickSearch = (e) => {

        //alert(JSON.stringify(selectedNos));
        dispatch(getPartnerMctSttl({
            page: initialState.page,
            pageSize: initialState.pageSize,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            selectedNos: selectedNos,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const onClickReset = (e) => {
        window.location.reload();
    }

    const onRefresh = () => {
        dispatch(getPartnerMctSttl({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            selectedNos: selectedNos,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }


    const exportExcel = ()=>{
        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {
            let jsonObject = { // JSON 객체를 생성합니다.
                순서: initialState.totalList[i].no? initialState.totalList[i].no :'',
                부본사: initialState.totalList[i].psnl_cd? initialState.totalList[i].psnl_cd :'',
                가맹점명: initialState.totalList[i].mrch_cd? initialState.totalList[i].mrch_cd :'',
                아이디: initialState.totalList[i].id? initialState.totalList[i].id :'',
                닉네임: initialState.totalList[i].nickname? initialState.totalList[i].nickname :'',
                구분: initialState.totalList[i].point_type_cd? initialState.totalList[i].point_type_cd :'',
                이전금액: initialState.totalList[i].before_amt? initialState.totalList[i].before_amt :'',
                요청금액: initialState.totalList[i].request_amt? initialState.totalList[i].request_amt :'',
                현재금액: initialState.totalList[i].now_amt? initialState.totalList[i].now_amt :'',
                일시: initialState.totalList[i].updatedAt? initialState.totalList[i].updatedAt :'',
                관련번호: initialState.totalList[i].involvement_no? initialState.totalList[i].involvement_no :'',
                메모: initialState.totalList[i].memo? initialState.totalList[i].memo :'',
                수행아이디: initialState.totalList[i].reg_id? initialState.totalList[i].reg_id :'',
                수행아이피: initialState.totalList[i].reg_ip? initialState.totalList[i].reg_ip :''
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '포인트거래내역');
        XLSX.writeFile(workBook, '포인트거래내역.xlsx');

    }

    const userGbCdChangeHandler = (e) => {
        alert('userGbCd000 >>>>>>>>>>>> '+e.currentTarget.value);
        setUserGbCd(e.currentTarget.value);

        alert('userGbCd >>>>>>>>>>>> '+userGbCd);
        onRefresh();
    }

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }


    const dateTypeChangeHandler = (type) => {
        setDateType(type)

        dispatch(getPartnerMctSttl({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: type,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            selectedNos: selectedNos,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const addMinDate = () => {
        setStartDate(moment().add(-24, "hour").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add90Date = () => {
        setStartDate(moment().add(-90, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add60Date = () => {
        setStartDate(moment().add(-60, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add30Date = () => {
        setStartDate(moment().add(-30, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const add7Date = () => {
        setStartDate(moment().add(-7, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }
    const addMaxDate = () => {
        setStartDate(moment().add(-730, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
    }


    const checkHandler = (checked, no) => {

        //alert("no >>> "+no);

        let selectedNos2 = []
        if(checked) {
            selectedNos2 = [...selectedNos, no]
            setSelectedNos([...selectedNos, no])
        }
        else {
            selectedNos2 = selectedNos.filter((id) => id !== no)
            setSelectedNos(selectedNos.filter((id) => id !== no))
        }

    }

    const allCheckHandler = (e) => {
        const checks = [];
        checks.push('01','02','03','04','05','06','07','08','09','10')
        checks.push('11','12','13','14','15','16','17','18','19','20')
        checks.push('21','22','23','24','25','26','27','28','29','30')
        checks.push('31','32','33','34','35','36','37','38','39','40')
        checks.push('41','42','43','44','45','46','47','48','49','50')
        checks.push('51','52','53','54','55','56','57','58','59','60')
        checks.push('61','62','63','64','65','66','67','68','69','70')
        checks.push('71','72','73','74')

        setSelectedNos(checks)
    }

    const allDisableCheckHandler = (e) => {
        const checks = [];
        setSelectedNos(checks)
    }

    const importantCheckHandler = (e) => {
        const checks = [];
        checks.push('08')
        checks.push('11')
        setSelectedNos(checks)
    }


    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">정산내역</font>
                    &nbsp;
                    <select
                        className="form-select sm-select"
                        defaultValue={120}
                        style={{
                            width: "100px"
                        }}
                        value={initialState.pageSize === "999"? "모두" : initialState.pageSize}
                        onChange={(e) => {
                            dispatch(getPartnerMctSttl({
                                page: initialState.page,
                                pageSize: parseInt(e.target.value),
                                order: initialState.order,
                                query: searchQuery,
                                startDate: startDate? startDate : initialState.startDate,
                                endDate: endDate? endDate : initialState.endDate,
                                userGbCd: initialState.userGbCd,
                                searchGbCd: initialState.searchGbCd,
                                selectedNos: selectedNos,
                                userId: userState.userId,
                                sessionId: userInfo.id,
                                sessionLevel: userInfo.level
                            }))
                        }}
                    >
                        <option value={30}>30개</option>
                        <option value={60}>60개</option>
                        <option value={120}>120개</option>
                        <option value={320}>320개</option>
                        <option value={500}>500개</option>
                        <option value={1000}>1000개</option>
                        <option value={5000}>5000개</option>
                    </select>
                    <table   width={"86%"}>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "80px"
                                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                                &nbsp;
                                <button style={{
                                    width: "120px", backgroundColor :"gray"
                                }} type="submit" className="btn btn-primary" onClick={onClickReset}>새로고침(자동보류)</button>
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
        </div>
        <PartnerMctSttl partnerMctSttl={initialState.partnerMctSttl} onRefresh={onRefresh} />
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default PartnerMctSttlContainer