import React, {useState} from 'react';
import {Outlet, useNavigate} from "react-router-dom";

const BettingMngIndex = () => {

    const navigate = useNavigate();
    const [activeNav, setActiveNav] = useState();
    const ontoggle = (index, address) => {
        setActiveNav(index)
        navigate(address)
    }
    const menuArr = [
        {name:"베팅내역", address:"/BettingMng/BettingItmPage"},
        {name:"[Kgon]베팅내역", address:"/BettingMng/KgonBettingItmPage"},
        /*
        {name:"자동게임통계", address:"/BettingMng/AutoGameStcsPage"},
        {name:"일반게임통계", address:"/BettingMng/NormGameStcsPage"},
        {name:"회원별게임통계", address:"/BettingMng/UserByGameStcsPage"},
        {name:"[Inplay]베팅내역", address:"/BettingMng/InplayBettingItmPage"},
        {name:"[Inplay]회원별게임통계", address:"/BettingMng/InplayUserByGameStcsPage"},
        {name:"[토큰]베팅내역", address:"/BettingMng/ToknBettingItmPage"},
        {name:"[토큰]회원별게임통계", address:"/BettingMng/ToknUserByGameStcsPage"},
        {name:"[Kgon]베팅내역", address:"/BettingMng/KgonBettingItmPage"},
        {name:"[Kgon]회원별게임통계", address:"/BettingMng/KgonUserByGameStcsPage"},
        */
    ]

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="collapse navbar-collapse" style={{
                    marginLeft: "290px",
                    height: "10px"
                }}>
                    <ul className="navbar-nav text-center">
                        {menuArr.map((menu,index) => (
                            <li key={index} className="mx-2" onClick={() => ontoggle(index, menu.address)}>
                                <div className={activeNav === index ? "nav-item active" : "nav-item"} style={{cursor: "pointer", fontSize: "12px"}}>
                                    {menu.name}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

            </nav>
            <div style={{
                paddingLeft: "10px"
            }}>
                <Outlet />
            </div>

        </div>


    )
}

export default BettingMngIndex;