import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"
import * as state from "react-dom/test-utils";

const initialState = {
    timeList : [
        { id : 0 , nm : "제한없음"},
        { id : 5 , nm : "5분"},
        { id : 15 , nm : "15분"},
        { id : 30 , nm : "30분"},
        { id : 60 , nm : "1시간"},
        { id : 360 , nm : "6시간"},
        { id : 720 , nm : "12시간"},
        { id : 1440 , nm : "24시간"},
        { id : 2880 , nm : "2일"},
        { id : 10080 , nm : "7일"},
        { id : 20160 , nm : "14일"},
        { id : 44640 , nm : "31일"},
        { id : 86400 , nm : "60일"},
        { id : 129600 , nm : "90일"},
        { id : 172800 , nm : "120일"},
    ], //분담는 객체
    cntLimitList : [], //사용횟수담는객체

    orgGb : [],
    org : [],
    org1 : [],
    org2 : [],
    org3 : [],

    isOpen : false,

    input : {
        recommend_key : "",
        link : "",
        recommend_org_admin_id : "",
        recommend_user_id : "",
        reg_join_point : 0,
        reg_charge_point : 0,
        first_charge_point : 0,
        mae_charge_point : 0,
        time_limit : 0,
        cnt_limit : 0,
        use_cnt : 0
    }
}

export const statsSlice = createSlice({
    name: 'RecommendAddReducer',
    initialState,
    reducers: {
        resetState: () => initialState,

        setRecommedKey(state,action){
            state.input.recommend_key = action.payload;
        },
        setLink(state,action){
            state.input.link = action.payload;
        },
        setRecommendOrgAdminId(state,action){
            state.input.recommend_org_admin_id = action.payload;
        },
        setRecommendUserId(state,action){
            state.input.recommend_user_id = action.payload;
        },
        setRegJoinPoint(state,action){
            state.input.reg_join_point = action.payload;
        },
        setRegChargePoint(state,action){
            state.input.reg_charge_point = action.payload;
        },
        setFirstChargePoint(state,action){
            state.input.first_charge_point = action.payload;
        },
        setMaeChargePoint(state,action){
            state.input.mae_charge_point = action.payload;
        },
        setTimeLimit(state,action){
            state.input.time_limit = action.payload;
        },
        setCntLimit(state,action){
            state.input.cnt_limit = action.payload;
        },
        setCntLimitList(state,action){
            const numberObjects = [];
            for (let i = 1; i <= 100; i++) {
                const numberObject = {
                    id: i,
                    nm: i + " 회",
                };
                numberObjects.push(numberObject);
            }
            state.cntLimitList = numberObjects;
        },
        setGb(state,action){
            state.gb = action.payload;
        },
        setOrg(state,action){
            state.org = action.payload;
        },
        setOrg1(state,action){
            state.org1 = action.payload;
        },
        setOrg2(state,action){
            state.org2 = action.payload;
        },
        setOrg3(state,action){
            state.org3 = action.payload;
        },
        setIsOpen(state,action){
            state.isOpen = action.payload;
        }

    }
});

export const {
    resetState,
    setRecommedKey,
    setLink,
    setRecommendOrgAdminId,
    setRecommendUserId,
    setRegJoinPoint,
    setRegChargePoint,
    setFirstChargePoint,
    setMaeChargePoint,
    setTimeLimit,
    setCntLimit,
    setUseCnt,
    setCntLimitList,
    setOrg1,
    setOrg2,
    setOrg3,
    setGb,
    setOrg,
    setIsOpen
} = statsSlice.actions;

export default statsSlice.reducer;