import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const customerSlice = createSlice({
    name: 'customer',
    initialState: {
        isLoading: false,
        error: null,
        customer: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        query: "",
        userGbCd: "",
        searchGb: "nicknameOrId",
        orgId: "",
        askGb: "",
        pGrade: "",
        selectedNos: "",
    },
    reducers: {
        getCustomers(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.userGbCd = action.payload.userGbCd;
            state.searchGb = action.payload.searchGb;
            state.query = action.payload.query;
            state.orgId = action.payload.orgId;
            state.askGb = action.payload.askGb;
            state.pGrade = action.payload.pGrade;
            state.selectedNos = action.payload.selectedNos;
        },
        failedGetCustomers(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setCustomers(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.customer = action.payload.result;
        },
    }
});

export const { getCustomers, failedGetCustomers, setCustomers } = customerSlice.actions;

export default customerSlice.reducer;