import React, {useEffect, useState} from "react";
import axios from "axios";
import CustomerList from "../../components/board/CustomerList";
import {useDispatch, useSelector} from "react-redux";
import {getCustomers} from "../../redux/reducers/board/CustomerReducer";
import Pagination from "../../components/common/Pagination";
import OrgSelect from "../../components/org/OrgSelect";
import PointGrade from "../../components/common/PointGrade";
import InterestUser from "../../components/common/InterestUser";

const CustomerContainer = ({userId}) => {

    const dispatch = useDispatch()
    const state = useSelector((state) => state.customer)
    const [searchQuery, setSearchQuery] = useState("");
    const [searchGb, setSearchGb] = useState("nicknameOrId");
    const [isWait, setIsWait] = useState(false)

    const userInfo = useSelector((state) => state.userInfo);

    const input = {
        id: userInfo.id,
        page: state.page,
        pageSize: state.pageSize,
        userGbCd: state.userGbCd,
        searchGb: state.searchGb,
        orgId: state.orgId,
        query: state.query,
        askGb: state.askGb,
        pGrade: state.pGrade,
        selectedNos: state.selectedNos
    }

    const onCheckedColor = (selectedNos) => {
        const fixInput = {...input}
        fixInput.selectedNos = [selectedNos]
        dispatch(getCustomers(fixInput))
    }

    useEffect(() => {
        const firstInput = {...input}
        if(userId && userId !== "") {
            setSearchQuery(userId)
            setSearchGb("id")
            input.searchGb = "id"
            input.query = userId
        }
        dispatch(getCustomers(firstInput))
    }, [])

    const deleteInUserPage = (type) => {
        axios.post('/admin/board/deleteCustomerByType', {
            type: type,
            adminId: userInfo.id
        }).then((res) => {
            if(res.data.result) {
                alert('완료')
            }
            else {
                alert('실패')
            }
        })
    }

    const onRefresh = () => {
        dispatch(getCustomers(input))
    }

    const changeQuery = (e) => {
        setSearchQuery(e.currentTarget.value)
    }

    const changeSearchGb = (e) => {
        setSearchGb(e.currentTarget.value)
    }
    const changeUserGbCd = (e) => {
        const fixInput = {...input}
        fixInput.userGbCd = e.currentTarget.value
        dispatch(getCustomers(fixInput))
    }
    const changeAskGb = (e) => {
        const fixInput = {...input}
        fixInput.askGb = e.currentTarget.value
        dispatch(getCustomers(fixInput))
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.currentTarget.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        const fixInput = {...input}
        fixInput.searchGb = searchGb
        fixInput.query = query
        dispatch(getCustomers(fixInput))
    }
    const handlePageChange = (page) => {
        const fixInput = {...input}
        fixInput.page = page
        dispatch(getCustomers(fixInput))
    }

    const setParentParam = (id) => {
        const fixInput = {...input}
        fixInput.orgId = id
        dispatch(getCustomers(fixInput))
    }

    const onChangePG = (selectedPGNos) => {
        const fixInput = {...input}
        fixInput.pGrade = selectedPGNos
        dispatch(getCustomers(fixInput))
    }

    return <div>
        <div className="sm-center-div-no-border-100" style={{height: "fit-content"}}>
            <div>
                <div className="sm-center-div">
                    <div className="me-1" style={{width: "300px", height: "20px"}}>
                        <div style={{zIndex : 998}}>
                            <OrgSelect setChildParam={setParentParam} id={0}/>
                        </div>
                    </div>


                    <select className="form-select sm-select" onChange={changeUserGbCd}>
                        <option value="" selected>::회원분류::</option>
                        <option value="01">대기회원</option>
                        <option value="02">무료회원</option>
                        <option value="03">유료회원</option>
                        <option value="04">VIP회원</option>
                        <option value="05">가라회원</option>
                        <option value="06">관리자</option>
                        <option value="07">최고관리자</option>
                    </select>
                    <select className="form-select sm-select" onChange={changeAskGb}>
                        <option value="All" selected>::고객문의구분선택::</option>
                        <option value="basic">기본답변</option>
                        <option value="account">입금계좌</option>
                    </select>
                    <select className="form-select sm-select" value={searchGb} onChange={changeSearchGb}>
                        <option value="nicknameOrId">닉네임 또는 아이디</option>
                        <option value="id">아이디</option>
                        <option value="nickname">닉네임</option>
                        <option value="cont">내용</option>
                        <option value="title">제목</option>
                        <option value="reply">댓글</option>
                    </select>
                    <input className="form-check-input sm-input" type="text" value={searchQuery} onChange={changeQuery} onKeyDown={inputPress}/>
                    <button onClick={(e) => {
                        onSearch(searchQuery)
                    }}>검색</button>
                    &nbsp;&nbsp;&nbsp;
                    <PointGrade onChangePG={onChangePG}/>
                </div>
            </div>
            <div style={{marginLeft: "auto"}}></div>
            <button style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "12px", height: "50px", width: "fit-content"}} onClick={onRefresh}>현재 페이지<br/>새로고침</button>
        </div>
        <div style={{display: "flex", paddingRight: "5px", paddingLeft: "5px"}}>
            <InterestUser onCheckedColor={onCheckedColor} />
        </div>
        <div className="sm-center-div-no-border">
            <button style={{ marginRight: "10px", backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content"}} onClick={(e) => {
                if(window.confirm("계좌문의 글을 사용자 쪽에서 삭제하시겠습니까? 관리자에는 그대로 남습니다.")) {
                    deleteInUserPage("account")
                }
            }}>계좌문의 글 사용자 삭제(복구 가능)</button>
            <button style={{ marginRight: "10px", backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content"}} onClick={(e) => {
                if(window.confirm("1/3번째 3일 전 까지의 고객센터 글을 사용자 페이지에서 삭제하시겠습니까?")) {
                    if (window.confirm("2/3번째 3일 전 까지의 고객센터 글을 사용자 페이지에서 삭제하시겠습니까?")) {
                        if (window.confirm("3/3번째 3일 전 까지의 고객센터 글을 사용자 페이지에서 삭제하시겠습니까?")) {
                            deleteInUserPage("3days")
                        }
                    }
                }
            }}>3일 이전까지 모두 사용자 삭제(복구 가능)</button>
            <button style={{ marginRight: "10px", backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content"}} onClick={(e) => {
                if(window.confirm("1/3번째 3일 전 까지의 고객센터 글을 사용자 페이지에서 삭제하시겠습니까?")) {
                    if (window.confirm("2/3번째 3일 전 까지의 고객센터 글을 사용자 페이지에서 삭제하시겠습니까?")) {
                        if (window.confirm("3/3번째 3일 전 까지의 고객센터 글을 사용자 페이지에서 삭제하시겠습니까?")) {
                            deleteInUserPage("1hour")
                        }
                    }
                }
            }}>1시간 전까지 모두 사용자 삭제(복구 가능)</button>
            <button style={{ marginRight: "10px", backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content"}} onClick={(e) => {
                deleteInUserPage("alert")
            }}>시스템알림 삭제(복구 불가)</button>
            <span style={{color: "red"}}>완전삭제의 경우 개발팀에 따로 요청해주세요</span>
        </div>

        <CustomerList state={state} onRefresh={onRefresh}/>
        <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange}/>
    </div>

}

export default CustomerContainer