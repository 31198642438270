import React, {useState, useEffect} from "react";
import DepositWithdrawLogContainer from "../../../../containers/deposit/DepositWithdrawLogContainer";

const MemberInformationDetailModal = (props) => {


    return (
        <div>
            <DepositWithdrawLogContainer />
        </div>
    )
}
export default MemberInformationDetailModal;