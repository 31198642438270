import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {setDisableYn, setMemberYnText, setMemberYnTextColor} from "../../../../redux/reducers/user/BlackMemberAddModalReducer";

const MemberInformationDetailModal = (props) => {


    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                준비중
            </div>
        </div>



    )
}
export default MemberInformationDetailModal;