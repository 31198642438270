import React, {useState, useEffect} from "react";
import CustomerContainer from "../../../../containers/board/CustomerContainer";

const MemberInformationDetailModal = (props) => {


    return (
        <div>
            <CustomerContainer />
        </div>
    )
}
export default MemberInformationDetailModal;