import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {NumericFormat} from "react-number-format";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";
const DepositWithdrawLog = ({depositWithdrawLog, onRefresh}) => {
    const dispatch = useDispatch()

    const depositWithdrawState = (state) => {
        switch(state) {
            case "01" :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}>
                                    <button style={{ backgroundColor: "green", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content"}}><font color="yellow">충전</font></button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            case "02" :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}>
                                    <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content"}}><font color="white">환전</font></button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            default : return state
        }
    }

    const stateNm = (state) => {
        switch(state) {
            case 0 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}>
                                    <button style={{ backgroundColor: "yellow", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content"}}><font color="black">대기</font></button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            case 1 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}><font color="#006400">확인중</font></td>
                            </tr>
                        </table>
                    </div>
                )
            case 2 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}>
                                    <button style={{ backgroundColor: "lightgreen", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content"}}><font color="black">완료</font></button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            case 3 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}>
                                    <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content"}}><font color="white">취소</font></button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            case 4 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}>
                                    <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content"}}><font color="white">취소</font></button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            case 5 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td align={"center"}><font color="#006400">확인중</font></td>
                            </tr>
                        </table>
                    </div>
                )
            default : return state
        }
    }

    const convertMinutesToDaysHoursMinutes = (totalMinutes) => {
        if(totalMinutes === 0){
            return '제한없음';
        }
        const days = Math.floor(((totalMinutes / (60 *60 )) % 24 )) === 0 ? "" : Math.floor(((totalMinutes / (60 *60 )) % 24 ));
        const hours = Math.floor(((totalMinutes / (60 )) % 60 )) === 0 ? "" : Math.floor(((totalMinutes / (60 )) % 60 ));
        const minutes = Math.floor((totalMinutes) % 60 ) === 0 ? "" : Math.floor((totalMinutes) % 60 );
        const daysNm = days === "" ?"":"시간";
        const hourNm = hours === "" ?"":"분";
        const minutesNm = minutes === "" ?"":"초";

        return days+daysNm+hours+hourNm+minutes+minutesNm;
    };


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>


                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead >
                        <tr>
                            <th style={{width: "3%"}}>log no</th>
                            <th style={{width: "5%"}}>사이트코드</th>
                            <th style={{width: "5%"}}>소속</th>
                            <th style={{width: "7%"}}>가맹점명</th>
                            <th style={{width: "5%"}}>도메인</th>
                            <th style={{width: "5%"}}>아이디</th>
                            <th style={{width: "5%"}}>닉네임</th>
                            <th style={{width: "5%"}}>예금주</th>
                            <th style={{width: "5%"}}>등급</th>
                            <th style={{width: "5%"}}>포인트등급</th>
                            <th style={{width: "3%"}}>구분</th>
                            <th style={{width: "3%"}}>대상번호</th>
                            <th style={{width: "3%"}}>이전상태</th>
                            <th style={{width: "3%"}}>처리상태</th>
                            <th style={{width: "5%"}}>금액</th>
                            <th style={{width: "7%"}}>처리일시</th>
                            <th style={{width: "3%"}}>처리간격</th>
                            <th style={{width: "5%"}}>수행아이디</th>
                            <th style={{width: "5%"}}>수행아이피</th>
                        </tr>
                        </thead>
                        {!depositWithdrawLog.length && (
                            <tbody key={depositWithdrawLog.no}>
                            <tr>
                                <td colSpan="18">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}

                        {depositWithdrawLog.map((depositWithdrawLog, index) => (
                            <tbody key={depositWithdrawLog.no}>
                            <tr>
                                <td align={"center"}>{depositWithdrawLog.no}</td>
                                <td align={"center"}>{depositWithdrawLog.site_cd}</td>
                                <td align={"center"}>{depositWithdrawLog.store_cd}</td>
                                <td align={"center"}>{depositWithdrawLog.mrch_cd}</td>
                                <td align={"center"}>{depositWithdrawLog.domain}</td>
                                <td align={"center"} style={{backgroundColor:depositWithdrawLog.bg_color, cursor: "pointer"}} onClick={(e) => {
                                        dispatch(openUserSimpleInfo({
                                            isOpen: true,
                                            openId: depositWithdrawLog.id,
                                            xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                        }))
                                    }}>
                                    <font color={depositWithdrawLog.font_color}>{depositWithdrawLog.id}</font>
                                </td>
                                <td align={"center"} style={{backgroundColor:depositWithdrawLog.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: depositWithdrawLog.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}>
                                    <font color={depositWithdrawLog.font_color}>{depositWithdrawLog.nickname}</font>
                                </td>
                                <td align={"center"}>{depositWithdrawLog.account_name}</td>
                                <td align={"center"} ><font color="green">{depositWithdrawLog.user_gb_cd}</font></td>
                                <td align={"center"}>{depositWithdrawLog.pnt_gd_cd}</td>
                                <td align={"center"} >
                                    {depositWithdrawState(depositWithdrawLog.deposit_withdraw_state_cd)}
                                </td>
                                <td align={"center"}>{depositWithdrawLog.target_no}</td>
                                <td align={"center"} >
                                    {stateNm(depositWithdrawLog.before_state_cd)}
                                </td>
                                <td align={"center"}>
                                    {stateNm(depositWithdrawLog.result_state_cd)}
                                </td>
                                <td align={"center"} >
                                    <font color="blue">
                                        <NumericFormat value={depositWithdrawLog.deposit_withdraw_amt} displayType={'text'} thousandSeparator={true}/>
                                    </font>
                                </td>
                                <td align={"center"}>{depositWithdrawLog.result_dt}</td>
                                <td align={"center"}>
                                    {convertMinutesToDaysHoursMinutes(depositWithdrawLog.result_interval)}
                                </td>
                                <td align={"center"}>{depositWithdrawLog.reg_id}</td>
                                <td align={"center"} >
                                    <a href={"https://www.ipaddress.com/ipv4/" + depositWithdrawLog.reg_ip} target="_blank"><span style={{ color: "#1F68EE" }}>{depositWithdrawLog.reg_ip}</span></a>
                                </td>
                            </tr>
                            </tbody>
                        ))}

                    </table>

                </div>

            </div>
        </div>

    )
}

export default DepositWithdrawLog;