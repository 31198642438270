import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {NumericFormat} from "react-number-format";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";
const ExchangeRequestComponents = ({exchangeRequestList, onRefresh}) => {
    const dispatch = useDispatch()


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>


                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="table table-bordered table-sm text-center align-middle calculate">
                        <thead>
                        <tr>
                            <th style={{width: "13%"}}>아이디</th>
                            <th style={{width: "13%"}}>닉네임</th>
                            <th style={{width: "12%"}}>출금전</th>
                            <th style={{width: "12%"}}>출금후</th>
                            <th style={{width: "12%"}}>출금액</th>
                            <th style={{width: "13%"}}>신청일시</th>
                            <th style={{width: "13%"}}>처리일시</th>
                            <th style={{width: "12%"}}>상태</th>
                        </tr>
                        </thead>
                        {!exchangeRequestList.length && (
                            <tbody key={exchangeRequestList.no}>
                            <tr>
                                <td colSpan="8">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {exchangeRequestList.map((exchangeRequest, index) => (
                            <tbody key={exchangeRequest.no}>
                            <tr>
                                <td align={"center"}>{exchangeRequest.id}</td>
                                <td align={"center"}>{exchangeRequest.nm}</td>
                                <td align={"center"}>
                                    <NumericFormat value={exchangeRequest.before_amt} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td align={"center"}>
                                    <NumericFormat value={exchangeRequest.result_amt} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td align={"center"}>
                                    <NumericFormat value={exchangeRequest.withdraw_amt} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td align={"center"}>{exchangeRequest.request_dt}</td>
                                <td align={"center"}>{exchangeRequest.complete_dt}</td>
                                <td align={"center"}>{exchangeRequest.withdraw_state_cd}</td>
                            </tr>
                            </tbody>
                        ))}
                    </table>

                </div>

            </div>
        </div>

    )
}

export default ExchangeRequestComponents;