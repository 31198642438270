import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    isOpen: false,
    isOpen2:false,
    isOpen3:false,//조직코드
    isOpen4:false,//유저공통

    totalList : [],
    totalCount : 0,
    gridList : [],
    gridCount : 0,

    cmCd0001 : [],//콤보입금방식코드
    cmCd0004 : [],//포인트등급코드
    cmCd0008 : [],//콤보회원등급코드
    nowCnncYnCombo : [
        { cd : '', nm : '전체'},
        { cd : 'Y', nm : '회원접속'},
        { cd : 'N', nm : '회원미접속'}
    ],
    userColorList : [],//콤보유저색상코드
    orgList : [],//콤보조직코드
    messageTemplateList : [],//쪽지템플릿리스트

    rcptMethCdListAll : false, //입금방식전체체크여부
    userGbCdListAll : true,//회원등급코드전체체크
    pntGdCdListAll : true,//포인트등급코드전체체크
    userColorNoListAll : false,//관심회원유저색상전체체크
    exceptUserColorNoListAll : false,//관심회원유저색상제외전체체크

    selectOrgId : "",//콤보에서선택한조직코드
    selectExceptOrgId : "",//콤보에서선택한제외조직코드

    orgIdText : "",//조직코드텍스트문자
    exceptOrgIdText : "",//제외조직코드텍스트문자

    inputTemplate:{
        gb : "",
        no : "",
        template : "",
        messageTitle : "",
        messageCont : "",
    },

    popNo : 0,//쪽지조작버튼 클릭시에 넘길 순번

    input:{
        gb : "",
        page: 1,
        pageSize: 20,
    },

    inputSend : {
        payPoint1 : 0,
        payPointTime : 0,
        payPoint2 : 0,
        rcptMethCdList :[],
        userGbCdList : [],
        nowCnncYn : "",
        pntGdCdList : [],//포인트등급리스트
        userColorNoList : [],
        exceptUserColorNoList : [],
        orgIdList : [],
        exceptOrgIdList : [],
        siteCdList : [],
        userIdList : [],
        exceptUserIdList : [],
        domainList : [],
        send_name : "관리자",
        pay_point : 0,
        pay_coupon_name : "",
        pay_coupon_amt : 0,
        send_id : "",
        send_ip : "",
        send_dt : "",
        title : "",
        cont : "",
        auto_delete_day : 3,
        reg_id : "",
        upd_id : ""
    }
}

export const statsSlice = createSlice({
    name: 'MessageReducer',
    initialState,
    reducers: {
        getMessageReducer(state, action){
            console.log("#reducers getMessageReducer start#",
                action.payload.input.pageSize,
                action.payload.input.page)
            ;
            state.input.page = action.payload.input.pageSize === 999? 1: action.payload.input.page;
            state.input.pageSize = action.payload.input.pageSize;
        },
        setMessageReducer(state, action) {
            console.log("#reducers setMessageReducer start#",action.payload.orgList);
            if((state.input.page -1) * state.input.pageSize > action.payload.totalCount) {
                state.input.page = 1
            }
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.gridCount = action.payload.gridCount;
            state.gridList = action.payload.gridList;

            state.cmCd0001 = action.payload.cmCd0001;//입금방식코드
            state.cmCd0004 = action.payload.cmCd0004;//포인트등급코드
            state.cmCd0008 = action.payload.cmCd0008;//회원등급코드
            state.userColorList = action.payload.userColorList;
            state.orgList = action.payload.orgList;
            state.messageTemplateList = action.payload.messageTemplateList;

            //전체선택 디폴트
            state.inputSend.userGbCdList = state.cmCd0008.map(item => item.cmmn_cd_dtl_id);
            state.inputSend.pntGdCdList = state.cmCd0004.map(item => item.cmmn_cd_dtl_id);

        },
        failedGetMessageReducer(state, action) {
            state.blackTypeCd = action.payload;
        },
        setPayPoint1(state, action){
            state.inputSend.payPoint1 = action.payload;
        },
        setPayPointTime(state, action){
            state.inputSend.payPointTime = action.payload;
        },
        setPayPoint2(state, action){
            state.inputSend.payPoint2 = action.payload;
        },
        setRcptMethCdListAll(state, action){
            if(action.payload){
                state.inputSend.rcptMethCdList = state.cmCd0001.map(item => item.cmmn_cd_dtl_id);
                state.rcptMethCdListAll = true;
            }else{
                state.inputSend.rcptMethCdList = [];
                state.rcptMethCdListAll = false;
            }
        },
        setRcptMethCdList(state, action){
            if(action.payload.gb){
                state.inputSend.rcptMethCdList.push(action.payload.val);
                //전부체크되어있으면
                if(state.inputSend.rcptMethCdList.length === state.cmCd0001.length){
                    state.rcptMethCdListAll = true;
                }
            }else{
                state.inputSend.rcptMethCdList = state.inputSend.rcptMethCdList.filter(item => item !== action.payload.val);
                state.rcptMethCdListAll = false;
            }
        },
        setUserGbCdListAll(state, action){
            if(action.payload){
                state.inputSend.userGbCdList = state.cmCd0008.map(item => item.cmmn_cd_dtl_id);
                state.userGbCdListAll = true;
            }else{
                state.inputSend.userGbCdList = [];
                state.userGbCdListAll = false;
            }
        },
        setUserGbCdList(state, action){
            if(action.payload.gb){
                state.inputSend.userGbCdList.push(action.payload.val);
                //전부체크되어있으면
                if(state.inputSend.userGbCdList.length === state.cmCd0008.length){
                    state.userGbCdListAll = true;
                }
            }else{
                state.inputSend.userGbCdList = state.inputSend.userGbCdList.filter(item => item !== action.payload.val);
                state.userGbCdListAll = false;
            }
        },
        setNowCnncYn(state, action){
            state.inputSend.nowCnncYn = action.payload;
        },
        setPntGdCdListAll(state, action){
            if(action.payload){
                state.inputSend.pntGdCdList = state.cmCd0004.map(item => item.cmmn_cd_dtl_id);
                state.pntGdCdListAll = true;
            }else{
                state.inputSend.pntGdCdList = [];
                state.pntGdCdListAll = false;
            }
        },
        setPntGdCdList(state, action){
            if(action.payload.gb){
                state.inputSend.pntGdCdList.push(action.payload.val);
                //전부체크되어있으면
                if(state.inputSend.pntGdCdList.length === state.cmCd0004.length){
                    state.pntGdCdListAll = true;
                }
            }else{
                state.inputSend.pntGdCdList = state.inputSend.pntGdCdList.filter(item => item !== action.payload.val);
                state.pntGdCdListAll = false;
            }
        },
        setUserColorNoListAll(state, action){
            if(action.payload){
                state.inputSend.userColorNoList = state.userColorList.map(item => item.no);
                state.userColorNoListAll = true;
            }else{
                state.inputSend.userColorNoList = [];
                state.userColorNoListAll = false;
            }
        },
        setUserColorNoList(state, action){
            if(action.payload.gb){
                state.inputSend.userColorNoList.push(parseInt(action.payload.val));
                //전부체크되어있으면
                if(state.inputSend.userColorNoList.length === state.userColorList.length){
                    state.userColorNoListAll = true;
                }
            }else{
                state.inputSend.userColorNoList = state.inputSend.userColorNoList.filter(item => item !== parseInt(action.payload.val));
                state.userColorNoListAll = false;
            }
            console.log("state.inputSend.userColorNoList",state.inputSend.userColorNoList);
        },
        setExceptUserColorNoListAll(state, action){
            if(action.payload){
                state.inputSend.exceptUserColorNoList = state.userColorList.map(item => item.no);
                state.exceptUserColorNoListAll = true;
            }else{
                state.inputSend.exceptUserColorNoList = [];
                state.exceptUserColorNoListAll = false;
            }
        },
        setExceptUserColorNoList(state, action){
            if(action.payload.gb){
                state.inputSend.exceptUserColorNoList.push(parseInt(action.payload.val));
                //전부체크되어있으면
                if(state.inputSend.exceptUserColorNoList.length === state.userColorList.length){
                    state.exceptUserColorNoListAll = true;
                }
            }else{
                state.inputSend.exceptUserColorNoList = state.inputSend.exceptUserColorNoList.filter(item => item !== parseInt(action.payload.val));
                state.exceptUserColorNoListAll = false;
            }
        },
        setSelectOrgId(state, action){
            state.selectOrgId = action.payload
        },
        setSelectExceptOrgId(state, action){
            state.selectExceptOrgId = action.payload
        },
        setOrgIdList(state, action){
            state.orgIdText = action.payload
            state.inputSend.orgIdList = action.payload.split('\n');
        },
        setExceptOrgIdList(state, action){
            state.exceptOrgIdText = action.payload
            state.inputSend.exceptOrgIdList = action.payload.split('\n');
        },
        setMessageTemplateList(state, action){
            state.inputSend.messageTemplateList = action.payload;
        },
        setSiteCdList(state, action){
            state.inputSend.siteCdList = action.payload;
        },
        setUserIdList(state, action){
            state.inputSend.userIdList = action.payload.split('\n');
        },
        setExceptUserIdList(state, action){
            state.inputSend.exceptUserIdList = action.payload.split('\n');
        },
        setDomainList(state, action){
            state.inputSend.domainList = action.payload.split('\n');
        },
        setSendName(state, action){
            state.inputSend.send_name = action.payload;
        },
        setPayPoint(state, action){
            state.inputSend.pay_point = action.payload;
        },
        setPayCouponName(state, action){
            state.inputSend.pay_coupon_name = action.payload;
        },
        setPayCouponAmt(state, action){
            state.inputSend.pay_coupon_amt = action.payload;
        },
        setSendId(state, action){
            state.inputSend.send_id = action.payload;
        },
        setSendIp(state, action){
            state.inputSend.send_ip = action.payload;
        },
        setSendDt(state, action){
            state.inputSend.send_dt = action.payload;
        },
        setTitle(state, action){
            state.inputSend.title = action.payload;
        },
        setCont(state, action){
            state.inputSend.cont = action.payload;
        },
        setAutoDeleteDay(state, action){
            state.inputSend.auto_delete_day = action.payload;
        },
        setRegId(state, action){
            state.inputSend.reg_id = action.payload;
        },
        setUpdId(state, action){
            state.inputSend.upd_id = action.payload;
        },
        setIsOpen(state,action){
            state.isOpen = action.payload;
        },
        setIsOpen2(state,action){
            state.isOpen2 = action.payload;
        },
        setIsOpen3(state,action){
            state.isOpen3 = action.payload;
        },
        setIsOpen4(state,action){
            state.isOpen4 = action.payload;
        },
        setInputTemplate(state,action){
            let nm = action.payload.nm
            state.inputTemplate[nm] = action.payload.val
        },
        setPopNo(state,action){
            state.popNo = action.payload;
        }
    }
});

export const {
    setIsOpen,
    setIsOpen2,
    setIsOpen3,
    setIsOpen4,
    getMessageReducer,
    setMessageReducer,
    failedGetMessageReducer,
    setAutoDeleteDay,
    setCont,
    setDomainList,
    setSendId,
    setSelectOrgId,
    setSelectExceptOrgId,
    setExceptOrgIdList,
    setExceptUserColorNoListAll,
    setExceptUserColorNoList,
    setExceptUserIdList,
    setMessageTemplateList,
    setNowCnncYn,
    setOrgIdList,
    setPayCouponAmt,
    setPayCouponName,
    setPayPoint,
    setPayPoint1,
    setPayPoint2,
    setPayPointTime,
    setPntGdCdList,
    setPntGdCdListAll,
    setRcptMethCdListAll,
    setRcptMethCdList,
    setRegId,
    setSendDt,
    setSendIp,
    setSendName,
    setSiteCdList,
    setTitle,
    setUpdId,
    setUserColorNoListAll,
    setUserColorNoList,
    setUserGbCdListAll,
    setUserGbCdList,
    setUserIdList,
    setInputTemplate,
    setPopNo
} = statsSlice.actions;

export default statsSlice.reducer;