
import React from "react";

const InplayBettingItmPage = () => {
    return (
        <div>
            <h7>[inplay]베팅내역</h7>
        </div>

    )
}
export default InplayBettingItmPage;