import {call, put, take,} from "redux-saga/effects";
import {fetchRtmSttlItm} from "../../../lib/api";
import {failedGetRtmSttlItm, setRtmSttlItm} from "../../reducers/rtmSttl/rtmSttlItm";

export function* getRtmSttlItmSaga(action) {
    try{
        const res = yield call(
            fetchRtmSttlItm,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd, action.payload.selectedNos, action.payload.userId,
            action.payload.sessionId, action.payload.sessionLevel
        )
        yield put(setRtmSttlItm(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetRtmSttlItm(error))
    }
}