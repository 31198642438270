import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    cmmnCdList : [],
    bankList : [],
    id : "",//아이디
    nickname : "",//닉네임
    mbphStart : "",//핸드폰앞자리
    mbphCenter : "",//핸드폰중간자리
    mbphEnd : "",//핸드폰마지막자리
    bankCd : "",//은행코드
    account : "",//계좌번호
    accountName : "",//예금주명
    blackTypeCd : "01",
    messangeInfo : "",
    damageAmt : 0,
    blackMemo : "",
    regId : "",
    updId : "",

    memberYnText : "",
    memberYnTextColor : "",
    disableYn : false,
}

export const statsSlice = createSlice({
    name: 'BlackMemberAddModalReducer',
    initialState,
    reducers: {
        setCmmnCd(state, action){
            state.cmmnCdList = action.payload;
        },
        setBankList(state, action){
            state.bankList = action.payload;
        },
        setId(state, action) {
            state.id = action.payload;
        },
        setNickname(state, action) {
            state.nickname = action.payload;
        },
        setMbphStart(state, action) {
            state.mbphStart = action.payload;
        },
        setMbphCenter(state, action) {
            state.mbphCenter = action.payload;
        },
        setMbphEnd(state, action) {
            state.mbphEnd = action.payload;
        },
        setBankCd(state, action) {
            state.bankCd = action.payload;
        },
        setAccountName(state, action) {
            state.accountName = action.payload;
        },
        setAccount(state, action) {
            state.account = action.payload;
        },
        setBlackTypeCd(state, action) {
            state.blackTypeCd = action.payload;
        },
        setMessangeInfo(state, action) {
            state.messangeInfo = action.payload;
        },
        setDamageAmt(state, action) {
            state.damageAmt = action.payload;
        },
        setBlackMemo(state, action) {
            state.blackMemo = action.payload;
        },
        setMemberYnText(state, action) {
            state.memberYnText = action.payload;
        },
        setMemberYnTextColor(state, action){
            state.memberYnTextColor = action.payload;
        },
        setDisableYn(state, action){
            state.disableYn = action.payload;
        },
        resetState: () => initialState,
    }
});

export const {
    setCmmnCd,
    setId,
    setNickname,
    setMbphStart,
    setMbphCenter,
    setMbphEnd,
    setBankCd,
    setBankList,
    setAccountName,
    setAccount,
    setBlackTypeCd,
    setMessangeInfo,
    setDamageAmt,
    setBlackMemo,
    setMemberYnText,
    setMemberYnTextColor,
    setDisableYn,
    resetState,
} = statsSlice.actions;

export default statsSlice.reducer;