import React, {useState, useEffect} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {setSelectedNos5, setRowsTemplateData} from "../../../../redux/reducers/userInfoDetail";

const MemberInformationDetailModal = (props) => {
        const [require, setRequire] = useState(false);

        const dispatch = useDispatch()
        const userState = useSelector((state) => state.userInfoCombine)
        const userDetailState = useSelector((state) => state.userInfoDetail)
        const [org, setOrg] = useState(0)
        const [detailInfo, setDetailInfo] = useState({});
        const [recommendInfo, setRecommendInfo] = useState([]);
        const [userTagInfo, setUserTagInfo] = useState([]);
        const [bankList, setBankList] = useState([]);
        const [bankList2, setBankList2] = useState([]);
        const [bankList3, setBankList3] = useState([]);
        const [changeColorList, setChangeColorList] = useState([]);


        const [userGbList, setUserGbList] = useState([]);
        const [pntGbList, setPntGbList] = useState([]);
        const [orgList, setOrgList] = useState([]);

        const userInfo = useSelector((state) => state.userInfo)
        const {open, onClose} = props
        const [siteCd, setSiteCd] = useState('')
        const [storeCd, setStoreCd] = useState('01')
        const [name, setName] = useState('')
        const [nickName, setNickName] = useState('')
        const [duplNickName, duplNickNameCheck] = useState(false)
        const [id, setId] = useState('')
        const [duplId, duplIdCheck] = useState(false)
        const [pw, setPw] = useState('')
        const [mbphStart, setMbphStart] = useState('')
        const [mbphCenter, setMbphCenter] = useState('')
        const [mbphEnd, setMbphEnd] = useState('')
        const [smsYn, setSmsYn] = useState('')
        const [email, setEmail] = useState('')
        const [upRcmrId, setUpRcmrId] = useState('')
        const [userGbCd, setUserGbCd] = useState('')
        const [pntGbCd, setPntGbCd] = useState('')
        const [bankCd, setBankCd] = useState('')
        const [accountName, setAccountName] = useState('')
        const [account, setAccount] = useState('')
        const [withdrawPw, setWithdrawPw] = useState('')
        const [userStateCd, setUserStateCd] = useState('')
        const [rcmrYn, setRcmrYn] = useState('')
        const [rcmrLaveCnt, setRcmrLaveCnt] = useState('')
        const [psnlRcptPrddAccBankCd, setPsnlRcptPrddAccBankCd] = useState('')
        const [psnlRcptPrddAccNo, setPsnlRcptPrddAccNo] = useState('')
        const [psnlRcptPrddName, setPsnlRcptPrddName] = useState('')
        const [memo1, setMemo1] = useState('')
        const [memo2, setMemo2] = useState('')
        const [memo3, setMemo3] = useState('')

        const [updateMemoArr, setUpdateMemoArr]   = useState([]);
        const [updateBankArr, setUpdateBankArr]   = useState([]);
        const [updateAccNoArr, setUpdateAccNoArr] = useState([]);
        const [updateAccNmArr, setUpdateAccNmArr] = useState([]);

        const [insertNoArr, setInsertNoArr]       = useState([]);
        const [insertMemoArr, setInsertMemoArr]   = useState([]);
        const [insertBankArr, setInsertBankArr]   = useState([]);
        const [insertAccNoArr, setInsertAccNoArr] = useState([]);
        const [insertAccNmArr, setInsertAccNmArr] = useState([]);

        let rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input,rowsUpdate05Input,rowsUpdate06Input,rowsUpdate07Input,rowsUpdate08Input,rowsUpdate09Input,rowsUpdate10Input,rowsUpdate11Input,rowsUpdate12Input,rowsUpdate13Input,rowsUpdate14Input,rowsUpdate15Input,rowsUpdate16Input,rowsUpdate17Input,rowsUpdate18Input,rowsUpdate19Input,rowsUpdate20Input;

        const [rowsData, setRowsData] = useState([]);
        const addTableRows2 = ()=>{
                setRowsData([...rowsData])
        }
        const addTableRows = ()=>{
                const rowsInput={
                        insertMemo:'',
                        insertBank:'',
                        insertAccNo:'',
                        insertAccNm:''
                }
                setRowsData([...rowsData, rowsInput])
        }
        const updateTableRows = (memo, bank, accNo, accNm, index)=>{

                if( index === 0 ) {
                        rowsUpdate01Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input])
                }
                if( index === 1 ) {
                        rowsUpdate02Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input])
                }
                if( index === 2 ) {
                        rowsUpdate03Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input])
                }
                if( index === 3 ) {
                        rowsUpdate04Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input])
                }
                if( index === 4 ) {
                        rowsUpdate05Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input,rowsUpdate05Input])
                }
                if( index === 5 ) {
                        rowsUpdate06Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input,rowsUpdate05Input,rowsUpdate06Input])
                }
                if( index === 6 ) {
                        rowsUpdate07Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input,rowsUpdate05Input,rowsUpdate06Input,rowsUpdate07Input])
                }
                if( index === 7 ) {
                        rowsUpdate08Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input,rowsUpdate05Input,rowsUpdate06Input,rowsUpdate07Input,rowsUpdate08Input])
                }
                if( index === 8 ) {
                        rowsUpdate09Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input,rowsUpdate05Input,rowsUpdate06Input,rowsUpdate07Input,rowsUpdate08Input,rowsUpdate09Input])
                }
                if( index === 9 ) {
                        rowsUpdate10Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input,rowsUpdate05Input,rowsUpdate06Input,rowsUpdate07Input,rowsUpdate08Input,rowsUpdate09Input,rowsUpdate10Input])
                }
                if( index === 10 ) {
                        rowsUpdate11Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input,rowsUpdate05Input,rowsUpdate06Input,rowsUpdate07Input,rowsUpdate08Input,rowsUpdate09Input,rowsUpdate10Input,rowsUpdate11Input])
                }
                if( index === 11 ) {
                        rowsUpdate12Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input,rowsUpdate05Input,rowsUpdate06Input,rowsUpdate07Input,rowsUpdate08Input,rowsUpdate09Input,rowsUpdate10Input,rowsUpdate11Input,rowsUpdate12Input])
                }
                if( index === 12 ) {
                        rowsUpdate13Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input,rowsUpdate05Input,rowsUpdate06Input,rowsUpdate07Input,rowsUpdate08Input,rowsUpdate09Input,rowsUpdate10Input,rowsUpdate11Input,rowsUpdate12Input,rowsUpdate13Input])
                }
                if( index === 13 ) {
                        rowsUpdate14Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input,rowsUpdate05Input,rowsUpdate06Input,rowsUpdate07Input,rowsUpdate08Input,rowsUpdate09Input,rowsUpdate10Input,rowsUpdate11Input,rowsUpdate12Input,rowsUpdate13Input,rowsUpdate14Input])
                }
                if( index === 14 ) {
                        rowsUpdate15Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input,rowsUpdate05Input,rowsUpdate06Input,rowsUpdate07Input,rowsUpdate08Input,rowsUpdate09Input,rowsUpdate10Input,rowsUpdate11Input,rowsUpdate12Input,rowsUpdate13Input,rowsUpdate14Input,rowsUpdate15Input])
                }
                if( index === 15 ) {
                        rowsUpdate16Input={ insertMemo:memo, insertBank:bank, insertAccNo:accNo, insertAccNm:accNm }
                        setRowsData([rowsUpdate01Input,rowsUpdate02Input,rowsUpdate03Input,rowsUpdate04Input,rowsUpdate05Input,rowsUpdate06Input,rowsUpdate07Input,rowsUpdate08Input,rowsUpdate09Input,rowsUpdate10Input,rowsUpdate11Input,rowsUpdate12Input,rowsUpdate13Input,rowsUpdate14Input,rowsUpdate15Input,rowsUpdate16Input])
                }


                insertNoArr[index] = index;
                setInsertNoArr(insertNoArr);
                insertMemoArr[index] = memo;
                setInsertMemoArr(insertMemoArr);
                insertBankArr[index] = bank;
                setInsertBankArr(insertBankArr);
                insertAccNoArr[index] = accNo;
                setInsertAccNoArr(insertAccNoArr);
                insertAccNmArr[index] = accNm;
                setInsertAccNmArr(insertAccNmArr);


        }

        const deleteTableRows = (index)=>{

                const rows = [...rowsData];
                //alert("index :: "+index);
                rows.splice(index, 1);
                //alert("rows :: "+JSON.stringify(rows));
                setRowsData(rows);
        }

        const dataCopy = (index)=>{
                //alert( "index : "+index+" ||insertBankArr[index] :: "+insertBankArr[index]);
                setPsnlRcptPrddAccBankCd(insertBankArr[index]);
                setPsnlRcptPrddAccNo(insertAccNoArr[index]);
                setPsnlRcptPrddName(insertAccNmArr[index]);
        }


        const insertMemoArrHandler = (e, index) => {
                insertMemoArr[index] = e.target.value;
                setInsertMemoArr(insertMemoArr);
                insertNoArr[index] = index;
                setInsertNoArr(insertNoArr);

                const {name, value} = e.target;
                const rowsInput = [...rowsData];
                rowsInput[index][name] = value;
        }
        const insertBankArrHandler = (e, index) => {

                insertBankArr[index] = e.target.value;
                setInsertBankArr(insertBankArr);
                insertNoArr[index] = index;
                setInsertNoArr(insertNoArr);

                const {name, value} = e.target;
                const rowsInput = [...rowsData];
                rowsInput[index][name] = value;

                //alert("insertBankArrHandler : "+rowsInput[index][name]);
                addTableRows2();
        }
        const insertAccNoArrHandler = (e, index) => {
                insertAccNoArr[index] = e.target.value;
                setInsertAccNoArr(insertAccNoArr);
                insertNoArr[index] = index;
                setInsertNoArr(insertNoArr);

                const {name, value} = e.target;
                const rowsInput = [...rowsData];
                rowsInput[index][name] = value;
        }
        const insertAccNmArrHandler = (e, index) => {
                insertAccNmArr[index] = e.target.value;
                setInsertAccNmArr(insertAccNmArr);
                insertNoArr[index] = index;
                setInsertNoArr(insertNoArr);

                const {name, value} = e.target;
                const rowsInput = [...rowsData];
                rowsInput[index][name] = value;
        }


        function TableRows({rowsData, deleteTableRows, handleChange}) {
                return(
                    rowsData.map((data, index)=>{
                            console.log("data >>>>>>>>>>>>>>>>>>"+JSON.stringify(data))
                            const {insertMemo, insertBank, insertAccNo, insertAccNm}= data;
                            return(
                                <tr key={index} >
                                        <td align={"center"} >
                                                <button style={{
                                                        backgroundColor: "lightgray",
                                                        border: "none",
                                                        color: "black",
                                                        fontSize: "10px",
                                                        height: "20px",
                                                        width: "fit-content",
                                                        marginRight: "5px"
                                                }} onClick={()=>(dataCopy(index))} >사용
                                                </button>
                                        </td>
                                        <td align={"left"}>
                                                <input className="form-check-input sm-input" type="text" name="insertMemo" style={{width: "250px"}}
                                                       placeholder={insertMemo}
                                                       onChange={(e) => {insertMemoArrHandler(e, index)}}
                                                />
                                        </td>
                                        <td align={"left"}>
                                                <select
                                                    name="insertBank"
                                                    id="insertBank"
                                                    className="form-select sm-select"
                                                    style={{
                                                            width: "140px", height: "25px"
                                                    }}
                                                    onChange={(e) => {insertBankArrHandler(e, index)}} value={insertBank }
                                                >
                                                        {bankList3.map((item, index) => (
                                                            <option key={item.bank_cd} value={item.bank_cd}>{item.tag_nm}</option>
                                                        ))}
                                                </select>
                                        </td>
                                        <td align={"left"}>
                                                <input className="form-check-input sm-input" type="text" name="insertAccNo" style={{width: "150px"}}
                                                       placeholder={insertAccNo}
                                                       onChange={(e) => {insertAccNoArrHandler(e, index)}}
                                                />
                                        </td>
                                        <td align={"left"}>
                                                <input className="form-check-input sm-input" type="text" name="insertAccNm" style={{width: "100px"}}
                                                       placeholder={insertAccNm}
                                                       onChange={(e) => {insertAccNmArrHandler(e, index)}}
                                                />
                                        </td>
                                        <td align={"center"}>
                                                <button style={{
                                                        backgroundColor: "lightgray",
                                                        border: "none",
                                                        color: "black",
                                                        fontSize: "10px",
                                                        height: "20px",
                                                        width: "fit-content",
                                                        marginRight: "5px"
                                                }} onClick={()=>(deleteTableRows(index))}>삭제
                                                </button>
                                        </td>
                                </tr>
                            )
                    })

                )

        }

        const requireChangeHandler = (e) => {
                if(require)
                {
                        setRequire(false);
                }
                else
                {
                        setRequire(true);
                }

        }

        const orgCdChangeHandler = (e) => {
                setOrg(e.currentTarget.value);
        }
        const checkHandler5 = (checked, no) => {
                if(checked) {

                        if( userDetailState.selectedNos5 === null )
                        {
                                dispatch(setSelectedNos5([no]))
                        }
                        else
                        {
                                dispatch(setSelectedNos5([...userDetailState.selectedNos5, no]))
                        }

                }
                else{
                        dispatch(setSelectedNos5(userDetailState.selectedNos5.filter((id) => id !== no)))
                }
        }


        const getDetailInfo = () => {

                axios.post('/admin/member/detailInfoSub', {
                        userId : userState.userId
                }).then((res) => {
                        setDetailInfo(res.data.cmmnCdList);
                })

                axios.post('/admin/member/recommendInfo', {
                        userId : userState.userId
                }).then((res) => {
                        setRecommendInfo(res.data.cmmnCdList);
                })

                axios.post('/admin/member/userTagInfo', {
                        userId : userState.userId
                }).then((res) => {
                        setUserTagInfo(res.data.cmmnCdList);

                        res.data.cmmnCdList != null &&  res.data.cmmnCdList.map((userTagInfo, index) => (
                            updateTableRows(userTagInfo.memo, userTagInfo.acc_bank_cd, userTagInfo.acc_no, userTagInfo.acc_name, index)
                        ))

                })

                //alert("result :: "+userDetailState.rowsTemplateData);

                axios.post('/admin/member/tagInfo', {
                        userId : userState.userId
                }).then((res) => {
                        dispatch(setSelectedNos5(res.data.initTagArr))
                        //alert("res.data.cmmnCdList :: "+JSON.stringify(res.data.cmmnCdList))
                        // res.data.cmmnCdList.map(item => (
                        //     checkHandler5(true, parseInt(item.addn_info_tag))
                        //     //dispatch(setSelectedNos5([...userDetailState.selectedNos5, parseInt(item.addn_info_tag)]))
                        //     //setSelectedNos5([selectedNos5 => parseInt(item.addn_info_tag)])
                        // ))
                })

        }
        useEffect(getDetailInfo, [])

        const getCmmnCd = () => {
                if(userGbList.length === 0){
                        axios.post('/admin/cmmnCdList', {
                                cmmnCdId : '0002',
                        }).then((res) => {
                                setUserGbList(res.data.cmmnCdList);
                        })
                }
                if(pntGbList.length === 0){
                        axios.post('/admin/cmmnCdList', {
                                cmmnCdId : '0004',
                        }).then((res) => {
                                setPntGbList(res.data.cmmnCdList);
                        })
                }
                if(bankList.length === 0){
                        axios.post('/admin/member/getBank', {
                                cmmnCdId : '01',
                        }).then((res) => {
                                setBankList(res.data.cmmnCdList);
                        })
                }
                if(bankList2.length === 0){
                        axios.post('/admin/member/getBank', {
                                cmmnCdId : '01',
                        }).then((res) => {
                                setBankList2(res.data.cmmnCdList);
                                setBankList3(res.data.cmmnCdList);
                        })
                }
                if(changeColorList.length === 0){
                        axios.post('/admin/member/getTagSetting', {
                                cmmnCdId : '0023',
                        }).then((res) => {
                                setChangeColorList(res.data.cmmnCdList);
                        })
                }
                if(orgList.length === 0){
                        axios.post('/admin/member/getOrg', {
                                cmmnCdId : '01',
                        }).then((res) => {
                                setOrgList(res.data.cmmnCdList);
                        })
                }

        }
        useEffect(getCmmnCd, [])


        const siteCdChangeHandler = (e) => {
                setSiteCd(e.currentTarget.value);
        }
        const storeCdChangeHandler = (e) => {
                setStoreCd(e.currentTarget.value);
        }
        const nameChangeHandler = (e) => {
                setName(e.currentTarget.value);
                const onlyKorean = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'/\\]/g;
                if (onlyKorean.test(name)) {
                        alert("한글만 입력바랍니다")
                        setName("")
                }
        }
        const nickNameChangeHandler = (e) => {
                setNickName(e.currentTarget.value);
        }
        const idChangeHandler = (e) => {
                setId(e.currentTarget.value);
                const notKorean = /[ㄱ-ㅎ가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'\\]/g;
                if (notKorean.test(id)) {
                        alert("영어,숫자조합만 가능합니다")
                        setId("")
                }
        }
        const pwChangeHandler = (e) => {
                setPw(e.currentTarget.value);
                const notKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'/\\]/g;
                if (notKorean.test(pw)) {
                        alert("영어,숫자조합만 가능합니다")
                        setPw("")
                }
        }
        const mbphStartChangeHandler = (e) => {
                setMbphStart(e.currentTarget.value);
        }
        const mbphCenterChangeHandler = (e) => {
                setMbphCenter(e.currentTarget.value);
        }
        const mbphEndChangeHandler = (e) => {
                setMbphEnd(e.currentTarget.value);
        }
        const smsYnChangeHandler = (type) => {
                setSmsYn(type)
        }
        const emailChangeHandler = (e) => {
                setEmail(e.currentTarget.value);
        }
        const upRcmrIdChangeHandler = (e) => {
                setUpRcmrId(e.currentTarget.value);
        }
        const userGbCdChangeHandler = (e) => {
                setUserGbCd(e.currentTarget.value);
        }
        const pntGbCdChangeHandler = (e) => {
                setPntGbCd(e.currentTarget.value);
        }
        const bankCdChangeHandler = (e) => {
                setBankCd(e.currentTarget.value);
        }
        const accountNameChangeHandler = (e) => {
                setAccountName(e.currentTarget.value);
        }
        const accountChangeHandler = (e) => {
                setAccount(e.currentTarget.value);
        }
        const withdrawPwChangeHandler = (e) => {
                setWithdrawPw(e.currentTarget.value);
        }
        const userStateCdChangeHandler = (type) => {
                setUserStateCd(type)
        }
        const rcmrYnChangeHandler = (type) => {
                setRcmrYn(type)
        }
        const rcmrLaveCntChangeHandler = (e) => {
                setRcmrLaveCnt(e.currentTarget.value);
        }
        const psnlRcptPrddAccBankCdChangeHandler = (e) => {
                setPsnlRcptPrddAccBankCd(e.currentTarget.value);
        }
        const psnlRcptPrddAccNoChangeHandler = (e) => {
                setPsnlRcptPrddAccNo(e.currentTarget.value);
        }
        const psnlRcptPrddNameChangeHandler = (e) => {
                setPsnlRcptPrddName(e.currentTarget.value);
        }
        const memo1ChangeHandler = (e) => {
                setMemo1(e.currentTarget.value);
        }
        const memo2ChangeHandler = (e) => {
                setMemo2(e.currentTarget.value);
        }
        const memo3ChangeHandler = (e) => {
                setMemo3(e.currentTarget.value);
        }

        const addFirstTime1 = () => {
                const nowTime = moment().format('YYYY-MM-DD HH:mm:ss');
                setMemo1(nowTime+"\n"+memo1);
        }
        const addLastTime1 = () => {
                const nowTime = moment().format('YYYY-MM-DD HH:mm:ss');
                setMemo1(memo1+"\n"+nowTime);
        }
        const addFirstTime2 = () => {
                const nowTime = moment().format('YYYY-MM-DD HH:mm:ss');
                setMemo2(nowTime+"\n"+memo2);
        }
        const addLastTime2 = () => {
                const nowTime = moment().format('YYYY-MM-DD HH:mm:ss');
                setMemo2(memo2+"\n"+nowTime);
        }
        const addFirstTime3 = () => {
                const nowTime = moment().format('YYYY-MM-DD HH:mm:ss');
                setMemo3(nowTime+"\n"+memo3);
        }
        const addLastTime3 = () => {
                const nowTime = moment().format('YYYY-MM-DD HH:mm:ss');
                setMemo3(memo3+"\n"+nowTime);
        }

        const onNickNameCheckHandler = (e) => {
                axios.post('/admin/member/duplicatedUserNickName', {
                        nickname: nickName
                }).then((res) => {
                        if(res.data.isUpdated) {
                                alert("중복되는 닉네임입니다.")
                                duplNickNameCheck(false);
                        } else {
                                alert("이용가능한 닉네임입니다.")
                                duplNickNameCheck(true);
                        }
                })
        }


        const onIdCheckHandler = (e) => {
                axios.post('/admin/member/duplicatedUserId', {
                        id: id
                }).then((res) => {
                        if(res.data.isUpdated) {
                                alert("중복되는 아이디입니다.")
                                duplIdCheck(false);
                        } else {
                                alert("이용가능한 아이디입니다.")
                                duplIdCheck(true);
                        }
                })
        }




        const handleCancel = () => {
                onClose()
        }
        const userDelete = (e) => {
                if(window.confirm("이 회원을 삭제하시겠습니까?"))
                {
                        axios.post('/admin/member/deleteUser', {
                                id: userState.userId,
                                reg_id: userInfo.id,
                                upd_id: userInfo.id,
                                reg_ip: userInfo.ip,
                                upd_ip: userInfo.ip,
                        })
                            .then((res) => {
                                    if (res.data.isInserted) {
                                            alert('삭제완료')
                                    } else {
                                            alert('삭제실패')
                                    }
                            })
                }
        }



        const handleRegister = () => {

                /*
                if(siteCd ==="") {
                        alert("SITE CODE를 입력해주세요")
                }
                else if(storeCd ==="") {
                        alert("소속을 선택해주세요")
                }
                else if(name ==="") {
                        alert("이름을 입력해주세요")
                }
                else if(nickName ==="") {
                        alert("닉네임을 입력해주세요")
                }
                else if(id ==="") {
                        alert("아이디를 입력해주세요")
                }
                else if(pw ==="") {
                        alert("신규 비밀번호를 입력해주세요")
                }
                else if( duplId == false )
                {
                        alert("아이디 중복 확인을 확인해주세요")
                }
                else if( duplNickName == false )
                {
                        alert("닉네임 중복 확인을 확인해주세요")
                }
                */
                // else
                // {


                // alert("rowsData >>>>>>>>>"+JSON.stringify(rowsData));
                // rowsData != null &&  rowsData.map((userTagInfo, index) => (
                //     console.log("userTagInfo >>>>>>>>>> "+JSON.stringify(userTagInfo))
                //     ))

                //alert("rowsData >>>>>>>>>"+JSON.stringify(rowsData));

                if(window.confirm("수정 하시겠습니까?"))
                {

                        // console.log("siteCd : "+siteCd);
                        // console.log("storeCd : "+storeCd);
                        // console.log("name : "+name);
                        // console.log("nickName : "+nickName);
                        // console.log("id : "+id);
                        // console.log("pw : "+pw);
                        // console.log("mbphStart : "+mbphStart);
                        // console.log("mbphCenter : "+mbphCenter);
                        // console.log("mbphEnd : "+mbphEnd);
                        // console.log("smsYn : "+smsYn);
                        // console.log("email : "+email);
                        // console.log("upRcmrId : "+upRcmrId);
                        // console.log("userGbCd : "+userGbCd);
                        // console.log("pntGbCd : "+pntGbCd);
                        // console.log("accountName : "+accountName);
                        // console.log("account : "+account);
                        // console.log("withdrawPw : "+withdrawPw);
                        // console.log("userStateCd : "+userStateCd);
                        // console.log("rcmrYn : "+rcmrYn);
                        // console.log("rcmrLaveCnt : "+rcmrLaveCnt);
                        // console.log("psnlRcptPrddAccBankCd : "+psnlRcptPrddAccBankCd);
                        // console.log("psnlRcptPrddAccNo : "+psnlRcptPrddAccNo);
                        // console.log("psnlRcptPrddName : "+psnlRcptPrddName);
                        // console.log("memo1 : "+memo1);
                        // console.log("memo2 : "+memo2);
                        // console.log("memo3 : "+memo3);

                        //alert("userDetailState.selectedNos5 >> "+userDetailState.selectedNos5);
                        //return;
                        //alert("userDetailState.selectedNos5 != null ? null : userDetailState.selectedNos5.toString() >> "+userDetailState.selectedNos5 != null ? null : userDetailState.selectedNos5.toString());

                        let selectedNos5 = "";
                        if( userDetailState.selectedNos5 != null )
                        {
                                selectedNos5 = userDetailState.selectedNos5.toString();
                        }
                        else
                        {
                                selectedNos5 = null;
                        }

                        axios.post('/admin/member/update', {
                                key: userState.userId,
                                siteCd: siteCd,
                                storeCd: org,
                                name: name,
                                nickName: nickName,
                                id: id,
                                pw: (require ? pw : ""),
                                mbphStart: mbphStart,
                                mbphCenter: mbphCenter,
                                mbphEnd: mbphEnd,
                                smsYn: smsYn,
                                email: email,
                                upRcmrId: upRcmrId,
                                userGbCd: userGbCd,
                                pntGbCd: pntGbCd,
                                accountName: accountName,
                                account: account,
                                withdrawPw: withdrawPw,
                                userStateCd: userStateCd,
                                rcmrYn: rcmrYn,
                                rcmrLaveCnt: rcmrLaveCnt,
                                psnlRcptPrddAccBankCd: psnlRcptPrddAccBankCd,
                                psnlRcptPrddAccNo: psnlRcptPrddAccNo,
                                psnlRcptPrddName: psnlRcptPrddName,
                                memo1: memo1,
                                memo2: memo2,
                                memo3: memo3,
                                addn_info_tag: selectedNos5,
                                reg_id: userInfo.id,
                                upd_id: userInfo.id,
                                reg_ip: userInfo.ip,
                                upd_ip: userInfo.ip,
                                insertMemoArr: insertMemoArr,
                                insertBankArr: insertBankArr,
                                insertAccNoArr: insertAccNoArr,
                                insertAccNmArr: insertAccNmArr
                        })
                            .then((res) => {
                                    if (res.data.isInserted) {
                                            //alert('수정완료')
                                    } else {
                                            alert('수정실패')
                                    }
                            })


                        //템플릿 배열 설정
                        //alert("rowsData >>>>>>>>>"+JSON.stringify(rowsData));



                        axios.post('/admin/member/deleteTemplate', {
                                id: userState.userId
                        })
                            .then((res) => {
                                    if (res.data.isInserted) {
                                            //alert('삭제완료')
                                    } else {
                                            alert('삭제실패')
                                    }
                            })

                        rowsData != null &&  rowsData.map((userTagInfo, index) => (


                            axios.post('/admin/member/updateTemplate', {
                                    id: userState.userId,
                                    insertMemoArr: userTagInfo.insertMemo,
                                    insertBankArr: userTagInfo.insertBank,
                                    insertAccNoArr: userTagInfo.insertAccNo,
                                    insertAccNmArr: userTagInfo.insertAccNm,
                                    reg_id: userInfo.id
                            })
                                .then((res) => {
                                        if (res.data.isInserted) {
                                                //alert('수정완료')
                                        } else {
                                                alert('수정실패')
                                        }
                                })

                        ))



                        alert('수정완료');


                }
                //}
        }


        return (
            <div className="common">
                    <div className="container-fluid p-0 mx-1" style={{
                            display: "flex"
                    }}>
                            <table width={"99%"}>
                                    <tr>
                                            <td align={"left"}>
                                                    <button style={{
                                                            backgroundColor: "red",
                                                            border: "none",
                                                            color: "white",
                                                            fontSize: "12px",
                                                            height: "20px",
                                                            width: "fit-content",
                                                            marginRight: "10px"
                                                    }} onClick={userDelete}>회원 삭제
                                                    </button>
                                            </td>
                                            <td align={"center"}>
                                                    <button style={{
                                                            backgroundColor: "lightskyblue",
                                                            border: "1",
                                                            color: "yellow",
                                                            fontSize: "12px",
                                                            height: "23px",
                                                            width: "100px",
                                                            marginRight: "10px"
                                                    }} ><b>{detailInfo.user_gb_nm}</b>
                                                    </button>&nbsp;&nbsp;
                                                    <button style={{
                                                            backgroundColor: "lightskyblue",
                                                            border: "1",
                                                            color: "yellow",
                                                            fontSize: "12px",
                                                            height: "23px",
                                                            width: "100px",
                                                            marginRight: "10px"
                                                    }} ><b>{detailInfo.user_state_cd}</b>
                                                    </button>&nbsp;&nbsp;
                                                    <button style={{
                                                            backgroundColor: "lightskyblue",
                                                            border: "1",
                                                            color: "yellow",
                                                            fontSize: "12px",
                                                            height: "23px",
                                                            width: "100px",
                                                            marginRight: "10px"
                                                    }} ><b>{detailInfo.del_yn}</b>
                                                    </button>
                                            </td>
                                            <td align={"right"}>
                                                    <button style={{
                                                            backgroundColor: "#5dc434",
                                                            border: "none",
                                                            color: "white",
                                                            fontSize: "12px",
                                                            height: "20px",
                                                            width: "fit-content",
                                                            marginRight: "10px"
                                                    }} onClick={handleRegister}>회원 수정
                                                    </button>
                                            </td>
                                    </tr>
                            </table>
                    </div>


                    <div className="container-fluid p-0 mx-1" style={{
                            display: "flex"
                    }}>

                            <table className="table table-bordered table-sm text-center align-middle"
                                   style={{width: "98%"}}>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>SITE
                                                    CODE
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"} colSpan="4">
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <input className="form-check-input sm-input" type="text"
                                                                   style={{width: "120px"}} placeholder={detailInfo.site_cd} value={siteCd}
                                                                   onChange={siteCdChangeHandler}/>
                                                    </div>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            * SITE CODE는, 개발자에 의해 발급된 코드만 사용하셔야 합니다.
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 소속
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <select
                                                                className="form-select sm-select"
                                                                defaultValue={0}
                                                                style={{
                                                                        width: "100px"
                                                                }}
                                                                onChange={orgCdChangeHandler} value={org ? org : detailInfo.store_cd}
                                                            >
                                                                    <option key='00' value=''>::총판선택::</option>
                                                                    {orgList.map((item, index) => (
                                                                        <option key={item.id} value={item.id}>{item.org_nm}</option>
                                                                    ))}
                                                            </select>
                                                    </div>
                                            </td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 이름
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <input className="form-check-input sm-input" type="text"
                                                                   style={{width: "120px"}} placeholder={detailInfo.name} value={name}
                                                                   onChange={nameChangeHandler}/>
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>*
                                                    닉네임
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <input className="form-check-input sm-input" type="text"
                                                                   style={{width: "120px"}} placeholder={detailInfo.nickname} value={nickName}
                                                                   onChange={nickNameChangeHandler}/>&nbsp;
                                                            <button style={{
                                                                    backgroundColor: "#3fadf7",
                                                                    border: "none",
                                                                    color: "white",
                                                                    fontSize: "12px",
                                                                    height: "20px",
                                                                    width: "fit-content",
                                                                    marginRight: "5px"
                                                            }} onClick={onNickNameCheckHandler}>닉네임 중복 확인
                                                            </button>
                                                    </div>
                                            </td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>*
                                                    아이디
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <input className="form-check-input sm-input" type="text"
                                                                   style={{width: "120px"}} placeholder={detailInfo.id} value={id} readOnly={true}
                                                                   onChange={idChangeHandler}/>&nbsp;
                                                            <button style={{
                                                                    backgroundColor: "#5dc434",
                                                                    border: "none",
                                                                    color: "white",
                                                                    fontSize: "12px",
                                                                    height: "20px",
                                                                    width: "fit-content",
                                                                    marginRight: "5px"
                                                            }} onClick={onIdCheckHandler}>개인 쪽지 발송
                                                            </button>
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} colspan={"6"}>
                                                    * 비밀번호를 수정하고 싶으신 경우, 아래 비밀번호 수정에 체크하신 후 신규 비밀번호를 넣으시면 됩니다.
                                                    <br></br>
                                                    비밀번호 변경확인을 체크하지 않으시면 비번 변경이 되지 않습니다.
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>
                                                    * 비밀번호 수정하기
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"} >
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <input className="form-check-input" type="checkbox" checked={require ? true:false}
                                                                   onChange={() => requireChangeHandler(require)} style={{ cursor: "pointer" }}/>
                                                    </div>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                    </div>
                                            </td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>
                                                    * 신규 비밀번호
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"} >
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <input className="form-check-input sm-input" type="text"
                                                                   style={{width: "120px"}} placeholder="" value={pw}
                                                                   onChange={pwChangeHandler}/>
                                                    </div>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>핸드폰
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <input className="form-check-input sm-input" type="text"
                                                                   style={{width: "40px"}} placeholder={detailInfo.mbph_start} value={mbphStart}
                                                                   onChange={mbphStartChangeHandler}/>-
                                                            <input className="form-check-input sm-input" type="text"
                                                                   style={{width: "40px"}} placeholder={detailInfo.mbph_center} value={mbphCenter}
                                                                   onChange={mbphCenterChangeHandler}/>-
                                                            <input className="form-check-input sm-input" type="text"
                                                                   style={{width: "40px"}} placeholder={detailInfo.mbph_end} value={mbphEnd}
                                                                   onChange={mbphEndChangeHandler}/>
                                                    </div>
                                            </td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                width={"15%"}>문자수신여부
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <input className="form-check-input" type="radio" value="Y" checked={smsYn === "" ? (detailInfo.sms_yn ==="Y" ?true:false) : ( smsYn ==="Y" ? true:false) }
                                                                   onChange={() => smsYnChangeHandler('Y')} style={{ cursor: "pointer" }}/> 수신
                                                            &nbsp;
                                                            <input className="form-check-input" type="radio" value="N" checked={smsYn === "" ? (detailInfo.sms_yn ==="N" ?true:false) : ( smsYn ==="N" ? true:false) }
                                                                   onChange={() => smsYnChangeHandler('N')} style={{ cursor: "pointer" }}/> 거부
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                width={"15%"}>EMAIL
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <input className="form-check-input sm-input" type="text"
                                                                   style={{width: "150px"}} placeholder={detailInfo.email} value={email}
                                                                   onChange={emailChangeHandler}/>&nbsp;
                                                    </div>
                                            </td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>추천인
                                                    id
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <div className="container-fluid p-0 mx-1" style={{
                                                                    display: "flex"
                                                            }}>
                                                                    <input className="form-check-input sm-input" type="text"
                                                                           style={{width: "120px"}} placeholder={detailInfo.up_rcmr_id}
                                                                           value={upRcmrId}
                                                                           onChange={upRcmrIdChangeHandler}/>&nbsp;
                                                            </div>
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>회원분류
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <select
                                                                className="form-select sm-select"
                                                                defaultValue={'01'}
                                                                style={{
                                                                        width: "100px"
                                                                }}
                                                                onChange={userGbCdChangeHandler} value={userGbCd ? userGbCd : detailInfo.user_gb_cd}
                                                            >
                                                                    {userGbList.map((item, index) => (
                                                                        <option key={item.cmmn_cd_dtl_id}
                                                                                value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                                                                    ))}
                                                            </select>
                                                    </div>
                                            </td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                width={"15%"}>포인트등급
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <div className="container-fluid p-0 mx-1" style={{
                                                                    display: "flex"
                                                            }}>
                                                                    <select
                                                                        className="form-select sm-select"
                                                                        defaultValue={'01'}
                                                                        style={{
                                                                                width: "100px"
                                                                        }}
                                                                        onChange={pntGbCdChangeHandler} value={pntGbCd ? pntGbCd : detailInfo.pnt_gd_cd}
                                                                    >
                                                                            {pntGbList.map((item, index) => (
                                                                                <option key={item.cmmn_cd_dtl_id}
                                                                                        value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                                                                            ))}
                                                                    </select>
                                                            </div>
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                width={"15%"}>은행/예금주
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <select
                                                                className="form-select sm-select"
                                                                defaultValue={'01'}
                                                                style={{
                                                                        width: "140px"
                                                                }}
                                                                onChange={bankCdChangeHandler} value={bankCd ? bankCd : detailInfo.bank_cd}
                                                            >
                                                                    {bankList.map((item, index) => (
                                                                        <option key={item.bank_cd} value={item.bank_cd}>{item.tag_nm}</option>
                                                                    ))}
                                                            </select>
                                                            &nbsp;
                                                            <input className="form-check-input sm-input" type="text"
                                                                   style={{width: "120px"}} placeholder={detailInfo.account_name}
                                                                   value={accountName} onChange={accountNameChangeHandler}/>
                                                    </div>
                                            </td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                width={"15%"}>계좌번호/환전비번
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <div className="container-fluid p-0 mx-1" style={{
                                                                    display: "flex"
                                                            }}>
                                                                    <input className="form-check-input sm-input" type="text"
                                                                           style={{width: "150px"}} placeholder={detailInfo.account}
                                                                           value={account}
                                                                           onChange={accountChangeHandler}/>&nbsp;
                                                                    <input className="form-check-input sm-input" type="text"
                                                                           style={{width: "100px"}} placeholder={detailInfo.withdraw_pw}
                                                                           value={withdrawPw}
                                                                           onChange={withdrawPwChangeHandler}/>
                                                            </div>
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>상태
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"} colSpan="4">
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <input className="form-check-input" type="radio" value="01" checked={userStateCd === "" ? (detailInfo.userStateCd ==="01" ?true:false) : ( userStateCd ==="01" ? true:false) }
                                                                   onChange={() => userStateCdChangeHandler('01')} style={{ cursor: "pointer" }}/> 정상
                                                            &nbsp;
                                                            <input className="form-check-input" type="radio" value="02" checked={userStateCd === "" ? (detailInfo.userStateCd ==="02" ?true:false) : ( userStateCd ==="02" ? true:false) }
                                                                   onChange={() => userStateCdChangeHandler('02')} style={{ cursor: "pointer" }}/> 정지
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>추천가능
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <input className="form-check-input" type="radio" value="Y" checked={rcmrYn === "" ? (detailInfo.rcmr_yn ==="Y" ?true:false) : ( rcmrYn ==="Y" ? true:false) }
                                                                   onChange={() => rcmrYnChangeHandler('Y')} style={{ cursor: "pointer" }}/>가능
                                                            &nbsp;
                                                            <input className="form-check-input" type="radio" value="N" checked={rcmrYn === "" ? (detailInfo.rcmr_yn ==="N" ?true:false) : ( rcmrYn ==="N" ? true:false) }
                                                                   onChange={() => rcmrYnChangeHandler('N')} style={{ cursor: "pointer" }}/>불가
                                                    </div>
                                            </td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>남은 추천가능 수
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"}>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <div className="container-fluid p-0 mx-1" style={{
                                                                    display: "flex"
                                                            }}>
                                                                    <input className="form-check-input sm-input" type="text"
                                                                           style={{width: "50px"}} placeholder={detailInfo.rcmr_lave_cnt}
                                                                           value={rcmrLaveCnt}
                                                                           onChange={rcmrLaveCntChangeHandler}/>명
                                                            </div>
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>태그
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"} colSpan="4">
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            {changeColorList.map(item => (
                                                                <React.Fragment key={item.cmmn_cd_dtl_id}>

                                                                        <input className="form-check-input" type="checkbox" name="tagRadio1" id="tagRadio1"
                                                                               style={{cursor:"pointer"}}
                                                                               checked={ userDetailState.selectedNos5 ? (userDetailState.selectedNos5.filter((no) => no === item.no).length > 0) : "" }
                                                                               onChange={(e) => {checkHandler5(e.currentTarget.checked, item.no) }}/>

                                                                        <td style={{backgroundColor:item.back_ground_color}} align={"center"} height={"20px"} >
                                                                                <label
                                                                                    htmlFor={`flexColor${item.no}`}
                                                                                    style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                                                                                ><font color={item.font_color}>{item.tag_nm}</font>
                                                                                </label>
                                                                        </td>
                                                                        &nbsp;&nbsp;
                                                                </React.Fragment>
                                                            ))}

                                                    </div>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                width={"15%"}>회원전옹<br></br>입금계좌
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"} colSpan="4">
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <select
                                                                className="form-select sm-select"
                                                                defaultValue={'01'}
                                                                style={{
                                                                        width: "140px"
                                                                }}
                                                                onChange={psnlRcptPrddAccBankCdChangeHandler} value={psnlRcptPrddAccBankCd ? psnlRcptPrddAccBankCd : detailInfo.psnl_rcpt_prdd_acc_bank_cd }
                                                            >
                                                                    {bankList2.map((item, index) => (
                                                                        <option key={item.bank_cd} value={item.bank_cd}>{item.tag_nm}</option>
                                                                    ))}
                                                            </select>
                                                            &nbsp;/&nbsp;계좌번호 :&nbsp;
                                                            <input className="form-check-input sm-input" type="text"
                                                                   style={{width: "150px"}} placeholder={detailInfo.psnl_rcpt_prdd_acc_no}
                                                                   value={psnlRcptPrddAccNo}
                                                                   onChange={psnlRcptPrddAccNoChangeHandler}/>
                                                            &nbsp;/&nbsp;예금주명 :&nbsp;
                                                            <input className="form-check-input sm-input" type="text"
                                                                   style={{width: "70px"}} placeholder={detailInfo.psnl_rcpt_prdd_name}
                                                                   value={psnlRcptPrddName}
                                                                   onChange={psnlRcptPrddNameChangeHandler}/>
                                                    </div>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            * 전용계좌를 넣으시면, 사용자페이지 - 충전 페이지에서 암호 입력시, 자동으로 이 계좌가 나가게 됩니다.
                                                    </div>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            * 즉, 환경설정 - 은행설정에서 설정된 내용보다 우선합니다.
                                                    </div>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            * 이 칸을 비워놓으시면 은행설정에서 설정한 내용대로 적용됩니다.
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                width={"15%"}>회원전옹<br></br>입금계좌<br></br>템플릿
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"} colSpan="4">
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <button style={{
                                                                    backgroundColor: "lightgray",
                                                                    border: "none",
                                                                    color: "black",
                                                                    fontSize: "11px",
                                                                    height: "20px",
                                                                    width: "fit-content",
                                                                    marginRight: "5px"
                                                            }} onClick={addTableRows}>템플릿 추가
                                                            </button>
                                                            * 회원전용 입금계좌를 미리 등록해놓고 변경하실 때 사용하는 기능입니다.
                                                    </div>
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            <table className="table table-bordered table-sm text-center align-middle" style={{width: "100%"}}>
                                                                    <thead>
                                                                    <tr>
                                                                            <th style={{backgroundColor: "lightgray",width: "5%"}} align={"center"}>사용</th>
                                                                            <th style={{backgroundColor: "lightgray",width: "20%"}} align={"center"}>메모</th>
                                                                            <th style={{backgroundColor: "lightgray",width: "20%"}} align={"center"}>은행</th>
                                                                            <th style={{backgroundColor: "lightgray",width: "20%"}} align={"center"}>계좌번호</th>
                                                                            <th style={{backgroundColor: "lightgray",width: "20%"}} align={"center"}>예금주</th>
                                                                            <th style={{backgroundColor: "lightgray",width: "5%"}} align={"center"}>삭제</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows}  />
                                                            </table>
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr height={"110px"}>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>
                                                    <button className="ms-2" style={{
                                                            backgroundColor: "white",
                                                            border: "1px solid black",
                                                            fontSize: "10px",
                                                            height: "30px",
                                                            width: "fit-content"
                                                    }} onClick={addFirstTime1}>현재시간삽입:처음
                                                    </button>
                                                    <br></br>
                                                    메모 1
                                                    <br></br>
                                                    <button className="ms-2" style={{
                                                            backgroundColor: "white",
                                                            border: "1px solid black",
                                                            fontSize: "10px",
                                                            height: "30px",
                                                            width: "fit-content"
                                                    }} onClick={addLastTime1}>현재시간삽입:마지막
                                                    </button>
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"} colSpan="4">
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                        <textarea name="memo1" id="memo1" cols="100" rows="5"
                                                                  value={memo1 ? memo1 : detailInfo.memo1}
                                                                  onChange={memo1ChangeHandler}></textarea>
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr height={"110px"}>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>
                                                    <button className="ms-2" style={{
                                                            backgroundColor: "white",
                                                            border: "1px solid black",
                                                            fontSize: "10px",
                                                            height: "30px",
                                                            width: "fit-content"
                                                    }} onClick={addFirstTime2}>현재시간삽입:처음
                                                    </button>
                                                    <br></br>
                                                    메모 2
                                                    <br></br>
                                                    <button className="ms-2" style={{
                                                            backgroundColor: "white",
                                                            border: "1px solid black",
                                                            fontSize: "10px",
                                                            height: "30px",
                                                            width: "fit-content"
                                                    }} onClick={addLastTime2}>현재시간삽입:마지막
                                                    </button>
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"} colSpan="4">
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                        <textarea name="memo2" id="memo2" cols="100" rows="5"
                                                                  value={memo2 ? memo2 : detailInfo.memo2}
                                                                  onChange={memo2ChangeHandler}></textarea>
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr height={"110px"}>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>
                                                    <button className="ms-2" style={{
                                                            backgroundColor: "white",
                                                            border: "1px solid black",
                                                            fontSize: "10px",
                                                            height: "30px",
                                                            width: "fit-content"
                                                    }} onClick={addFirstTime3}>현재시간삽입:처음
                                                    </button>
                                                    <br></br>
                                                    메모 3
                                                    <br></br>
                                                    <button className="ms-2" style={{
                                                            backgroundColor: "white",
                                                            border: "1px solid black",
                                                            fontSize: "10px",
                                                            height: "30px",
                                                            width: "fit-content"
                                                    }} onClick={addLastTime3}>현재시간삽입:마지막
                                                    </button>
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"} colSpan="4">
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                        <textarea name="memo3" id="memo3" cols="100" rows="5"
                                                                  value={memo3 ? memo3 : detailInfo.memo3}
                                                                  onChange={memo3ChangeHandler}></textarea>
                                                    </div>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"}
                                                width={"15%"}>가입시점<br></br>추천인키 정보
                                            </td>
                                            <td width={"1%"}></td>
                                            <td width={"34%"} align={"left"} colSpan="4">
                                                    <div className="container-fluid p-0 mx-1" style={{
                                                            display: "flex"
                                                    }}>
                                                            가입 도메인 : / 사용한 추천인키 :
                                                            <br></br>
                                                            가입시점 추천인키 정보 : []
                                                    </div>
                                            </td>
                                    </tr>
                            </table>

                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                            display: "flex"
                    }}>

                            <table className="table table-bordered table-sm text-center align-middle" style={{width: "100%"}}>
                                    <tr>
                                            <td align={"left"} colSpan={18}> <b>추천인 목록</b></td>
                                    </tr>
                                    <tr>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"2%"}>순서</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>아이디</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>닉네임</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>이름</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>가맹점</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>게임머니</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>포인트</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>회원등급</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>회원상태</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>P등급</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>핸드폰</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>문자</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>충전</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>환전</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>마진</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>가입일</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>최근활동</td>
                                            <td style={{backgroundColor: "lightgray"}} align={"center"} width={"6%"}>로그인</td>
                                    </tr>


                                    { recommendInfo === null && (
                                        <tbody>
                                        <tr>
                                                <td colSpan="18">추천인이 없습니다.</td>
                                        </tr>
                                        </tbody>
                                    )}
                                    { recommendInfo != null &&  recommendInfo.map((recommendInfo, index) => (
                                        <tbody key={recommendInfo.no}>
                                        <td align={"center"}>{recommendInfo.no}</td>
                                        <td align={"center"}>{recommendInfo.id}</td>
                                        <td align={"center"}>{recommendInfo.nickname}</td>
                                        <td align={"center"}>{recommendInfo.name}</td>
                                        <td align={"center"}>{recommendInfo.mrch_cd}</td>
                                        <td align={"center"}>{recommendInfo.game_money_amt}</td>
                                        <td align={"center"}>{recommendInfo.point}</td>
                                        <td align={"center"}>{recommendInfo.user_gb_cd}</td>
                                        <td align={"center"}>{recommendInfo.user_state_cd}</td>
                                        <td align={"center"}>{recommendInfo.pnt_gd_cd}</td>
                                        <td align={"center"}>{recommendInfo.mbph_start}-{recommendInfo.mbph_center}-{recommendInfo.mbph_end}</td>
                                        <td align={"center"}>-</td>
                                        <td align={"center"}>{recommendInfo.deposit_amt}</td>
                                        <td align={"center"}>{recommendInfo.withdraw_amt}</td>
                                        <td align={"center"}>{recommendInfo.profit_amt}</td>
                                        <td align={"center"}>-</td>
                                        <td align={"center"}>-</td>
                                        <td align={"center"}>-</td>
                                        </tbody>
                                    ))}
                            </table>
                    </div>
            </div>



        )
}
export default MemberInformationDetailModal;