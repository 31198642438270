import React from 'react';
import MoneyStatContainer from "../../containers/deposit/MoneyStatContainer";


const MoneyStat = () => {
    return (
        <div>
            <MoneyStatContainer />
        </div>
    );
};

export default MoneyStat;