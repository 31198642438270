import {call, put} from "redux-saga/effects";
import {failedGetRecommendKeyListReducer, setRecommendKeyListReducer} from "../../reducers/partner/RecommendKeyListReducer";
import {fetchRecommendKeyList} from "../../../lib/api";

export function* getRecommendKeyListSaga(action) {
    try{
        console.log("##getRecommendKeyListSaga input#",action.payload.input)
        const res = yield call(fetchRecommendKeyList,
            action.payload.input,
        );
        console.log("##getRecommendKeyListSaga output#",res.data)
        yield put(setRecommendKeyListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetRecommendKeyListReducer(error))
    }
}