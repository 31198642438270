import {call, put, take,} from "redux-saga/effects";
import {fetchPointList} from "../../../lib/api";
import {failedGetPointList, setPointList} from "../../reducers/deposit/PointListReducer";

export function* getPointListSaga(action) {
    try{
        const res = yield call(
            fetchPointList,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd, action.payload.selectedNos, action.payload.userId,
            action.payload.sessionId, action.payload.sessionLevel
        )
        yield put(setPointList(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetPointList(error))
    }
}