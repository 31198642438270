
import React from "react";

const NormGameStcsPage = () => {
    return (
        <div>
            <h7>일반게임통계</h7>
        </div>

    )
}
export default NormGameStcsPage;