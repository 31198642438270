import {all, fork, takeLatest} from 'redux-saga/effects';
import {setUserInfoSaga} from "./userInfo";
import {getMemberSaga} from "./user/member";
import {getMemberUser} from "../reducers/user/MemberReducer";
import {getDepositListSaga} from "./deposit/DepositListSaga";
import {getDepositList} from "../reducers/deposit/DepositListReducer";
import {getWithdrawListSaga} from "./deposit/WithdrawListSaga";
import {getWithdrawList} from "../reducers/deposit/WithdrawListReducer";
import {getMoneyListSaga} from "./deposit/MoneyListSaga";
import {getMoneyList} from "../reducers/deposit/MoneyListReducer";
import {getMoneyStatSaga} from "./deposit/MoneyStatSaga";
import {getMoneyStat} from "../reducers/deposit/MoneyStatReducer";
import {getPointListSaga} from "./deposit/PointListSaga";
import {getPointList} from "../reducers/deposit/PointListReducer";
import {getRecommendKeyListSaga} from "./partner/RecommendKeyListSaga";
import {getRecommendKeyListReducer} from "../reducers/partner/RecommendKeyListReducer";

import {getRtmSttlItmSaga} from "./rtmSttl/RtmSttlItmSaga";
import {getRtmSttlItm} from "../reducers/rtmSttl/rtmSttlItm";

import {getPartnerMctSttlSaga} from "./deposit/PartnerMctSttlSaga";
import {getPartnerMctSttl} from "../reducers/deposit/PartnerMctSttlReducer";



export function* watcherSaga() {
    yield all([
        fork(setUserInfoSaga)
    ])
    yield takeLatest(getMemberUser.type, getMemberSaga)
    yield takeLatest(getDepositList.type, getDepositListSaga)
    yield takeLatest(getWithdrawList.type, getWithdrawListSaga)
    yield takeLatest(getMoneyList.type, getMoneyListSaga)
    yield takeLatest(getMoneyStat.type, getMoneyStatSaga)
    yield takeLatest(getPointList.type, getPointListSaga)
    yield takeLatest(getRecommendKeyListReducer.type, getRecommendKeyListSaga)
    yield takeLatest(getRtmSttlItm.type, getRtmSttlItmSaga)
    yield takeLatest(getPartnerMctSttl.type, getPartnerMctSttlSaga)


}

