import axios from "axios";

export const requestLogin = async (id, pw) => {
    console.log("requestLoginrequestLoginrequestLoginrequestLoginrequestLogin");
    return axios.post("/api/sign/login", {id: id, pw: pw})
}

export function fetchMember(page, pageSize, query, order, startDate, endDate, userGbCd, sortColumn, sortValue, searchGb,
                            searchCd01,searchCd02,searchCd03,searchCd04,searchCd05,searchCd06,searchCd07,searchCd08,searchCd09,searchCd10,selectedNos,
                            selectedNos2,selectedNos3,selectedNos4,selectedNos5,selectedNos6,selectedNos7,selectedNos8,selectedNos9,
                            searchIdArr,searchIdSort,searchNicknameArr,searchNicknameSort,sessionId,sessionLevel
) {

    return axios.get("/partner/member/getMemberUser", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
            userGbCd: userGbCd,
            sortColumn: sortColumn,
            sortValue: sortValue,
            searchGb: searchGb,
            searchCd01: searchCd01,
            searchCd02: searchCd02,
            searchCd03: searchCd03,
            searchCd04: searchCd04,
            searchCd05: searchCd05,
            searchCd06: searchCd06,
            searchCd07: searchCd07,
            searchCd08: searchCd08,
            searchCd09: searchCd09,
            searchCd10: searchCd10,
            selectedNos: selectedNos,
            selectedNos2: selectedNos2,
            selectedNos3: selectedNos3,
            selectedNos4: selectedNos4,
            selectedNos5: selectedNos5,
            selectedNos6: selectedNos6,
            selectedNos7: selectedNos7,
            selectedNos8: selectedNos8,
            selectedNos9: selectedNos9,
            searchIdArr: searchIdArr,
            searchIdSort: searchIdSort,
            searchNicknameArr: searchNicknameArr,
            searchNicknameSort: searchNicknameSort,
            sessionId:sessionId,
            sessionLevel:sessionLevel
        }
    })
}

export const fetchDepositList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd,
                                       searchCd01,searchCd02,searchCd03,searchCd04,searchCd05,searchCd06,searchCd07,searchCd08,searchCd09,searchCd10,
                                       searchCd11,searchCd12,searchCd13,searchCd14,searchCd15,searchCd16,keyCd,valueCd,userId, selectedNos, selectedNos3, selectedNos4,
                                       sessionId,sessionLevel
) => {

    return axios.post("/partner/depositList/getDepositList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        searchCd01: searchCd01,
        searchCd02: searchCd02,
        searchCd03: searchCd03,
        searchCd04: searchCd04,
        searchCd05: searchCd05,
        searchCd06: searchCd06,
        searchCd07: searchCd07,
        searchCd08: searchCd08,
        searchCd09: searchCd09,
        searchCd10: searchCd10,
        searchCd11: searchCd11,
        searchCd12: searchCd12,
        searchCd13: searchCd13,
        searchCd14: searchCd14,
        searchCd15: searchCd15,
        searchCd16: searchCd16,
        keyCd: keyCd,
        valueCd: valueCd,
        userId: userId,
        selectedNos: selectedNos,
        selectedNos3: selectedNos3,
        selectedNos4: selectedNos4,
        sessionId:sessionId,
        sessionLevel:sessionLevel
    })
}

export const fetchWithdrawList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd,
                                        searchCd01,searchCd02,searchCd03,searchCd04,searchCd05,searchCd06,searchCd07,searchCd08,searchCd09,searchCd10,
                                        searchCd11,searchCd12,searchCd13,searchCd14,searchCd15,searchCd16,keyCd,valueCd,userId, selectedNos, selectedNos3, selectedNos4,
                                        sessionId,sessionLevel) => {
    return axios.post("/partner/withdrawList/getWithdrawList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        searchCd01: searchCd01,
        searchCd02: searchCd02,
        searchCd03: searchCd03,
        searchCd04: searchCd04,
        searchCd05: searchCd05,
        searchCd06: searchCd06,
        searchCd07: searchCd07,
        searchCd08: searchCd08,
        searchCd09: searchCd09,
        searchCd10: searchCd10,
        searchCd11: searchCd11,
        searchCd12: searchCd12,
        searchCd13: searchCd13,
        searchCd14: searchCd14,
        searchCd15: searchCd15,
        searchCd16: searchCd16,
        keyCd: keyCd,
        valueCd: valueCd,
        userId: userId,
        selectedNos: selectedNos,
        selectedNos3: selectedNos3,
        selectedNos4: selectedNos4,
        sessionId:sessionId,
        sessionLevel:sessionLevel
    })
}

export const fetchMoneyList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd, userId, sessionId,sessionLevel) => {
    return axios.post("/partner/moneyList/getMoneyList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        userId: userId,
        sessionId:sessionId,
        sessionLevel:sessionLevel
    })
}
export const fetchMoneyStat = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd, searchCd01, sessionId,sessionLevel) => {
    return axios.post("/admin/moneyStat/getMoneyStat", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        searchCd01: searchCd01,
        sessionId:sessionId,
        sessionLevel:sessionLevel
    })
}

//공통코드 가져오기
//input tableId(테이블id-필수),columnNm(컬럼명-필수),useYn(사용여부),inCd(조회할코드),notInCd(조회하지을코드)
export function fetchCmmnCd(tableId, columnNm, useYn, inCd, notInCd) {
    return axios.post("/admin/getCmmnCd", {
            tableId: tableId,
            columnNm: columnNm,
            useYn: useYn,
            inCd: inCd,
            notInCd: notInCd,
    })
}

export const insertAdminLog = async (input) => {
    return axios.post("/partner/admin/insertLog", input)
}

export const fetchPointList = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd, selectedNos, userId, sessionId,sessionLevel) => {
    return axios.post("/partner/pointList/getPointList", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        selectedNos: selectedNos,
        userId: userId,
        sessionId:sessionId,
        sessionLevel:sessionLevel
    })
}

export const fetchRecommendKeyList = async (input) => {
    return axios.post("/partner/partner/getRecommendKeyList", input)
}

export const fetchRtmSttlItm = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd, selectedNos, userId, sessionId,sessionLevel) => {
    return axios.post("/partner/rtmSttlItm/getRtmSttlItm", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        selectedNos: selectedNos,
        userId: userId,
        sessionId:sessionId,
        sessionLevel:sessionLevel
    })
}


export const fetchPartnerMctSttl = async (page, pageSize, query, order, startDate, endDate, userGbCd, searchGbCd, selectedNos, userId, sessionId,sessionLevel) => {
    return axios.post("/partner/partnerMctSttl/getPartnerMctSttl", {
        page: page,
        pageSize: pageSize,
        query: query,
        order: order,
        startDate: startDate,
        endDate: endDate,
        userGbCd: userGbCd,
        searchGbCd: searchGbCd,
        selectedNos: selectedNos,
        userId: userId,
        sessionId:sessionId,
        sessionLevel:sessionLevel
    })
}

