import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {NumericFormat} from "react-number-format";
const SttlAmtPayment = ({sttlAmtPaymentList, onRefresh}) => {
    const dispatch = useDispatch()


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>


                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead >
                        <tr>
                            <th style={{width: "2%"}}>번호</th>
                            <th style={{width: "5%"}}>구분</th>
                            <th style={{width: "10%"}}>뱅킹</th>
                            <th style={{width: "5%"}}>정산방식</th>
                            <th style={{width: "7%"}}>정산액</th>
                            <th style={{width: "5%"}}>지급률</th>
                            <th style={{width: "7%"}}>지급액</th>
                            <th style={{width: "5%"}}>지급아이디</th>
                            <th style={{width: "15%"}}>신청기간</th>
                            <th style={{width: "10%"}}>정산일시</th>
                            <th style={{width: "*%"}}>정산시점 데이터 조회</th>
                        </tr>
                        </thead>
                        {!sttlAmtPaymentList.length && (
                            <tbody key={sttlAmtPaymentList.no}>
                            <tr>
                                <td colSpan="11">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {sttlAmtPaymentList.map((sttlAmtPaymentList, index) => (
                            <tbody key={sttlAmtPaymentList.no}>
                            <tr>
                                <td align={"center"}>{sttlAmtPaymentList.no}</td>
                                <td align={"center"}>{sttlAmtPaymentList.adjustment_cd}</td>
                                <td align={"center"}>{sttlAmtPaymentList.bank}({sttlAmtPaymentList.account})</td>
                                <td align={"center"}>-</td>
                                <td align={"center"}>
                                    <NumericFormat className="ms-1" value={sttlAmtPaymentList.pay_adjustment_amt} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td align={"center"}>{sttlAmtPaymentList.losing_ratio}</td>
                                <td align={"center"}>
                                    <NumericFormat className="ms-1" value={sttlAmtPaymentList.adjustment_amt} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td align={"center"}>{sttlAmtPaymentList.reg_id}</td>
                                <td align={"center"}>{sttlAmtPaymentList.before_adjustment_dttm} ~ {sttlAmtPaymentList.after_adjustment_dttm}</td>
                                <td align={"center"}>{sttlAmtPaymentList.createdAt}</td>
                                <td align={"center"}>-</td>
                            </tr>
                            </tbody>
                        ))}
                    </table>

                </div>

            </div>
        </div>

    )
}

export default SttlAmtPayment;