import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';

const MemberAddComponent = (props) => {



    const [userGbList, setUserGbList] = useState([]);
    const [pntGbList, setPntGbList] = useState([]);
    const [orgList, setOrgList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [bankList2, setBankList2] = useState([]);
    const [recommendList, setRecommendList] = useState([]);

    const userInfo = useSelector((state) => state.userInfo)
    const {open, onClose} = props
    const [siteCd, setSiteCd] = useState('')
    const [storeCd, setStoreCd] = useState('01')
    const [org, setOrg] = useState(0)
    const [name, setName] = useState('')
    const [nickName, setNickName] = useState('')
    const [duplNickName, duplNickNameCheck] = useState(false)
    const [id, setId] = useState('')
    const [duplId, duplIdCheck] = useState(false)
    const [pw, setPw] = useState('')
    const [mbphStart, setMbphStart] = useState('')
    const [mbphCenter, setMbphCenter] = useState('')
    const [mbphEnd, setMbphEnd] = useState('')
    const [smsYn, setSmsYn] = useState('')
    const [email, setEmail] = useState('')
    const [upRcmrId, setUpRcmrId] = useState('')
    const [userGbCd, setUserGbCd] = useState('')
    const [pntGbCd, setPntGbCd] = useState('')
    const [bankCd, setBankCd] = useState('')
    const [accountName, setAccountName] = useState('')
    const [account, setAccount] = useState('')
    const [withdrawPw, setWithdrawPw] = useState('')
    const [userStateCd, setUserStateCd] = useState('')
    const [rcmrYn, setRcmrYn] = useState('')
    const [rcmrLaveCnt, setRcmrLaveCnt] = useState('')
    const [psnlRcptPrddAccBankCd, setPsnlRcptPrddAccBankCd] = useState('')
    const [psnlRcptPrddAccNo, setPsnlRcptPrddAccNo] = useState('')
    const [psnlRcptPrddName, setPsnlRcptPrddName] = useState('')
    const [memo1, setMemo1] = useState('')
    const [memo2, setMemo2] = useState('')
    const [memo3, setMemo3] = useState('')
    const [recommend, setRecommend] = useState('')


    const getCmmnCd = () => {
        if(userGbList.length === 0){
            axios.post('/admin/cmmnCdList', {
                cmmnCdId : '0002',
            }).then((res) => {
                setUserGbList(res.data.cmmnCdList);
            })
        }
        if(pntGbList.length === 0){
            axios.post('/admin/cmmnCdList', {
                cmmnCdId : '0004',
            }).then((res) => {
                setPntGbList(res.data.cmmnCdList);
            })
        }
        if(orgList.length === 0){
            axios.post('/admin/member/getOrg', {
                cmmnCdId : '01',
            }).then((res) => {
                setOrgList(res.data.cmmnCdList);
            })
        }
        if(bankList.length === 0){
            axios.post('/admin/member/getBank', {
                cmmnCdId : '01',
            }).then((res) => {
                setBankList(res.data.cmmnCdList);
            })
        }
        if(bankList2.length === 0){
            axios.post('/admin/member/getBank', {
                cmmnCdId : '01',
            }).then((res) => {
                setBankList2(res.data.cmmnCdList);
            })
        }

        if(recommendList.length === 0){
            axios.post('/admin/member/getRecommend', {
                cmmnCdId : '01',
            }).then((res) => {
                setRecommendList(res.data.cmmnCdList);
            })
        }


    }
    useEffect(getCmmnCd, [])


    const siteCdChangeHandler = (e) => {
        setSiteCd(e.currentTarget.value);
    }
    const storeCdChangeHandler = (e) => {
        setStoreCd(e.currentTarget.value);
    }
    const nameChangeHandler = (e) => {
        setName(e.currentTarget.value);
        const onlyKorean = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'/\\]/g;
        if (onlyKorean.test(name)) {
            alert("한글만 입력바랍니다")
            setName("")
        }
    }
    const nickNameChangeHandler = (e) => {
        setNickName(e.currentTarget.value);
    }
    const idChangeHandler = (e) => {
        setId(e.currentTarget.value);
        const notKorean = /[ㄱ-ㅎ가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'\\]/g;
        if (notKorean.test(id)) {
            alert("영어,숫자조합만 가능합니다")
            setId("")
        }
    }
    const pwChangeHandler = (e) => {
        setPw(e.currentTarget.value);
        const notKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'/\\]/g;
        if (notKorean.test(pw)) {
            alert("영어,숫자조합만 가능합니다")
            setPw("")
        }
    }
    const orgCdChangeHandler = (e) => {
        setOrg(e.currentTarget.value);
    }
    const mbphStartChangeHandler = (e) => {
        setMbphStart(e.currentTarget.value);
    }
    const mbphCenterChangeHandler = (e) => {
        setMbphCenter(e.currentTarget.value);
    }
    const mbphEndChangeHandler = (e) => {
        setMbphEnd(e.currentTarget.value);
    }
    const smsYnChangeHandler = (type) => {
        setSmsYn(type)
    }
    const emailChangeHandler = (e) => {
        setEmail(e.currentTarget.value);
    }
    const upRcmrIdChangeHandler = (e) => {
        setUpRcmrId(e.currentTarget.value);
    }
    const userGbCdChangeHandler = (e) => {
        setUserGbCd(e.currentTarget.value);
    }
    const pntGbCdChangeHandler = (e) => {
        setPntGbCd(e.currentTarget.value);
    }
    const bankCdChangeHandler = (e) => {
        setBankCd(e.currentTarget.value);
    }
    const accountNameChangeHandler = (e) => {
        setAccountName(e.currentTarget.value);
    }
    const accountChangeHandler = (e) => {
        setAccount(e.currentTarget.value);
    }
    const withdrawPwChangeHandler = (e) => {
        setWithdrawPw(e.currentTarget.value);
    }
    const userStateCdChangeHandler = (type) => {
        setUserStateCd(type)
    }
    const rcmrYnChangeHandler = (type) => {
        setRcmrYn(type)
    }
    const rcmrLaveCntChangeHandler = (e) => {
        setRcmrLaveCnt(e.currentTarget.value);
    }
    const psnlRcptPrddAccBankCdChangeHandler = (e) => {
        setPsnlRcptPrddAccBankCd(e.currentTarget.value);
    }
    const psnlRcptPrddAccNoChangeHandler = (e) => {
        setPsnlRcptPrddAccNo(e.currentTarget.value);
    }
    const psnlRcptPrddNameChangeHandler = (e) => {
        setPsnlRcptPrddName(e.currentTarget.value);
    }
    const memo1ChangeHandler = (e) => {
        setMemo1(e.currentTarget.value);
    }
    const memo2ChangeHandler = (e) => {
        setMemo2(e.currentTarget.value);
    }
    const memo3ChangeHandler = (e) => {
        setMemo3(e.currentTarget.value);
    }
    const recommendChangeHandler = (e) => {
        setRecommend(e.currentTarget.value);
    }

    const addFirstTime1 = () => {
        const nowTime = moment().format('YYYY-MM-DD HH:mm:ss');
        setMemo1(nowTime+"\n"+memo1);
    }
    const addLastTime1 = () => {
        const nowTime = moment().format('YYYY-MM-DD HH:mm:ss');
        setMemo1(memo1+"\n"+nowTime);
    }
    const addFirstTime2 = () => {
        const nowTime = moment().format('YYYY-MM-DD HH:mm:ss');
        setMemo2(nowTime+"\n"+memo2);
    }
    const addLastTime2 = () => {
        const nowTime = moment().format('YYYY-MM-DD HH:mm:ss');
        setMemo2(memo2+"\n"+nowTime);
    }
    const addFirstTime3 = () => {
        const nowTime = moment().format('YYYY-MM-DD HH:mm:ss');
        setMemo3(nowTime+"\n"+memo3);
    }
    const addLastTime3 = () => {
        const nowTime = moment().format('YYYY-MM-DD HH:mm:ss');
        setMemo3(memo3+"\n"+nowTime);
    }

    const onNickNameCheckHandler = (e) => {
        axios.post('/admin/member/duplicatedUserNickName', {
            nickname: nickName
        }).then((res) => {
            if(res.data.isUpdated) {
                alert("중복되는 닉네임입니다.")
                duplNickNameCheck(false);
            } else {
                alert("이용가능한 닉네임입니다.")
                duplNickNameCheck(true);
            }
        })
    }


    const onIdCheckHandler = (e) => {
        axios.post('/admin/member/duplicatedUserId', {
            id: id
        }).then((res) => {
            if(res.data.isUpdated) {
                alert("중복되는 아이디입니다.")
                duplIdCheck(false);
            } else {
                alert("이용가능한 아이디입니다.")
                duplIdCheck(true);
            }
        })
    }




    const handleCancel = () => {
        onClose()
    }
    const handleRegister = () => {
        if(siteCd ==="") {
            alert("SITE CODE를 입력해주세요")
        }
        else if(org == 0) {
            alert("소속을 입력해주세요")
        }
        else if(name ==="") {
            alert("이름을 입력해주세요")
        }
        else if(nickName ==="") {
            alert("닉네임을 입력해주세요")
        }
        else if(id ==="") {
            alert("아이디를 입력해주세요")
        }
        else if(pw ==="") {
            alert("신규 비밀번호를 입력해주세요")
        }
        else if( duplId == false )
        {
            alert("아이디 중복 확인을 확인해주세요")
        }
        else if( duplNickName == false )
        {
            alert("닉네임 중복 확인을 확인해주세요")
        }
        else
        {
            if(window.confirm("등록 하시겠습니까?"))
            {

                // console.log("siteCd : "+siteCd);
                // console.log("storeCd : "+storeCd);
                // console.log("name : "+name);
                // console.log("nickName : "+nickName);
                // console.log("id : "+id);
                // console.log("pw : "+pw);
                // console.log("mbphStart : "+mbphStart);
                // console.log("mbphCenter : "+mbphCenter);
                // console.log("mbphEnd : "+mbphEnd);
                // console.log("smsYn : "+smsYn);
                // console.log("email : "+email);
                // console.log("upRcmrId : "+upRcmrId);
                // console.log("userGbCd : "+userGbCd);
                // console.log("pntGbCd : "+pntGbCd);
                // console.log("accountName : "+accountName);
                // console.log("account : "+account);
                // console.log("withdrawPw : "+withdrawPw);
                // console.log("userStateCd : "+userStateCd);
                // console.log("rcmrYn : "+rcmrYn);
                // console.log("rcmrLaveCnt : "+rcmrLaveCnt);
                // console.log("psnlRcptPrddAccBankCd : "+psnlRcptPrddAccBankCd);
                // console.log("psnlRcptPrddAccNo : "+psnlRcptPrddAccNo);
                // console.log("psnlRcptPrddName : "+psnlRcptPrddName);
                // console.log("memo1 : "+memo1);
                // console.log("memo2 : "+memo2);
                // console.log("memo3 : "+memo3);

                axios.post('/admin/member/insert', {
                    siteCd: siteCd,
                    storeCd: org,
                    name: name,
                    nickName: nickName,
                    id: id,
                    pw: pw,
                    bankCd: bankCd,
                    mbphStart: mbphStart,
                    mbphCenter: mbphCenter,
                    mbphEnd: mbphEnd,
                    smsYn: smsYn,
                    email: email,
                    upRcmrId: upRcmrId,
                    userGbCd: userGbCd,
                    pntGbCd: pntGbCd,
                    accountName: accountName,
                    account: account,
                    withdrawPw: withdrawPw,
                    userStateCd: userStateCd,
                    rcmrYn: rcmrYn,
                    rcmrLaveCnt: rcmrLaveCnt,
                    psnlRcptPrddAccBankCd: psnlRcptPrddAccBankCd,
                    psnlRcptPrddAccNo: psnlRcptPrddAccNo,
                    psnlRcptPrddName: psnlRcptPrddName,
                    memo1: memo1,
                    memo2: memo2,
                    memo3: memo3,
                    reg_id: userInfo.id,
                    upd_id: userInfo.id,
                    reg_ip: userInfo.ip,
                    recommend: recommend
                })
                    .then((res) => {
                        if (res.data.isInserted) {
                            onClose();
                            alert('가입완료')
                        } else {
                            alert('가입실패')
                        }
                    })

            }
        }
    }





    return (
        <div className="partner_modal_background">
            <div className="partner_modal_box" >
                <div className="partner_modal_body">

                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <br></br>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table   width={"99%"}>
                            <tr>
                                <td align={"right"}>
                                    <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "10px"}} onClick={handleRegister}>회원 등록</button>
                                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={handleCancel}>닫기</button>
                                </td>
                            </tr>
                        </table>
                    </div>


                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>

                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "98%"}}>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>SITE CODE</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"} colspan="4">
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <input className="form-check-input sm-input" type="text" style={{ width: "120px" }} placeholder="" value={siteCd} onChange={siteCdChangeHandler}/>
                                    </div>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        * SITE CODE는, 개발자에 의해 발급된 코드만 사용하셔야 합니다.
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>* 소속</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <select
                                            className="form-select sm-select"
                                            defaultValue={0}
                                            style={{
                                                width: "250px"
                                            }}
                                            onChange={orgCdChangeHandler} value={org}
                                        >
                                            <option key='00' value={0}>::총판선택::</option>
                                            {orgList.map((item, index) => (
                                                <option key={item.id} value={item.id}>{(item.lev2 === '총판직영' ? '● '+item.lev1+'[총판직영]' : 'ㄴ'+item.lev1+'['+item.lev2+']'  )}</option>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>* 이름</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <input className="form-check-input sm-input" type="text" style={{ width: "120px" }} placeholder="" value={name} onChange={nameChangeHandler}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>* 닉네임</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <input className="form-check-input sm-input" type="text" style={{ width: "120px" }} placeholder="" value={nickName} onChange={nickNameChangeHandler} />&nbsp;
                                        <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={onNickNameCheckHandler}>닉네임 중복 확인</button>
                                    </div>
                                </td>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>* 아이디</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <input className="form-check-input sm-input" type="text" style={{ width: "120px" }} placeholder="" value={id} onChange={idChangeHandler}/>&nbsp;
                                        <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={onIdCheckHandler} >아이디 중복 확인</button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>* 신규 비밀번호</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"} colSpan="4">
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <input className="form-check-input sm-input" type="text"
                                               style={{width: "120px"}} placeholder="" value={pw} onChange={pwChangeHandler} />
                                    </div>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>핸드폰</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <input className="form-check-input sm-input" type="text" style={{ width: "40px" }} placeholder="" value={mbphStart} onChange={mbphStartChangeHandler}/>-
                                        <input className="form-check-input sm-input" type="text" style={{ width: "40px" }} placeholder="" value={mbphCenter} onChange={mbphCenterChangeHandler}/>-
                                        <input className="form-check-input sm-input" type="text" style={{ width: "40px" }} placeholder="" value={mbphEnd} onChange={mbphEndChangeHandler}/>
                                    </div>
                                </td>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>문자수신여부</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <input className="form-check-input me-1" type="radio"
                                               name="flexRadio44" id="flexRad22" name="smsYn" id="smsYn" value={smsYn} onChange={() => smsYnChangeHandler('Y')} />수신
                                        &nbsp;
                                        <input className="form-check-input me-1" type="radio"
                                               name="flexRadio44" id="flexRad22" name="smsYn" id="smsYn" value={smsYn} onChange={() => smsYnChangeHandler('N')} />거부
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>EMAIL</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <input className="form-check-input sm-input" type="text" style={{ width: "120px" }} placeholder="" value={email} onChange={emailChangeHandler}/>&nbsp;
                                    </div>
                                </td>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>추천인 id</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input className="form-check-input sm-input" type="text" style={{ width: "120px" }} placeholder="" value={upRcmrId} onChange={upRcmrIdChangeHandler}/>&nbsp;
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>회원분류</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <select
                                            className="form-select sm-select"
                                            defaultValue={'01'}
                                            style={{
                                                width: "100px"
                                            }}
                                            onChange={userGbCdChangeHandler} value={userGbCd}
                                        >
                                            {userGbList.map((item, index) => (
                                                <option key={item.cmmn_cd_dtl_id} value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>포인트등급</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <select
                                                className="form-select sm-select"
                                                defaultValue={'01'}
                                                style={{
                                                    width: "100px"
                                                }}
                                                onChange={pntGbCdChangeHandler} value={pntGbCd}
                                            >
                                                {pntGbList.map((item, index) => (
                                                    <option key={item.cmmn_cd_dtl_id} value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>은행/예금주</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <select
                                            className="form-select sm-select"
                                            defaultValue={'01'}
                                            style={{
                                                width: "140px"
                                            }}
                                            onChange={bankCdChangeHandler} value={bankCd}
                                        >
                                            {bankList.map((item, index) => (
                                                <option key={item.bank_cd} value={item.bank_cd}>{item.tag_nm}</option>
                                            ))}
                                        </select>
                                        &nbsp;
                                        <input className="form-check-input sm-input" type="text" style={{ width: "120px" }} placeholder="" value={accountName} onChange={accountNameChangeHandler}/>
                                    </div>
                                </td>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>계좌번호/환전비번</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input className="form-check-input sm-input" type="text" style={{ width: "150px" }} placeholder="" value={account} onChange={accountChangeHandler}/>&nbsp;
                                            <input className="form-check-input sm-input" type="text" style={{ width: "100px" }} placeholder="" value={withdrawPw} onChange={withdrawPwChangeHandler}/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>상태</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"} colSpan="4">
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <input className="form-check-input me-1" type="radio"
                                               name="flexRadio44" id="flexRad22" name="userStateCd" id="userStateCd" value={userStateCd} onChange={() => userStateCdChangeHandler('01')} />정상
                                        &nbsp;
                                        <input className="form-check-input me-1" type="radio"
                                               name="flexRadio44" id="flexRad22" name="userStateCd" id="userStateCd" value={userStateCd} onChange={() => userStateCdChangeHandler('02')} />정지
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>추천가능</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>

                                        <input className="form-check-input me-1" type="radio"
                                               name="flexRadio44" id="flexRad22" name="rcmrYn" id="rcmrYn" value={rcmrYn} onChange={() => rcmrYnChangeHandler('Y')} />가능
                                        &nbsp;
                                        <input className="form-check-input me-1" type="radio"
                                               name="flexRadio44" id="flexRad22" name="rcmrYn" id="rcmrYn" value={rcmrYn} onChange={() => rcmrYnChangeHandler('N')} />불가
                                    </div>
                                </td>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"15%"}>남은 추천가능 수</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"}>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <div className="container-fluid p-0 mx-1" style={{
                                            display: "flex"
                                        }}>
                                            <input className="form-check-input sm-input" type="text" style={{ width: "50px" }} placeholder="0" value={rcmrLaveCnt} onChange={rcmrLaveCntChangeHandler}/>명
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>회원전옹<br></br>입금계좌</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"} colSpan="4">
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <select
                                            className="form-select sm-select"
                                            defaultValue={'01'}
                                            style={{
                                                width: "140px"
                                            }}
                                            onChange={psnlRcptPrddAccBankCdChangeHandler} value={psnlRcptPrddAccBankCd}
                                        >
                                            {bankList2.map((item, index) => (
                                                <option key={item.bank_cd} value={item.bank_cd}>{item.tag_nm}</option>
                                            ))}
                                        </select>
                                        &nbsp;/&nbsp;계좌번호 :&nbsp;
                                        <input className="form-check-input sm-input" type="text"
                                               style={{width: "120px"}} placeholder="" value={psnlRcptPrddAccNo} onChange={psnlRcptPrddAccNoChangeHandler}/>
                                        &nbsp;/&nbsp;/ 예금주명 :&nbsp;
                                        <input className="form-check-input sm-input" type="text"
                                               style={{width: "70px"}} placeholder="" value={psnlRcptPrddName} onChange={psnlRcptPrddNameChangeHandler}/>
                                    </div>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        * 전용계좌를 넣으시면, 사용자페이지 - 충전 페이지에서 암호 입력시, 자동으로 이 계좌가 나가게 됩니다.
                                    </div>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        * 즉, 환경설정 - 은행설정에서 설정된 내용보다 우선합니다.
                                    </div>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        * 이 칸을 비워놓으시면 은행설정에서 설정한 내용대로 적용됩니다.
                                    </div>
                                </td>
                            </tr>
                            <tr height={"110px"}>
                                <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>
                                    <button className="ms-2" style={{ backgroundColor: "white", border: "1px solid black",fontSize: "10px", height: "30px", width: "fit-content"}} onClick={addFirstTime1}>현재시간삽입:처음</button>
                                    <br></br>
                                    메모 1
                                    <br></br>
                                    <button className="ms-2" style={{ backgroundColor: "white", border: "1px solid black",fontSize: "10px", height: "30px", width: "fit-content"}} onClick={addLastTime1}>현재시간삽입:마지막</button>
                                </td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"} colSpan="4">
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <textarea name="memo1" id="memo1" cols="100" rows="5" value={memo1} onChange={memo1ChangeHandler}></textarea>
                                    </div>
                                </td>
                            </tr>
                            <tr height={"110px"}>
                                <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>
                                    <button className="ms-2" style={{ backgroundColor: "white", border: "1px solid black",fontSize: "10px", height: "30px", width: "fit-content"}} onClick={addFirstTime2}>현재시간삽입:처음</button>
                                    <br></br>
                                    메모 2
                                    <br></br>
                                    <button className="ms-2" style={{ backgroundColor: "white", border: "1px solid black",fontSize: "10px", height: "30px", width: "fit-content"}} onClick={addLastTime2}>현재시간삽입:마지막</button>
                                </td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"} colSpan="4">
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <textarea name="memo2" id="memo2" cols="100" rows="5" value={memo2} onChange={memo2ChangeHandler}></textarea>
                                    </div>
                                </td>
                            </tr>
                            <tr height={"110px"}>
                                <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>
                                    <button className="ms-2" style={{ backgroundColor: "white", border: "1px solid black",fontSize: "10px", height: "30px", width: "fit-content"}} onClick={addFirstTime3}>현재시간삽입:처음</button>
                                    <br></br>
                                    메모 3
                                    <br></br>
                                    <button className="ms-2" style={{ backgroundColor: "white", border: "1px solid black",fontSize: "10px", height: "30px", width: "fit-content"}} onClick={addLastTime3}>현재시간삽입:마지막</button>
                                </td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"} colSpan="4">
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <textarea name="memo3" id="memo3" cols="100" rows="5" value={memo3} onChange={memo3ChangeHandler}></textarea>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor: "lightgray"}} align={"center"} width={"15%"}>가입시점<br></br>추천인키 정보</td>
                                <td width={"1%"}></td>
                                <td width={"34%"} align={"left"} colSpan="4">
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        사용한 추천인키 / 가입 도메인 / 가입시점 추천인키 정보
                                    </div>
                                    <div className="container-fluid p-0 mx-1" style={{
                                        display: "flex"
                                    }}>
                                        <select
                                            className="form-select sm-select"
                                            defaultValue={''}
                                            style={{
                                                width: "400px"
                                            }}
                                            onChange={recommendChangeHandler} value={recommend}
                                        >
                                            {recommendList.map((item, index) => (
                                                <option key={item.recommend_key} value={item.recommend_key}>{item.recommend_key} / {item.recommend_org_admin_id} / {item.recommend_user_id}</option>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>




    )
}
export default MemberAddComponent;