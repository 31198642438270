import {combineReducers} from "redux";
import storage from "redux-persist/lib/storage"
import userInfoReducer, {login} from "./userInfo"
import {persistReducer} from "redux-persist";
import MemberReducer from "./user/MemberReducer"
import DepositListReducer from "./deposit/DepositListReducer";
import WithdrawListReducer from "./deposit/WithdrawListReducer";
import MoneyListReducer from "./deposit/MoneyListReducer";
import MoneyStatReducer from "./deposit/MoneyStatReducer";
import PointListReducer from "./deposit/PointListReducer";

import UserInfoCombineReducer from "./user/userInfoCombineReducer";
import MemberInformationSimpleModalReducer from "./member/MemberInformationSimpleModalReducer";
import OrgSelectReducer from "./partner/OrgSelectReducer";

import RecommendKeyListReducer from "./partner/RecommendKeyListReducer";
import RecommendAddReducer from "./partner/RecommendAddReducer";

import RtmSttlItmReducer from "./rtmSttl/rtmSttlItm";

import PartnerMctSttlReducer from "./deposit/PartnerMctSttlReducer";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["userInfo"]
}

const rootReducer = combineReducers({
    userInfo: userInfoReducer,
    member: MemberReducer,
    depositList: DepositListReducer,
    withdrawList: WithdrawListReducer,
    MemberInformationSimpleModalReducer:MemberInformationSimpleModalReducer,
    OrgSelectReducer : OrgSelectReducer,
    userInfoCombine: UserInfoCombineReducer,
    moneyList: MoneyListReducer,
    moneyStat: MoneyStatReducer,
    pointList: PointListReducer,
    RecommendKeyListReducer: RecommendKeyListReducer,
    RecommendAddReducer : RecommendAddReducer,
    RtmSttlItm : RtmSttlItmReducer,
    partnerMctSttl: PartnerMctSttlReducer
});

export default persistReducer(persistConfig, rootReducer);