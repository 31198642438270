import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination2";
import {getSttlAmtPayment} from "../../redux/reducers/deposit/SttlAmtPaymentReducer";
import SttlAmtPayment from "../../components/deposit/SttlAmtPayment";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {ArrowsExpand} from "react-bootstrap-icons";
import {NumericFormat} from "react-number-format";
import {insertAdminLog} from "../../lib/api";

const SttlAmtPaymentContainer = ({userId}) => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const userInfo = useSelector((state) => state.userInfo)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.sttlAmtPayment)
    const userState = useSelector((state) => state.userInfoCombine)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));


    const [userGbList, setUserGbList] = useState([]);
    const [userGbCd, setUserGbCd] = useState('')

    const [dateType, setDateType] = useState(0);

    const [maxDate, setMaxDate] = useState('#3fadf7')
    const [date365, setDate365] = useState('#3fadf7')
    const [date180, setDate180] = useState('#3fadf7')
    const [date90, setDate90] = useState('#3fadf7')
    const [date60, setDate60] = useState('#3fadf7')
    const [date30, setDate30] = useState('#3fadf7')
    const [date7, setDate7] = useState('#3fadf7')
    const [date1, setDate1] = useState('#3fadf7')
    const [date0, setDate0] = useState('#3fadf7')
    const [minDate, setMinDate] = useState('red')


    const changeFieldListHandler = (e) => {
        setUserGbCd(e.currentTarget.value);
        //setUserGbCd(e.currentTarget.value);
    }

    const getCmmnCd = () => {
        if(userGbList.length === 0){
            axios.post('/partner/admin/cmmnCdList', {
                cmmnCdId : '0020',
            }).then((res) => {
                setUserGbList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getSttlAmtPayment({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: (userId && userId !== "")? '2': initialState.searchGbCd,
            userId: userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
        setSearchQuery(userId)

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfo.id,
            logContent: "머니내역"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })

    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getSttlAmtPayment({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getSttlAmtPayment({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getSttlAmtPayment({
            page: initialState.page,
            pageSize: initialState.pageSize,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const onClickReset = (e) => {
        window.location.reload();
    }



    const userGbCdChangeHandler = (e) => {
        setUserGbCd(e.currentTarget.value);

        dispatch(getSttlAmtPayment({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: e.currentTarget.value,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }


    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
        setMaxDate('#3fadf7')
        setDate365('#3fadf7')
        setDate180('#3fadf7')
        setDate90('#3fadf7')
        setDate60('#3fadf7')
        setDate30('#3fadf7')
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
        setMinDate('#3fadf7')
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
        setMaxDate('#3fadf7')
        setDate365('#3fadf7')
        setDate180('#3fadf7')
        setDate90('#3fadf7')
        setDate60('#3fadf7')
        setDate30('#3fadf7')
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
        setMinDate('#3fadf7')
    }




    return <>
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">정산금 지급내역</font>&nbsp;&nbsp;&nbsp;
                    <select
                        className="form-select sm-select"
                        defaultValue={120}
                        style={{
                            width: "100px"
                        }}
                        value={initialState.pageSize === "999"? "모두" : initialState.pageSize}
                        onChange={(e) => {
                            dispatch(getSttlAmtPayment({
                                page: initialState.page,
                                pageSize: parseInt(e.target.value),
                                order: initialState.order,
                                query: searchQuery,
                                startDate: startDate? startDate : initialState.startDate,
                                endDate: endDate? endDate : initialState.endDate,
                                userGbCd: initialState.userGbCd,
                                searchGbCd: initialState.searchGbCd,
                                userId: userState.userId,
                                sessionId: userInfo.id,
                                sessionLevel: userInfo.level
                            }))
                        }}
                    >
                        <option value={30}>30개</option>
                        <option value={60}>60개</option>
                        <option value={120}>120개</option>
                        <option value={320}>320개</option>
                        <option value={500}>500개</option>
                        <option value={1000}>1000개</option>
                    </select>
                    <table width={"85%"}>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "80px"
                                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색
                                </button>
                                &nbsp;
                                <button style={{
                                    width: "120px", backgroundColor :"gray"
                                }} type="submit" className="btn btn-primary" onClick={onClickReset}>새로고침(자동보류)</button>
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
            <br></br>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                [현재 페이지 총합 : <font color="red">
                <NumericFormat className="ms-1" value={initialState.countList.total_amt} displayType={'text'} thousandSeparator={true}/>
            </font>]
            </div>
        </div>
        <SttlAmtPayment sttlAmtPaymentList={initialState.sttlAmtPayment} />
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default SttlAmtPaymentContainer