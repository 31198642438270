import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {setDisableYn, setMemberYnText, setMemberYnTextColor} from "../../../../redux/reducers/user/BlackMemberAddModalReducer";

const MemberInformationDetailModal = (props) => {


    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table width={"99%"}>
                    <tr>
                        <td align={"right"}>
                            <button style={{
                                backgroundColor: "#5dc434",
                                border: "none",
                                color: "white",
                                fontSize: "12px",
                                height: "20px",
                                width: "fit-content",
                                marginRight: "10px"
                            }} >회원 수정
                            </button>

                        </td>
                    </tr>
                </table>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <b>게임 종류별 개인 &nbsp;<font color={"red"}> 두가지 선택지 게임</font>&nbsp; 배당 설정</b>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table className="commonT table table-bordered table-sm text-center" style={{width: "60%"}}>
                    <thead >
                    <tr>
                        <td style={{width: "50%",backgroundColor: "lightgray"}}>종류</td>
                        <td style={{width: "50%",backgroundColor: "lightgray"}}>2선택지 개인 배당</td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >173
                            </button>
                            가상 농구

                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >36</button>
                            가상개경주
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >39</button>
                            가상경마
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >9</button>
                            가상축구
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >147</button>
                            넥스트 링
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >148</button>넥스트 바카라
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >27</button>로투스바카라1
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >29</button>로투스홀짝
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >1</button>스폐셜
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >33</button>스피드키노
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >6</button>실시간
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >0</button>일반게임
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >3</button>크로스
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >26</button>키노사다리
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >3</button>파워볼
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >-440</button>파워볼 조합
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >15</button>파워사다리
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >-443</button>파워사다리 조합
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <button style={{
                                backgroundColor: "darkgray",
                                border: "none",
                                color: "yellow",
                                fontSize: "10px",
                                height: "20px",
                                width: "30px",
                                marginRight: "10px"
                            }} >-334</button>토큰
                        </td>
                        <td style={{width: "50%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "120px"}} placeholder="-1.00" />
                        </td>
                    </tr>
                    </thead>
                </table>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <font color={"red"}> * '-1'로 설정하면, 기본 배당을 따름</font>
            </div>
        </div>



    )
}
export default MemberInformationDetailModal;