
import React from "react";

const ToknUserByGameStcsPage = () => {
    return (
        <div>
            <h7>[토큰]회원별게임통계</h7>
        </div>

    )
}
export default ToknUserByGameStcsPage;