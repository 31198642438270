import React, {useState, useEffect} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {setIsOpen,setIsOpen02,setIsOpen03,setIsOpen04,setIsOpen05,setIsOpen06,setIsOpen07,setIsOpen08,setIsOpen09,setIsOpen10, setIsUserId} from "../../../../redux/reducers/user/userInfoCombineReducer";

import Combine01Modal from "../combine/Combine01Modal";
import Combine02Modal from "../combine/Combine02Modal";
import Combine03Modal from "../combine/Combine03Modal";
import Combine04Modal from "../combine/Combine04Modal";
import Combine05Modal from "../combine/Combine05Modal";
import Combine06Modal from "../combine/Combine06Modal";
import Combine07Modal from "../combine/Combine07Modal";
import Combine08Modal from "../combine/Combine08Modal";
import Combine09Modal from "../combine/Combine09Modal";
import Combine10Modal from "../combine/Combine10Modal";


const MemberInformationDetailModal = (props) => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo)
    const initialState = useSelector((state) => state.userInfoCombine)
    const {open, onClose, userId, refresh} = props
    const modal01Open = () => {
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(true))
    }
    const modal02Open = () => {
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(true))
    }
    const modal03Open = () => {
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(true))
    }
    const modal04Open = () => {
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(true))
    }
    const modal05Open = () => {
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(true))
    }
    const modal06Open = () => {
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(true))
    }
    const modal07Open = () => {
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(true))
    }
    const modal08Open = () => {
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(true))
    }
    const modal09Open = () => {
        dispatch(setIsOpen10(false))
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(true))
    }
    const modal10Open = () => {
        dispatch(setIsOpen(false))
        dispatch(setIsOpen02(false))
        dispatch(setIsOpen03(false))
        dispatch(setIsOpen04(false))
        dispatch(setIsOpen05(false))
        dispatch(setIsOpen06(false))
        dispatch(setIsOpen07(false))
        dispatch(setIsOpen08(false))
        dispatch(setIsOpen09(false))
        dispatch(setIsOpen10(true))
    }
    const handleCancel = () => {

        // refresh()
        // modal01Open()

        //dispatch(setIsUserId(""))
        onClose()
    }



    return (
        <div className="user_combine_modal_background">
            <div className="user_combine_modal_box" >
                <div className="user_combine_modal_body">

                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" >
                            <tr>
                                <td style={{backgroundColor:"blue"}} align={"left"} height={"30px"}>
                                    <b><font color={"white"} size={"3"}> [{initialState.userNo}] {initialState.nickName} ({initialState.userId}) 님의 관련 정보 </font></b>
                                </td>
                                <td style={{backgroundColor:"blue"}} align={"right"}>
                                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={handleCancel}>X</button>
                                    &nbsp;&nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"white"}} align={"left"} >
                                    <button style={{
                                        width: "120px", height:"30px", backgroundColor:  (initialState.isOpen ? "red"  : "lightgray")
                                    }} type="submit" onClick={() => {modal01Open()}}><b><font color={ (initialState.isOpen ? "white" : "black")  }>베팅내역</font></b>
                                    </button>
                                    &nbsp;
                                    <button style={{
                                        width: "120px", height:"30px", backgroundColor:  (initialState.isOpen02 ? "red"  : "lightgray")
                                    }} type="submit" onClick={() => {modal02Open()}}><b><font color={ (initialState.isOpen02 ? "white" : "black")  }>충/환전 로그</font></b>
                                    </button>
                                    &nbsp;
                                    <button style={{
                                        width: "120px", height:"30px", backgroundColor:  (initialState.isOpen03 ? "red"  : "lightgray")
                                    }} type="submit" onClick={() => {modal03Open()}}><b><font color={ (initialState.isOpen03 ? "white" : "black")  }>마진로그</font></b>
                                    </button>
                                    &nbsp;
                                    <button style={{
                                        width: "120px", height:"30px", backgroundColor:  (initialState.isOpen04 ? "red"  : "lightgray")
                                    }} type="submit" onClick={() => {modal04Open()}}><b><font color={ (initialState.isOpen04 ? "white" : "black")  }>충전내역</font></b>
                                    </button>
                                    &nbsp;
                                    <button style={{
                                        width: "120px", height:"30px", backgroundColor:  (initialState.isOpen05 ? "red"  : "lightgray")
                                    }} type="submit" onClick={() => {modal05Open()}}><b><font color={ (initialState.isOpen05 ? "white" : "black")  }>환전내역</font></b>
                                    </button>
                                    &nbsp;
                                    <button style={{
                                        width: "120px", height:"30px", backgroundColor:  (initialState.isOpen06 ? "red"  : "lightgray")
                                    }} type="submit" onClick={() => {modal06Open()}}><b><font color={ (initialState.isOpen06 ? "white" : "black")  }>머니거래내역</font></b>
                                    </button>
                                    &nbsp;
                                    <button style={{
                                        width: "120px", height:"30px", backgroundColor:  (initialState.isOpen07 ? "red"  : "lightgray")
                                    }} type="submit" onClick={() => {modal07Open()}}><b><font color={ (initialState.isOpen07 ? "white" : "black")  }>포인트내역</font></b>
                                    </button>
                                    &nbsp;
                                    <button style={{
                                        width: "120px", height:"30px", backgroundColor:  (initialState.isOpen08 ? "red"  : "lightgray")
                                    }} type="submit" onClick={() => {modal08Open()}}><b><font color={ (initialState.isOpen08 ? "white" : "black")  }>회원정보</font></b>
                                    </button>
                                    &nbsp;
                                    <button style={{
                                        width: "120px", height:"30px", backgroundColor:  (initialState.isOpen09 ? "red"  : "lightgray")
                                    }} type="submit" onClick={() => {modal09Open()}}><b><font color={ (initialState.isOpen09 ? "white" : "black")  }>고객센터 글 보기</font></b>
                                    </button>
                                    &nbsp;
                                    <button style={{
                                        width: "120px", height:"30px", backgroundColor:  (initialState.isOpen10 ? "red"  : "lightgray")
                                    }} type="submit" onClick={() => {modal10Open()}}><b><font color={ (initialState.isOpen10 ? "white" : "black")  }>자유게시판 글 보기</font></b>
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex", height:"670px", overflowY:"scroll", alignItems:'flex-start'
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" >
                            <tr>
                                <td>
                                    {initialState.isOpen && (<Combine01Modal /> )}
                                    {initialState.isOpen02 && (<Combine02Modal /> )}
                                    {initialState.isOpen03 && (<Combine03Modal /> )}
                                    {initialState.isOpen04 && (<Combine04Modal /> )}
                                    {initialState.isOpen05 && (<Combine05Modal /> )}
                                    {initialState.isOpen06 && (<Combine06Modal /> )}
                                    {initialState.isOpen07 && (<Combine07Modal /> )}
                                    {initialState.isOpen08 && (<Combine08Modal /> )}
                                    {initialState.isOpen09 && (<Combine09Modal /> )}
                                    {initialState.isOpen10 && (<Combine10Modal /> )}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>



    )
}
export default MemberInformationDetailModal;