import React from 'react';
import DepositListContainer from "../../containers/deposit/DepositListContainer";
import {useParams} from "react-router-dom";


const DepositList = () => {
    const param = useParams()
    return (
        <div>
            <DepositListContainer userId={param.userId}/>
        </div>
    );
};

export default DepositList;