
import React from "react";

const KgonBettingItmPage = () => {
    return (
        <div>
            <h7>[Kgon]베팅내역</h7>
        </div>

    )
}
export default KgonBettingItmPage;