import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const withdrawListSlice = createSlice({
    name: 'withdrawList',
    initialState: {
        isLoading: false,
        error: null,
        withdrawList: [],
        countList: {},
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "",
        userGbCd: "",
        searchGbCd: "",
        searchCd01: 0,
        searchCd02: "",
        searchCd03: "",
        searchCd04: "",
        searchCd05: "",
        searchCd06: "",
        searchCd07: "",
        searchCd08: "",
        searchCd09: "",
        searchCd10: "",
        searchCd11: "",
        searchCd12: "",
        searchCd13: "",
        searchCd14: "",
        searchCd15: "",
        searchCd16: "",
        selectedNos: "",
        selectedNos3: [],
        selectedNos4: [],
        keyCd :"",
        valueCd: "",
        userId: ""
    },
    reducers: {
        getWithdrawList(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.userGbCd = action.payload.userGbCd;
            state.searchGbCd = action.payload.searchGbCd;
            state.searchCd01 = action.payload.searchCd01;
            state.searchCd02 = action.payload.searchCd02;
            state.searchCd03 = action.payload.searchCd03;
            state.searchCd04 = action.payload.searchCd04;
            state.searchCd05 = action.payload.searchCd05;
            state.searchCd06 = action.payload.searchCd06;
            state.searchCd07 = action.payload.searchCd07;
            state.searchCd08 = action.payload.searchCd08;
            state.searchCd09 = action.payload.searchCd09;
            state.searchCd10 = action.payload.searchCd10;
            state.searchCd11 = action.payload.searchCd11;
            state.searchCd12 = action.payload.searchCd12;
            state.searchCd13 = action.payload.searchCd13;
            state.searchCd14 = action.payload.searchCd14;
            state.searchCd15 = action.payload.searchCd15;
            state.searchCd16 = action.payload.searchCd16;
            state.keyCd = action.payload.keyCd;
            state.valueCd = action.payload.valueCd;
            state.userId = action.payload.userId;
            state.selectedNos = action.payload.selectedNos;
            state.selectedNos3 = action.payload.selectedNos3;
            state.selectedNos4 = action.payload.selectedNos4;
            state.sessionId = action.payload.sessionId;
            state.sessionLevel = action.payload.sessionLevel;
        },
        setWithdrawList(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.withdrawList = action.payload.result;
            state.totalList = action.payload.totalList;
            state.countList = action.payload.countList;
        },
        failedGetWithdrawList(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setSelectedNos(state,action){
            state.selectedNos = action.payload;
        },
        setSelectedNos3(state,action){
            state.selectedNos3 = action.payload;
        },
        setSelectedNos4(state,action){
            state.selectedNos4 = action.payload;
        },
    }
});

export const { getWithdrawList, setWithdrawList, failedGetWithdrawList, setSelectedNos, setSelectedNos3, setSelectedNos4 } = withdrawListSlice.actions;

export default withdrawListSlice.reducer;