import React from 'react';
import MoneyListContainer from "../../containers/deposit/MoneyListContainer";
import {useParams} from "react-router-dom";


const MoneyList = () => {
    const param = useParams()
    return (
        <div>
            <MoneyListContainer userId={param.userId}/>
        </div>
    );
};

export default MoneyList;