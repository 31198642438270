import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {NumericFormat} from "react-number-format";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";
const PartnerMctSttlList = ({partnerMctSttl, onRefresh}) => {
    const dispatch = useDispatch()


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>


                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead className="table-dark">
                        <tr>
                            <td align={"center"} style={{width: "3%"}} rowSpan="2">번호</td>
                            <td align={"center"} style={{width: "3%"}} rowSpan="2">총판</td>
                            <td align={"center"} style={{width: "3%"}} rowSpan="2">가맹점</td>
                            <td align={"center"} style={{width: "3%"}} rowSpan="2">배당일자</td>
                            <td align={"center"} style={{width: "24%"}} colSpan="8">유저 통계</td>
                            <td align={"center"} style={{width: "3%"}} rowSpan="2">입금액</td>
                            <td align={"center"} style={{width: "3%"}} rowSpan="2">입금건수</td>
                            <td align={"center"} style={{width: "3%"}} rowSpan="2">출금액</td>
                            <td align={"center"} style={{width: "3%"}} rowSpan="2">출금건수</td>
                            <td align={"center"} style={{width: "3%"}} rowSpan="2">수익</td>
                            <td align={"center"} style={{width: "6%"}} colSpan="2">전체 베팅</td>
                            <td align={"center"} style={{width: "6%"}} colSpan="2">일반 베팅</td>
                            <td align={"center"} style={{width: "6%"}} colSpan="2">인플레이 베팅</td>
                            <td align={"center"} style={{width: "6%"}} colSpan="2">자동게임 베팅</td>
                            <td align={"center"} style={{width: "6%"}} colSpan="2">kgon카지노 베팅</td>
                            <td align={"center"} style={{width: "6%"}} colSpan="2">토큰 베팅</td>
                            <td align={"center"} style={{width: "6%"}} colSpan="2">전체</td>
                        </tr>
                        <tr>
                            <td align={"center"}>가입유저</td>
                            <td align={"center"}>삭제유저</td>
                            <td align={"center"}>가입베팅유저</td>
                            <td align={"center"}>가입충전유저</td>
                            <td align={"center"}>가입환전유저</td>
                            <td align={"center"}>소속베팅유저</td>
                            <td align={"center"}>소속충전유저</td>
                            <td align={"center"}>소속환전유저</td>
                            <td align={"center"}>총베팅액</td>
                            <td align={"center"}>마진</td>
                            <td align={"center"}>총베팅액</td>
                            <td align={"center"}>마진</td>
                            <td align={"center"}>총베팅액</td>
                            <td align={"center"}>마진</td>
                            <td align={"center"}>총베팅액</td>
                            <td align={"center"}>마진</td>
                            <td align={"center"}>총베팅액</td>
                            <td align={"center"}>마진</td>
                            <td align={"center"}>총베팅액</td>
                            <td align={"center"}>마진</td>
                            <td align={"center"}>진행중베팅액</td>
                            <td align={"center"}>유저보유액</td>
                        </tr>
                        </thead>
                        <tbody>

                        </tbody>
                    </table>
                </div>

            </div>
        </div>

    )
}

export default PartnerMctSttlList;