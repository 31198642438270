import React from 'react';
import {NumericFormat} from "react-number-format";

const PlusMinusText = ({ number}) => {
    //플러스면 파란색, 마이너스면 빨간색
    let spanStyle = {
        color: "blue"
    }
    if(number < 0) {
        spanStyle = {
            color: "red"
        }
    }

    return <span style={spanStyle}><NumericFormat value={number} displayType={'text'} thousandSeparator={true} /></span>
};

export default PlusMinusText;