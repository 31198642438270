
import React from "react";

const AutoGameStcsPage = () => {
    return (
        <div>
            <h7>자동게임통계</h7>
        </div>

    )
}
export default AutoGameStcsPage;