import React from 'react';
import RtmSttlItmpageContainer from "../../containers/rtmSttl/RtmSttlItmpageContainer";
import {useParams} from "react-router-dom";


const RtmSttlItmPage = () => {
    const param = useParams()
    return (
        <div>
            <RtmSttlItmpageContainer userId={param.userId}/>
        </div>
    );
};

export default RtmSttlItmPage;