import React, {useState, useEffect} from "react";
import MoneyListContainer from "../../../../containers/deposit/MoneyListContainer";

const MemberInformationDetailModal = (props) => {


    return (
        <div>
            <MoneyListContainer />
        </div>
    )
}
export default MemberInformationDetailModal;