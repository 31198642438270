import React, {useEffect, useState} from "react";
import axios from "axios";
import PlusMinusText from "../../components/common/PlusMinusText";
import {NumericFormat} from "react-number-format";
import {useSelector} from "react-redux";
const BettingContainer = ({userId}) => {

    const [list, setList] = useState([])
    const [state, setState] = useState(new Map())
    const [stat, setStat] = useState(null)
    const userInfoLvl = useSelector((state) => state.userInfo.level)
    const userInfoId = useSelector((state) => state.userInfo.id)


    useEffect(() => {
        getBettingList()
    }, [])

    const getBettingList = () => {
        axios.post('/partner/betting/getBettingList', {
            userId: userId,
            userInfoId:userInfoId,
            userInfoLvl: userInfoLvl
        }).then((res) => {
            setList(res.data.list)
            setStat(res.data.stat)
        })
    }

    const getBettingDetails = (betNo) => {
        axios.post('/partner/betting/getBettingDetails', {
            betNo: betNo
        }).then((res) => {
            const details = res.data.result
            addItem(details[0].user_bet_no, details)
        })
    }

    const addItem = (key, value) => {
        setState((prev) => new Map([...prev, [key, value]]));
    };


    const upsertItem = (key, value) => {
        setState((prev) => new Map(prev).set(key, value));
    }

    const deleteItem = (key) => {
        setState((prev) => {
            const newState = new Map(prev);
            newState.delete(key);
            return newState;
        });
    }

    const clearAll = () => {
        setState((new Map()))
    }

    return (
        <div className="sm-font mx-3" style={{marginTop: "30px", height: "fit-content"}}>
            <div style={{ backgroundColor: "white"}}>
                <div style={{display: "flex"}}>
                    <div style={{flexGrow: "1"}}>
                        <div className="align-items-center" style={{display: "flex"}}>
                            <span style={{color: "#1F68EE", fontWeight: "bold"}}>배팅내역</span>
                            <select className="form-select mx-1" aria-label="Default select example" style={{width: "80px"}}>
                                <option selected>30개</option>
                            </select>
                            <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                            <label className="form-check-label sm-label" htmlFor="flexRadioDefault11" style={{color: "#1F68EE", fontWeight: "bold"}}>
                                데이터 자동 갱신(15초 단위)
                            </label>
                        </div>

                        <div className="align-items-center" style={{display: "flex"}}>
                            <button style={{ marginRight: "10px", backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content"}}>엑셀 다운로드</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}}>365일</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}}>180일</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}}>90일</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}}>60일</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}}>30일</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}}>7일</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}}>어제</button>
                            <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}}>오늘</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}}>지난 24시간</button>
                            <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}}>베팅내역 전체 펼치기</button>
                            <div style={{display: "flex", paddingLeft: "5px", paddingRight: "5px", alignItems: "center"}}>
                                <span className="mx-1">◀</span>
                                <input className="form-control sm-date" type="date" style={{width: "120px"}}/>
                                <span className="mx-1">~</span>
                                <input className="form-control sm-date" type="date" style={{width: "120px"}}/>
                                <span className="mx-1">▶</span>
                            </div>
                            <select className="form-select mx-1" aria-label="Default select example" style={{width: "150px"}}>
                                <option selected>전체 사이트 코드</option>
                            </select>
                        </div>

                        <div className="align-items-center" style={{display: "flex"}}>
                            <select className="form-select mx-1" aria-label="Default select example" style={{width: "150px"}}>
                                <option selected>::총판/가맹점::</option>
                            </select>
                            <select className="form-select mx-1" aria-label="Default select example" style={{width: "150px"}}>
                                <option selected>전체 P등급</option>
                            </select>
                            <div className="sm-center-div" style={{height: "30px"}}>
                                <label className="sm-label">
                                    베팅머니 :
                                </label>
                                <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault1">
                                    전체
                                </label>
                                <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault2">
                                    게임머니
                                </label>
                                <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault3">
                                    포인트
                                </label>
                            </div>
                            <div className="sm-center-div" style={{height: "30px"}}>
                                <label className="sm-label">
                                    게임결과 :
                                </label>
                                <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault1">
                                    전체
                                </label>
                                <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault2">
                                    베팅
                                </label>
                                <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault3">
                                    적중+적특
                                </label>
                                <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault3">
                                    적중
                                </label>
                                <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault3">
                                    적특
                                </label>
                                <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault3">
                                    실패
                                </label>
                                <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault3">
                                    취소
                                </label>
                            </div>
                        </div>


                        <div className="align-items-center" style={{display: "flex"}}>
                            <div className="sm-center-div" style={{height: "30px"}}>
                                <label>
                                    폴더갯수 :
                                </label>

                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    전체
                                </label>
                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    1폴
                                </label>
                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    2폴
                                </label>
                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    3폴
                                </label>
                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    4폴
                                </label>
                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    5폴
                                </label>
                                <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                                <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                                    6폴 이상
                                </label>
                            </div>
                            <select className="form-select mx-1" aria-label="Default select example" style={{width: "150px"}}>
                                <option selected>[전체 종목]</option>
                            </select>
                            <select className="form-select mx-1" aria-label="Default select example" style={{width: "150px"}}>
                                <option selected>아이디</option>
                            </select>
                            <input className="form-check-input sm-input me-1" type="text" defaultValue={userId? userId : ""} style={{height: "18px"}} />

                            <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px", fontWeight: "bold"}}>:: 적중 위험 베팅 확인 ::</button>
                        </div>
                    </div>
                    <button style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "12px", height: "50px", width: "fit-content"}}>현재 페이지<br/>새로고침</button>
                </div>
                <div style={{display: "flex"}}>
                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="me-1">
                            리그 :
                        </label>
                        <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "12px", fontWeight: "bold", width: "fit-content"}}>KBO</button>
                        <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "12px", fontWeight: "bold", width: "fit-content"}}>MLB</button>
                        <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "12px", fontWeight: "bold", width: "fit-content"}}>NPB</button>
                        <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "12px", fontWeight: "bold", width: "fit-content"}}>KBL</button>
                        <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "12px", fontWeight: "bold", width: "fit-content"}}>NBA</button>
                        <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "12px", fontWeight: "bold", width: "fit-content"}}>WKBL</button>
                    </div>

                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="sm-label">
                            베팅선택 :
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault1">
                            전체
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault2">
                            홈
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3">
                            무
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3">
                            원정
                        </label>
                    </div>
                </div>
                <div style={{display: "flex"}}>
                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="sm-label">
                            정렬 :
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault1">
                            베팅 최근 우선
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault2">
                            베팅 과거 우선
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3">
                            결과처리 최근 우선
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3" style={{
                            color: "red"
                        }}>
                            배당률 큰 순
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3" style={{
                            color: "red"
                        }}>
                            당첨금 큰 순
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3" style={{
                            color: "red"
                        }}>
                            베팅금 큰 순
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3" style={{
                            color: "red",
                            backgroundColor: "yellow"
                        }}>
                            닉네임 순
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault3" style={{
                            color: "red",
                            backgroundColor: "yellow"
                        }}>
                            닉네임 역순
                        </label>
                    </div>

                </div>
                <div style={{display: "flex"}}>
                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="sm-label">
                            게임구분 :
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            크로스
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            크로스2
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            일반게임
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            핸디캡
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            스페셜
                        </label>
                    </div>
                </div>

                <div style={{display: "flex"}}>
                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="sm-label">
                            알림베팅 :
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault1">
                            전체
                        </label>
                        <input className="form-check-input sm-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault2">
                            알림 베팅만
                        </label>
                    </div>

                </div>

                <div className="sm-center-div" style={{height: "30px"}}>
                    <label className="me-1">
                        게임구분 :
                    </label>
                    <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "12px", fontWeight: "bold", width: "fit-content"}}>전체선택</button>
                    <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "12px", fontWeight: "bold", width: "fit-content"}}>전체해제</button>
                    <button style={{ backgroundColor: "lightblue", border: "1px solid black", fontSize: "12px", fontWeight: "bold", width: "fit-content"}}>일반게임</button>
                    <button style={{ backgroundColor: "lightsalmon", border: "1px solid black", fontSize: "12px", fontWeight: "bold", width: "fit-content"}}>자동게임</button>
                    <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "12px", fontWeight: "bold", width: "fit-content"}}>크로스</button>
                    <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "12px", fontWeight: "bold", width: "fit-content"}}>크로스2</button>
                </div>

                <div style={{display: "flex"}}>
                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="sm-label">
                            게임종류 :
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            승무패
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            핸디캡
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            오버언더
                        </label>
                    </div>
                    <div className="sm-center-div" style={{height: "30px"}}>
                        <label className="sm-label">
                            회원구분 :
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            대기회원
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            무료회원
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            유료회원
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            VIP회원
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            가라회원
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            관리자
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                            최고관리자
                        </label>
                        <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                        <label className="form-check-label sm-label" htmlFor="flexRadioDefault11" style={{
                            backgroundColor: "yellow",
                            color: "red"
                        }}>
                            관심회원
                        </label>
                    </div>
                </div>

                <div className="sm-center-div" style={{height: "30px"}}>
                    <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                    <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                        조작된 베팅만 보기
                    </label>
                    <input className="form-check-input sm-radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault11" />
                    <label className="form-check-label sm-label" htmlFor="flexRadioDefault11">
                        중복 베팅만 보기
                    </label>
                </div>
                <div>
                    <table className="table table-bordered table-sm text-center align-middle" >
                        <tr>
                            <td colSpan="1" style={{backgroundColor:"lightgray"}}>현재 진행중인<br></br>실회원 베팅</td>
                            <td colSpan="1" style={{backgroundColor:"white"}}>
                                <font color="blue"><b><PlusMinusText number={stat?.progressTotalMoney}/></b> / <PlusMinusText number={stat?.progressTotalCount} /></font>
                            </td>
                            <td colSpan="1" style={{backgroundColor:"lightgray"}}>현재 진행중인<br></br>실회원 예상당첨금</td>
                            <td style={{backgroundColor:"white"}}>
                                <font color="blue"><b><PlusMinusText number={stat?.progressTotalWinMoney} /></b></font>
                            </td>
                            <td style={{backgroundColor:"lightgray"}}>조회 조건 내<br/>현재 진행중 베팅금</td>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.progressMoney} />
                            </td>
                            <td style={{backgroundColor:"lightgray"}}></td>
                            <td style={{backgroundColor:"white"}}>
                                <font color="blue">-</font>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1" rowSpan="2" style={{backgroundColor:"lightgray"}}>총 건수 / 취소 제외 건수<br/>배팅 아이디수</td>
                            <td colSpan="1" style={{backgroundColor:"white"}}>
                                <span><PlusMinusText number={stat?.resultTotalCount} /> / <PlusMinusText number={stat?.resultExceptCancel} /></span>
                            </td>
                            <td colSpan="1" rowSpan="2" style={{backgroundColor:"lightgray"}}>배팅금(취소제외)<br/>평균 베팅금</td>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.resultMoneyExceptCancel} />
                            </td>
                            <td rowSpan="2" style={{backgroundColor:"lightgray"}}>당첨금<br/>평균 당첨금</td>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.resultWinMoney} />
                            </td>
                            <td rowSpan="2" style={{backgroundColor:"lightgray"}}>남은 예상당첨금</td>
                            <td rowSpan="2" style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.preWinMoney} />
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan="1" style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.resultIdCount} />
                            </td>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.avgMoney} />
                            </td>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.avgWinMoney} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="1" rowSpan="2" style={{backgroundColor:"lightgray"}}>단순마진</td>
                            <td colSpan="1" rowSpan="2" style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.simpleMargin} />
                            </td>
                            <td colSpan="1" rowSpan="2" style={{backgroundColor:"lightgray"}}>실제마진(단순 마진- 지급<br/>포인트</td>
                            <td rowSpan="2" style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.realMargin} />
                            </td>
                            <td rowSpan="2" style={{backgroundColor:"lightgray"}}>낙첨 지급 포인트<br/>추천인 지급 포인트</td>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={stat?.totalPoint} />
                            </td>
                            <td rowSpan="2" style={{backgroundColor:"lightgray"}}>당첨 수 /실패 수<br/>베팅금 대비 당첨금 비율</td>
                            <td rowSpan="1" style={{backgroundColor:"white"}}>
                                <span color="red">
                                    <NumericFormat value={stat?.resultWin} displayType="text" thousandSeparator={true} />
                                    {" / "}
                                    <NumericFormat value={stat?.resultLose} displayType="text" thousandSeparator={true} />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"white"}}>
                                <PlusMinusText number={0} />
                            </td>
                            <td style={{backgroundColor:"white"}}>
                                <font color="blue"><PlusMinusText number={Math.floor(stat?.winMoneyRate * 100)} />%</font>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="sm-center-div-no-border" style={{height: "30px"}}>
                    <button className="me-1" style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "12px", width: "fit-content"}}>유저 베팅내역 전체 삭제(유저에게서만 삭제됨)</button>
                    <button style={{ backgroundColor: "#CECECE", border: "1px solid black", fontSize: "12px", width: "fit-content"}}>유저 베팅내역 전체 복원(유저에게서 복원됨)</button>
                </div>

                <div className="sm-font">
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead>
                        <tr>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>번호</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>사이트코드</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>소속</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>가맹</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이디</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>닉네임</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>아이피</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>회원분류</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>게임구분</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>상세내역</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>User&Game</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>User&All</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>IP&Game</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>IP&All</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}} colSpan="2">적중/조합</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>베팅일자</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>베팅액</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>배당률</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>당첨금</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>예상당첨금</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>배팅상태</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>조작여부</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>사용자삭제여부</td>
                            <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>처리</td>
                        </tr>
                        </thead>
                        <tbody>
                        {list.map((item, index) => (
                            <>
                                <tr>
                                    <td>{item.no}</td>
                                    <td>{item.site_cd}</td>
                                    <td>{item.store_cd}</td>
                                    <td>{item.psnl_cd}</td>
                                    <td style={{backgroundColor: "gray"}}><span style={{color: "yellow"}}>{item.id}</span></td>
                                    <td style={{backgroundColor: "gray"}}><span style={{color: "yellow"}}>{item.nickname}</span></td>
                                    <td>{item.bet_ip}</td>
                                    <td><span style={{color: "green"}}>{item.user_state_cd}</span></td>
                                    <td>{item.type}</td>
                                    <td><button className="btn btn-small btn-success" onClick={(e) => {
                                        getBettingDetails(item.no)
                                    }}>상세정보</button></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{item.result}</td>
                                    <td>{item.folder_count}</td>
                                    <td>{item.createdAt}</td>
                                    <td><PlusMinusText number={item.money} /></td>
                                    <td>{item.total_ratio.toFixed(2)}</td>
                                    <td><PlusMinusText number={item.result_money} /></td>
                                    <td><PlusMinusText number={item.win_money} /></td>
                                    <td>{item.result}</td>
                                    <td>-</td>
                                    <td>{item.is_deleted}</td>
                                    <td><button className="btn btn-small btn-danger">취소</button></td>
                                </tr>
                                {state.get(item.no) && (
                                    <tr>
                                        <td colSpan="25">
                                            <table className="table table-bordered table-sm text-center align-middle">
                                                <thead>
                                                <tr>
                                                    <td>게임번호</td>
                                                    <td>경기</td>
                                                    <td>경기일자</td>
                                                    <td>게임종류</td>
                                                    <td>종목</td>
                                                    <td>리그</td>
                                                    <td>홈 vs 원정</td>
                                                    <td>User&Game</td>
                                                    <td>User&All</td>
                                                    <td>IP&Game</td>
                                                    <td>IP&All</td>
                                                    <td>승</td>
                                                    <td>무</td>
                                                    <td>패</td>
                                                    <td>스코어</td>
                                                    <td>결과</td>
                                                    <td>버전</td>
                                                    <td>수정일자</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {state.get(item.no).map((detail, dIndex) => (
                                                    <tr>
                                                        <td>{detail.no}</td>
                                                        <td>{}</td>
                                                        <td>{detail.start_date}</td>
                                                        <td>{detail.type}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{`${detail.home} vs ${detail.away}`}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td style={{
                                                            backgroundColor: detail.bet_no === 1? "green" : "white"
                                                        }}>{detail.bet1.toFixed(2)}</td>
                                                        <td>-</td>
                                                        <td style={{
                                                            backgroundColor: detail.bet_no === 3? "green" : "white"
                                                        }}>{detail.bet3.toFixed(2)}</td>
                                                        <td>{`${detail.score_home} : ${detail.score_away}`}</td>
                                                        <td>{detail.result}</td>
                                                        <td>-</td>
                                                        <td>{detail.updatedAt}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                )}

                            </>

                        ))}

                        </tbody>
                    </table>
                </div>

            </div>
        </div>

    )
}
export default BettingContainer;