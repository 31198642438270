import React, {useState} from 'react';
import {Outlet, useNavigate} from "react-router-dom";

const RtmSttlIndex = () => {

    const navigate = useNavigate();
    const [activeNav, setActiveNav] = useState();
    const ontoggle = (index, address) => {
        setActiveNav(index)
        navigate(address)
    }
    const menuArr = [
        {name:"실시간정산내역", address:"/RtmSttl/RtmSttlItmPage"},
    ]
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="collapse navbar-collapse" style={{
                    marginLeft: "290px",
                    height: "10px"
                }}>
                    <ul className="navbar-nav text-center">
                        {menuArr.map((menu,index) => (
                            <li key={index} className="mx-2" onClick={() => ontoggle(index, menu.address)}>
                                <div className={activeNav === index ? "nav-item active" : "nav-item"} style={{cursor: "pointer", fontSize: "12px"}}>
                                    {menu.name}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

            </nav>
            <div style={{
                paddingLeft: "10px"
            }}>
                <Outlet />
            </div>

        </div>


    )
}

export default RtmSttlIndex;