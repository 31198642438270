import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const memberSlice = createSlice({
    name: 'member',
    initialState: {
        isLoading: false,
        error: null,
        member: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().add(-1, "month").format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "",
        userGbCd: "",
        sortColumn: "",
        sortValue: 0,
        isOpen : false,
        isOpen2 : false,
        isOpen3 : false,
        isOpen4 : false,
        isOpen5 : false,
        isOpen6 : false,    //아이디다수입력
        isOpen7 : false,    //닉네임다수입력
        isOpen8 : false,    //레벨설정
        isOpen9 : false,    //소속설정
        searchGb: 0,
        userId: "",
        searchCd01: "",
        searchCd02: "",
        searchCd03: "",
        searchCd04: "",
        searchCd05: "",
        searchCd06: "",
        searchCd07: "",
        searchCd08: "",
        searchCd09: "",
        searchCd10: "",
        selectedNos: ['01','02','03','04'],
        selectedNos2: [],
        selectedNos3: [],
        selectedNos4: [],
        selectedNos5: [],
        selectedNos6: [],
        selectedNos7: [],
        selectedNos8: [],
        selectedNos9: [],
        searchIdArr: [],
        searchNicknameArr: [],
        searchIdSort: 0,
        searchNicknameSort: 0,
        isEditing00 : false,    //부가정보/태그표시
    },
    reducers: {
        getMemberUser(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.userGbCd = action.payload.userGbCd;
            state.sortColumn = action.payload.sortColumn;
            state.sortValue = action.payload.sortValue;
            state.searchGb = action.payload.searchGb;
            state.searchCd01 = action.payload.searchCd01;
            state.searchCd02 = action.payload.searchCd02;
            state.searchCd03 = action.payload.searchCd03;
            state.searchCd04 = action.payload.searchCd04;
            state.searchCd05 = action.payload.searchCd05;
            state.searchCd06 = action.payload.searchCd06;
            state.searchCd07 = action.payload.searchCd07;
            state.searchCd08 = action.payload.searchCd08;
            state.searchCd09 = action.payload.searchCd09;
            state.searchCd10 = action.payload.searchCd10;
            state.sessionId = action.payload.sessionId;
            state.sessionLevel = action.payload.sessionLevel;
        },
        setMember(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.member = action.payload.result;
            state.totalList = action.payload.totalList;
        },
        failedGetMember(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setIsOpen(state,action){
            state.isOpen = action.payload;
        },
        setIsOpen2(state,action){
            state.isOpen2 = action.payload;
        },
        setIsOpen3(state,action){
            state.isOpen3 = action.payload;
        },
        setIsOpen4(state,action){
            state.isOpen4 = action.payload;
        },
        setIsOpen5(state,action){
            state.isOpen5 = action.payload;
        },
        setIsOpen6(state,action){
            state.isOpen6 = action.payload;
        },
        setIsOpen7(state,action){
            state.isOpen7 = action.payload;
        },
        setIsOpen8(state,action){
            state.isOpen8 = action.payload;
        },
        setIsOpen9(state,action){
            state.isOpen9 = action.payload;
        },
        setUserId(state,action){
            state.userId = action.payload;
        },
        setSelectedNos(state,action){
            state.selectedNos = action.payload;
        },
        setSelectedNos2(state,action){
            state.selectedNos2 = action.payload;
        },
        setSelectedNos3(state,action){
            state.selectedNos3 = action.payload;
        },
        setSelectedNos4(state,action){
            state.selectedNos4 = action.payload;
        },
        setSelectedNos5(state,action){
            state.selectedNos5 = action.payload;
        },
        setSelectedNos6(state,action){
            state.selectedNos6 = action.payload;
        },
        setSelectedNos7(state,action){
            state.selectedNos7 = action.payload;
        },
        setSelectedNos8(state,action){
            state.selectedNos8 = action.payload;
        },
        setSelectedNos9(state,action){
            state.selectedNos9 = action.payload;
        },
        setSearchIdArr(state,action){
            state.searchIdArr = action.payload;
        },
        setSearchNicknameArr(state,action){
            state.searchNicknameArr = action.payload;
        },
        setSearchIdSort(state,action){
            state.searchIdSort = action.payload;
        },
        setSearchNicknameSort(state,action){
            state.searchNicknameSort = action.payload;
        },
        setIsEditing00(state,action){
            state.isEditing00 = action.payload;
        },
    }
});

export const { getMemberUser, setMember, failedGetMember, setIsOpen, setIsOpen2, setIsOpen3, setIsOpen4, setIsOpen5, setIsOpen6, setIsOpen7, setIsOpen8, setIsOpen9, setUserId, setSelectedNos,
    setSelectedNos2,setSelectedNos3,setSelectedNos4,setSelectedNos5,setSelectedNos6,setSelectedNos7,setSelectedNos8,setSelectedNos9,setSearchIdArr,setSearchNicknameArr,
    setSearchIdSort,setSearchNicknameSort, setIsEditing00
} = memberSlice.actions;

export default memberSlice.reducer;