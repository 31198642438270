
import React from "react";

const UserByGameStcsPage = () => {
    return (
        <div>
            <h7>회원별게임통계</h7>
        </div>

    )
}
export default UserByGameStcsPage;