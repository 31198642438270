import React from 'react';
import PartnerMctSttlContainer from "../../containers/deposit/PartnerMctSttlContainer";
import {useParams} from "react-router-dom";


const PartnerMctSttlPage = () => {
    const param = useParams()
    return (
        <div>
            <PartnerMctSttlContainer userId={param.userId}/>
        </div>
    );
};

export default PartnerMctSttlPage;