import React, {useState, useEffect} from "react";
import PointListContainer from "../../../../containers/deposit/PointListContainer";

const MemberInformationDetailModal = (props) => {


    return (
        <div>
            <PointListContainer />
        </div>
    )
}
export default MemberInformationDetailModal;