import React, {useEffect, useState} from "react";
import axios from "axios";


const InterestUser = ({onCheckedColor}) => {

    const [userColorList, setUserColorList] = useState([])
    const [selectedNos, setSelectedNos] = useState([])

    useEffect(() => {
        getUserColorList()
    }, [])

    const getUserColorList = () => {
        axios.post('/partner/admin/userColorList', {

        }).then((res) => {
            setUserColorList(res.data.userColorList)
        })
    }

    const selectAll = (e) => {
        if(e.currentTarget.checked) {
            const arr = []
            for (const item of userColorList) {
                arr.push(item.no)
            }
            setSelectedNos(arr)
            onCheckedColor(arr)
        }
        else {
            setSelectedNos([])
            onCheckedColor([])
        }
    }
    const selectNoAll = (e) => {
        if(e.currentTarget.checked) {
            setSelectedNos([-1])
            onCheckedColor([-1])
        }
        else {
            setSelectedNos([])
            onCheckedColor([])
        }

    }

    return <div className="sm-div" style={{border: "1px solid lightgray"}}>
        <div className="d-flex">
            <input
                type="checkbox"
                name="flexColor1All"
                id="flexColor1All"
                style={{ verticalAlign: 'middle', marginLeft: '8px' }}
                checked={selectedNos.length === userColorList.length}
                onChange={selectAll}
            />
            <label
                htmlFor="flexColor1All"
                style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
            >
                관심회원전체
            </label>
            <input
                type="checkbox"
                name="flexColor1All"
                id="flexColor1NoAll"
                style={{ verticalAlign: 'middle', marginLeft: '8px' }}
                checked={selectedNos.filter((el) => el === -1).length > 0}
                onChange={selectNoAll}
            />
            <label
                htmlFor="flexColor1NoAll"
                style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
            >
                관심회원아님
            </label>
            {userColorList.map((item, index) => (
                <>
                    {index < 31 && (
                        <div className="mx-2" style={{backgroundColor:item.back_ground_color}} >
                            <input
                                type="checkbox"
                                name="flexColor1"
                                id={`flexColor1${item.no}`}
                                value={item.no}
                                style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                checked={selectedNos.filter((el) => el === item.no).length > 0}
                                onChange={(e) => {
                                    if(e.currentTarget.checked) {
                                        let arr
                                        if(selectedNos.includes(-1)) {
                                            arr = []
                                        }
                                        else {
                                            const filterArr = selectedNos.filter((el) => el !== item.no)
                                            arr = [...filterArr]
                                        }
                                        arr.push(item.no)
                                        setSelectedNos(arr)
                                        onCheckedColor(arr)
                                    }
                                    else {
                                        let arr
                                        if(selectedNos.includes(-1)) {
                                            arr = []
                                        }
                                        else {
                                            const filterArr = selectedNos.filter((el) => el !== item.no)
                                            arr = [...filterArr]
                                        }
                                        setSelectedNos(arr)
                                        onCheckedColor(arr)
                                    }
                                }}
                            />
                            <label
                                htmlFor={`flexColor1${item.no}`}
                                style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                            ><font color={item.font_color}>{item.no}</font>
                            </label>
                        </div>
                    )}
                </>
            ))}
        </div>
        <div className="d-flex">
            {userColorList.map((item, index) => (
                <>
                    {index >= 31 && (
                        <div className="mx-2" style={{backgroundColor:item.back_ground_color}} >
                            <input
                                type="checkbox"
                                name="flexColor1"
                                id={`flexColor1${item.no}`}
                                value={item.no}
                                style={{ verticalAlign: 'middle',marginLeft:'8px' }}
                                checked={selectedNos.filter((el) => el === item.no).length > 0}
                                onChange={(e) => {
                                    if(e.currentTarget.checked) {
                                        let arr
                                        if(selectedNos.includes(-1)) {
                                            arr = []
                                        }
                                        else {
                                            const filterArr = selectedNos.filter((el) => el !== item.no)
                                            arr = [...filterArr]
                                        }
                                        arr.push(item.no)
                                        setSelectedNos(arr)
                                        onCheckedColor(arr)
                                    }
                                    else {
                                        let arr
                                        if(selectedNos.includes(-1)) {
                                            arr = []
                                        }
                                        else {
                                            const filterArr = selectedNos.filter((el) => el !== item.no)
                                            arr = [...filterArr]
                                        }
                                        setSelectedNos(arr)
                                        onCheckedColor(arr)
                                    }
                                }}
                            />
                            <label
                                htmlFor={`flexColor1${item.no}`}
                                style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginBottom: '0', marginLeft: '0px' }}
                            ><font color={item.font_color}>{item.no}</font>
                            </label>
                        </div>
                    )}
                </>
            ))}
        </div>

    </div>
}

export default InterestUser