import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination2";
import RtmSttlItmComponents from "../../components/rtmSttl/RtmSttlItmComponents";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {ArrowsExpand} from "react-bootstrap-icons";
import {NumericFormat} from "react-number-format";
import {insertAdminLog} from "../../lib/api";
import {getRtmSttlItm} from "../../redux/reducers/rtmSttl/rtmSttlItm";
import {getPointList} from "../../redux/reducers/deposit/PointListReducer";

const RtmSttlItmpageContainer = ({userId}) => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const userInfo = useSelector((state) => state.userInfo)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.rtmSttlItm)
    const userState = useSelector((state) => state.userInfoCombine)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().subtract(364,"day").format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [userGbCd, setUserGbCd] = useState("");
    const [dateType, setDateType] = useState(0);

    const [userGbList, setUserGbList] = useState([]);
    const [selectedNos, setSelectedNos] = useState([]);

    const periodOption = [
        {value: "", name: "전체"},
        {value: '1', name: "24시간 이내"},
        {value: '2', name: "일주일 이내"},
        {value: '3', name: "30일 이내"},
        {value: '4', name: "60일 이내"},
        {value: '5', name: "90일 이내"},
        {value: '6', name: "180일 이내"},
    ]
    const gbOption = [
        {value: "", name: "전체"},
        {value: '2', name: "일반게임"},
        {value: '3', name: "미니게임"},
        {value: '4', name: "토큰게임"},
        {value: '5', name: "Inplay게임"},
        {value: '6', name: "조정/전환"},
    ]
    const pageOption = [
        {value: 20, name: "20개씩"},
        {value: 50, name: "50개씩"},
        {value: 100, name: "100개씩"},
        {value: 200, name: "200개씩"},
        {value: 999, name: "모두"},
    ]


    useEffect(() => {
        dispatch(getRtmSttlItm({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: (userId && userId !== "")? '2': initialState.searchGbCd,
            selectedNos: selectedNos,
            userId: userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getRtmSttlItm({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            selectedNos: selectedNos,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }


    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <div className="common">
            <div style={{
                paddingLeft: "1px", border:"1px solid lightgray"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">실시간정산내역</font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray"
                }}>
                    <div className="mb-3" style={{
                        width: "200px",
                        height: "50px",
                        borderRadius: "20px",
                        backgroundColor: "#F7F36E",
                        textAlign: "center",
                        lineHeight: "40px",  // 높이와 동일한 라인 높이를 설정하여 텍스트를 세로 중앙 정렬
                        border: "5px solid #FFDACD" // 파란색 테두리 추가
                    }}>
                        <strong style={{fontSize:"15px"}}>{initialState.countList.nm}</strong> <span style={{fontSize:"15px"}}>({initialState.countList.id})</span>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{ fontSize: '25px',fontWeight: 'bold' }}>
                        정산 보유금 : <span style={{color:"blue"}}> <NumericFormat value={initialState.countList.money_amt} displayType={'text'} thousandSeparator={true}/></span> &nbsp;&nbsp;&nbsp;
                        정산 루징금 : <span style={{color:"blue"}}> <NumericFormat value={initialState.countList.losing_cnt} displayType={'text'} thousandSeparator={true}/> </span> &nbsp;&nbsp;&nbsp;
                        소속회원 총 라이브 머니 : <span style={{color:"blue"}}><NumericFormat value={initialState.countList.live_money} displayType={'text'} thousandSeparator={true}/> </span>
                    </span>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <span style={{ fontSize: '14px',fontWeight: 'bold' }}>
                            소속회원 보유머니 : <span style={{color:"blue"}}><NumericFormat value={initialState.countList.live_money} displayType={'text'} thousandSeparator={true}/></span> / 소속회원 보유포인트 : <span style={{color:"blue"}}><NumericFormat value={initialState.countList.point} displayType={'text'} thousandSeparator={true}/></span> /소속회원 진행중베팅금 : <span style={{color:"blue"}}><NumericFormat value={initialState.countList.bet_amt} displayType={'text'} thousandSeparator={true}/></span>
                            </span>
                </div>
                    <div className="container-fluid m-1 p-1 d-flex justify-content-end">


                        <div className="input-group justify-content-end" style={{ display: "flex", width: "800px", alignItems: "center" }}>

                            <p style={{ width: "30px", margin: 0, fontSize: "14px" }}><strong>기간</strong></p>
                            <select style={{ width: "80px", fontSize: "10px" }}>
                                {periodOption.map((item) => (
                                    <option key={item.value} value={item.value}>{item.name}</option>
                                ))}
                            </select>
                            <p className="ms-3" style={{ width: "30px", margin: 0, fontSize: "14px" }}><strong>구분</strong></p>
                            <select style={{ width: "80px", fontSize: "10px" }}>
                                {gbOption.map((item) => (
                                    <option key={item.value} value={item.value}>{item.name}</option>
                                ))}
                            </select>
                            <p className="ms-3" style={{ width: "70px", margin: 0, fontSize: "14px" }}><strong>페이징건수</strong></p>
                            <select style={{ width: "80px", fontSize: "10px" }}>
                                {pageOption.map((item) => (
                                    <option key={item.value} value={item.value}>{item.name}</option>
                                ))}
                            </select>
                            <button className="ms-3" style={{ fontSize: "10px"}}>현재페이지새로고침</button>
                        </div>
                    </div>
                </div>
            </div>
        <RtmSttlItmComponents RtmSttlItmList={initialState.rtmSttlItm} />
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default RtmSttlItmpageContainer