import React from "react";

const KgonUserByGameStcsPage = () => {
    return (
        <div>
            <h7>[Kgon]회원별게임통계</h7>
        </div>

    )
}
export default KgonUserByGameStcsPage;