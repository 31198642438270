import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    displayYn : true,
    selectFullNm : "",

    query : "",
    orgList : [],
    searchList : [],
}

export const statsSlice = createSlice({
    name: 'OrgSelectReducer',
    initialState,
    reducers: {
        setOrgList(state, action) {
            const { id, list } = action.payload;
            if (!state[id]) {
                state[id] = {};
            }
            state[id].orgList = list
            state[id].searchList =list
        },
        setDisplayYn(state, action) {
            console.log("##setDisplayYn##", action.payload)
            const { id, displayYn } = action.payload;
            if (!state[id]) {
                state[id] = {};
            }
            state[id].displayYn = displayYn
        },
        setQuery(state, action) {
            console.log("## setQuery##", action.payload)
             const {id,val} = action.payload;
            if (!state[id]) {
                state[id] = {};
            }
             state[id].searchList = val
                ? state[id].orgList.filter(item => item.fullNm.includes(val))
                : state[id].orgList;
             state[id].query = val;
        },
        setSelectFullNm(state, action) {
            console.log("#setSelectFullNm#",action.payload)
            const {id,fullNm} = action.payload;
            if (!state[id]) {
                state[id] = {};
            }
            state[id].selectFullNm = fullNm;
        },
        setIsHovered(state, action){
            console.log("#action.payload.idx#",action.payload.idx)
            console.log("#action.payload.gb#",action.payload.gb)
            const {id,idx,gb} = action.payload;
            if (!state[id]) {
                state[id] = {};
            }
            state[id].searchList[idx].isHovered = gb;
        }
    }
});

export const {
    setOrgList,
    setDisplayYn,
    setQuery,
    setIsHovered,
    setSelectFullNm
} = statsSlice.actions;

export default statsSlice.reducer;