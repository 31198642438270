import React from 'react';
import MemberContainer from "../../containers/user/MemberListContainer";


const Member = () => {


    return (
        <div>

            <MemberContainer />
        </div>
    );
};

export default Member;