import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {setDisableYn, setMemberYnText, setMemberYnTextColor} from "../../../../redux/reducers/user/BlackMemberAddModalReducer";

const MemberInformationDetailModal = (props) => {


    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table width={"99%"}>
                    <tr>
                        <td align={"right"}>
                            <button style={{
                                backgroundColor: "#5dc434",
                                border: "none",
                                color: "white",
                                fontSize: "12px",
                                height: "20px",
                                width: "fit-content",
                                marginRight: "10px"
                            }} >회원 수정
                            </button>

                        </td>
                    </tr>
                </table>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
               <table className="commonT table table-bordered table-sm text-center">
                   <thead >
                   <tr>
                       <td colspan="2" align={"left"}>
                           <b>게임 종류별 개인 <font color={"red"}>최대 베팅 금액</font>(미설정시 기본 설정을 따름)</b>
                       </td>
                       <td colSpan="2" align={"left"}>
                           <b>게임 종류별 <font color={"red"}>단폴더</font> 개인 <font color={"red"}>최대 베팅 금액</font>(미설정시 기본 설정을 따름)</b>
                       </td>
                   </tr>
                   <tr>
                       <td colSpan="2" align={"left"}>
                           <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                       </td>
                       <td colSpan="2" align={"left"}>
                           <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "lightgray"}}>종류</td>
                       <td style={{width: "30%",backgroundColor: "lightgray"}}>베팅 최대 금액</td>
                       <td style={{width: "20%",backgroundColor: "lightgray"}}>종류</td>
                       <td style={{width: "30%",backgroundColor: "lightgray"}}>단폴더 베팅 최대 금액</td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"red"}>스포츠</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"red"}>스포츠</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"red"}>미니게임</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"red"}>미니게임</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>가상 농구</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>가상 농구</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>가상개경주</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>가상개경주</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>가상경마</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>가상경마</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>가상축구</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>가상축구</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>넥스트 링</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>넥스트 링</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>넥스트 바카라</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>넥스트 바카라</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>로투스바카라1</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>로투스바카라1</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>로투스홀짝</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>로투스홀짝</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>스폐셜</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>스폐셜</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>스피드키노</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>스피드키노</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>실시간</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>실시간</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>일반게임</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>일반게임</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>크로스</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>크로스</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>키노사다리</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>키노사다리</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>파워볼</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>파워볼</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>파워볼조합</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>파워볼조합</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>파워사다리</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>파워사다리</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>파워사다리 조합</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>파워사다리 조합</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>토큰</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                       <td style={{width: "20%",backgroundColor: "white"}} align={"left"}><font color={"black"}>토큰</font></td>
                       <td style={{width: "30%",backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1" />
                       </td>
                   </tr>
                   <tr>
                       <td colSpan="2" align={"left"}>
                           <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                       </td>
                       <td colSpan="2" align={"left"}>
                           <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                       </td>
                   </tr>
                   <tr>
                       <td colSpan={"4"}><br></br></td>
                   </tr>
                   <tr>
                       <td colSpan="2" align={"left"}>
                           <b>게임 종류별 개인 <font color={"red"}>최대 당첨 금액</font>(미설정시 기본 설정을 따름)</b>
                       </td>
                       <td colSpan="2" align={"left"}>
                           <b>게임 종류별 <font color={"red"}>단폴더</font> 개인 <font color={"red"}>최대 당첨 금액</font>(미설정시 기본 설정을
                               따름)</b>
                       </td>
                   </tr>
                   <tr>
                       <td colSpan="2" align={"left"}>
                           <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                       </td>
                       <td colSpan="2" align={"left"}>
                           <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "lightgray"}}>종류</td>
                       <td style={{width: "30%", backgroundColor: "lightgray"}}>베팅 최대 금액</td>
                       <td style={{width: "20%", backgroundColor: "lightgray"}}>종류</td>
                       <td style={{width: "30%", backgroundColor: "lightgray"}}>단폴더 베팅 최대 금액</td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"red"}>스포츠</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"red"}>스포츠</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"red"}>미니게임</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"red"}>미니게임</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font color={"black"}>가상
                           농구</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font color={"black"}>가상
                           농구</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>가상개경주</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>가상개경주</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>가상경마</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>가상경마</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>가상축구</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>가상축구</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font color={"black"}>넥스트
                           링</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font color={"black"}>넥스트
                           링</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font color={"black"}>넥스트
                           바카라</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font color={"black"}>넥스트
                           바카라</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>로투스바카라1</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>로투스바카라1</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>로투스홀짝</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>로투스홀짝</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>스폐셜</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>스폐셜</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>스피드키노</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>스피드키노</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>실시간</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>실시간</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>일반게임</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>일반게임</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>크로스</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>크로스</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>키노사다리</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>키노사다리</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>파워볼</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>파워볼</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>파워볼조합</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>파워볼조합</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>파워사다리</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>파워사다리</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font color={"black"}>파워사다리
                           조합</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font color={"black"}>파워사다리
                           조합</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>토큰</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                       <td style={{width: "20%", backgroundColor: "white"}} align={"left"}><font
                           color={"black"}>토큰</font></td>
                       <td style={{width: "30%", backgroundColor: "white"}} align={"left"}>
                           <input className="form-check-input sm-input" type="text"
                                  style={{width: "120px"}} placeholder="-1"/> 원
                       </td>
                   </tr>
                   <tr>
                       <td colSpan="2" align={"left"}>
                           <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                       </td>
                       <td colSpan="2" align={"left"}>
                           <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                       </td>
                   </tr>
                   </thead>
               </table>
            </div>
       </div>



    )
}
export default MemberInformationDetailModal;