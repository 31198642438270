import axios from "axios";
import {Link, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import moment from "moment";
import React, {useState} from "react";
import LoginComponent from "./components/loginComponent";
import LoginModal from "./components/LoginModal";
import HomePage from "./pages/home/HomePage";
import BettingMngIndex from "./pages/bettingMng/BettingMngIndex";
import BettingItmPage from "./pages/bettingMng/BettingItmPage";
import AutoGameStcsPage from "./pages/bettingMng/AutoGameStcsPage";
import NormGameStcsPage from "./pages/bettingMng/NormGameStcsPage";
import UserByGameStcsPage from "./pages/bettingMng/UserByGameStcsPage";
import InplayBettingItmPage from "./pages/bettingMng/InplayBettingItmPage";
import InplayUserByGameStcsPage from "./pages/bettingMng/InplayUserByGameStcsPage";
import ToknBettingItmPage from "./pages/bettingMng/ToknBettingItmPage";
import ToknUserByGameStcsPage from "./pages/bettingMng/ToknUserByGameStcsPage";
import KgonBettingItmPage from "./pages/bettingMng/KgonBettingItmPage";
import KgonUserByGameStcsPage from "./pages/bettingMng/KgonUserByGameStcsPage";
import DepWithMngIndex from "./pages/depWithMng/DepWithMngIndex";
import DepositItmPage from "./pages/depWithMng/DepositItmPage";
import WithdrawItmPage from "./pages/depWithMng/WithdrawItmPage";
import MoneyItmPage from "./pages/depWithMng/MoneyItmPage";
import MoneyItmStcsAllPage from "./pages/depWithMng/MoneyItmStcsAllPage";
import PointItmPage from "./pages/depWithMng/PointItmPage";
import PartnerMctSttlPage from "./pages/depWithMng/PartnerMctSttlPage";
import SttlAmtPaymentItmPage from "./pages/depWithMng/SttlAmtPaymentItmPage";
import RtmSttlIndex from "./pages/RtmSttl/RtmSttlIndex";
import RtmSttlItmPage from "./pages/RtmSttl/RtmSttlItmPage";
import UserMngIndex from "./pages/userMng/UserMngIndex";
import UserPage from "./pages/userMng/Member"
import ExchangeMngIndex from "./pages/exchangeMng/ExchangeMngIndex";
import ExchangePage from  "./pages/exchangeMng/ExchangeRequestPage"
import RecommendList from "./pages/recommendMng/recommend_list";
import RecommendAddComponent from "./components/modal/recommend/RecommendAddComponent";
import RecommendIndex from "./pages/recommendMng/RecommendIndex";

import {useSelector} from "react-redux";

function App() {

    const [ip, setIp] = useState("");

    const getIp = () => {
        axios.get('https://api.ipify.org?format=json').then((res) => {
            if(res.status === 200) {
                setIp(res.data.ip)
            }
        })
    }
    /// useEffect(getIp, [])


    return (
        <div>
            <Routes>


                <Route element={<Layout />}>
                    <Route path="/" element={<HomePage />}/>

                    <Route path="BettingMng" element={<BettingMngIndex />}>
                        <Route path="BettingItmPage" element={<BettingItmPage />}/>
                        <Route path="AutoGameStcsPage" element={<AutoGameStcsPage />}/>
                        <Route path="NormGameStcsPage" element={<NormGameStcsPage />}/>
                        <Route path="UserByGameStcsPage" element={<UserByGameStcsPage />}/>
                        <Route path="InplayBettingItmPage" element={<InplayBettingItmPage />}/>
                        <Route path="InplayUserByGameStcsPage" element={<InplayUserByGameStcsPage />}/>
                        <Route path="ToknBettingItmPage" element={<ToknBettingItmPage />}/>
                        <Route path="ToknUserByGameStcsPage" element={<ToknUserByGameStcsPage />}/>
                        <Route path="KgonBettingItmPage" element={<KgonBettingItmPage />}/>
                        <Route path="KgonUserByGameStcsPage" element={<KgonUserByGameStcsPage />}/>
                    </Route>
                    <Route path="DepWithMng" element={<DepWithMngIndex />}>
                        <Route path="DepositItmPage" element={<DepositItmPage />}/>
                        <Route path="WithdrawItmPage" element={<WithdrawItmPage />}/>
                        <Route path="MoneyItmPage" element={<MoneyItmPage />}/>
                        <Route path="MoneyItmStcsAllPage" element={<MoneyItmStcsAllPage />}/>
                        <Route path="PointItmPage" element={<PointItmPage />}/>
                        <Route path="PartnerMctSttlPage" element={<PartnerMctSttlPage />}/>
                        <Route path="SttlAmtPaymentItmPage" element={<SttlAmtPaymentItmPage />}/>
                    </Route>
                    <Route path="RtmSttl" element={<RtmSttlIndex />}>
                        <Route path="RtmSttlItmPage" element={<RtmSttlItmPage />}/>
                    </Route>
                    <Route path="Recommend" element={<RecommendIndex />}>
                        <Route path="recommendList" element={<RecommendList />}/>
                        <Route path="recommendAddComponent" element={<RecommendAddComponent />}/>
                    </Route>
                    <Route path="UserMng" element={<UserMngIndex />}>
                        <Route path="UserPage" element={<UserPage />}/>
                    </Route>
                    <Route path="ExchangeMng" element={<ExchangeMngIndex />}>
                        <Route path="ExchangePage" element={<ExchangePage />}/>
                    </Route>
                </Route>
            </Routes>
        </div>
    );
}

function Layout() {
    const userInfo = useSelector((state) => state.userInfo);

    const [activeNav, setActiveNav] = useState();
    const navigate = useNavigate();

    const ontoggle = (index, address) => {
        setActiveNav(index)
        navigate(address)
    }


    return (
        <div>
                <nav className="navbar navbar-expand-sm navbar-dark ">
                    <div className="navbar-collapse collapse justify-content-center adminMenu">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className="nav-link" to="/">홈</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/BettingMng/BettingItmPage">베팅관리</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/DepWithMng/DepositItmPage">충환전관리</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/UserMng/UserPage">회원관리</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/Recommend/recommendList">추천인키관리</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/RtmSttl/RtmSttlItmPage">실시간정산</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/ExchangeMng/ExchangePage">정산금환전신청</Link>
                            </li>

                        </ul>
                    </div>
                    <div className= "loginInfoMenu" style={{alignItems: "center"}}>
                        <LoginComponent />
                    </div>
                </nav>

            <div className="d-flex">
                {userInfo.isLogin?
                <>
                    <div style={{
                        width: "100%",
                        position:"relative",
                        }}>
                            <Outlet/>
                    </div>
                </>
                    :
                    (
                        <div>
                            <LoginModal/>
                        </div>
                    )}
            </div>

        </div>
    );
}

export default App;

