import {call, put, take,} from "redux-saga/effects";
import {fetchWithdrawList} from "../../../lib/api";
import {failedGetWithdrawList, setWithdrawList} from "../../reducers/deposit/WithdrawListReducer";

export function* getWithdrawListSaga(action) {
    try{
        const res = yield call(
            fetchWithdrawList,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.userGbCd, action.payload.searchGbCd,
            action.payload.searchCd01, action.payload.searchCd02, action.payload.searchCd03,
            action.payload.searchCd04, action.payload.searchCd05, action.payload.searchCd06,
            action.payload.searchCd07, action.payload.searchCd08, action.payload.searchCd09,
            action.payload.searchCd10, action.payload.searchCd11, action.payload.searchCd12,
            action.payload.searchCd13, action.payload.searchCd14, action.payload.searchCd15,
            action.payload.searchCd16, action.payload.keyCd, action.payload.valueCd,
            action.payload.userId, action.payload.selectedNos, action.payload.selectedNos3,
            action.payload.selectedNos4,
            action.payload.sessionId, action.payload.sessionLevel
        )
        yield put(setWithdrawList(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetWithdrawList(error))
    }
}