import React from 'react';
import ExchangeRequestContainer from "../../containers/exchange/ExchangeRequestContainer";
import {useParams} from "react-router-dom";


const ExchangeRequestPage = () => {
    const param = useParams()
    return (
        <div>
            <ExchangeRequestContainer userId={param.userId}/>
        </div>
    );
};

export default ExchangeRequestPage;