import React, {useState, useEffect} from "react";
import DepositListContainer from "../../../../containers/deposit/DepositListContainer";

const MemberInformationDetailModal = (props) => {


    return (
        <div>
            <DepositListContainer />
        </div>
    )
}
export default MemberInformationDetailModal;