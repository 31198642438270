import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination2";
import {getMoneyList} from "../../redux/reducers/deposit/MoneyListReducer";
import MoneyList from "../../components/deposit/MoneyList";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {ArrowsExpand} from "react-bootstrap-icons";
import {NumericFormat} from "react-number-format";
import {getMemberUser} from "../../redux/reducers/user/MemberReducer";
import {insertAdminLog} from "../../lib/api";

const MoneyListContainer = ({userId}) => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const userInfo = useSelector((state) => state.userInfo)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.moneyList)
    const userState = useSelector((state) => state.userInfoCombine)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    const [userGbList, setUserGbList] = useState([]);
    const [userGbCd, setUserGbCd] = useState('')

    const [dateType, setDateType] = useState(0);

    const [maxDate, setMaxDate] = useState('#3fadf7')
    const [date365, setDate365] = useState('#3fadf7')
    const [date180, setDate180] = useState('#3fadf7')
    const [date90, setDate90] = useState('#3fadf7')
    const [date60, setDate60] = useState('#3fadf7')
    const [date30, setDate30] = useState('#3fadf7')
    const [date7, setDate7] = useState('#3fadf7')
    const [date1, setDate1] = useState('#3fadf7')
    const [date0, setDate0] = useState('#3fadf7')
    const [minDate, setMinDate] = useState('red')


    const changeFieldListHandler = (e) => {
        setUserGbCd(e.currentTarget.value);
        //setUserGbCd(e.currentTarget.value);
    }

    const getCmmnCd = () => {
        if(userGbList.length === 0){
            axios.post('/partner/admin/cmmnCdList', {
                cmmnCdId : '0020',
            }).then((res) => {
                setUserGbList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: (userId && userId !== "")? '2': initialState.searchGbCd,
            userId: userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
        setSearchQuery(userId)

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfo.id,
            logContent: "머니내역"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })

    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getMoneyList({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const onClickReset = (e) => {
        window.location.reload();
    }

    const onRefresh = () => {
        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }


    const exportExcel = ()=>{
        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {

            let jsonObject = { // JSON 객체를 생성합니다.
                순서: initialState.totalList[i].no? initialState.totalList[i].no :'',
                부본사: initialState.totalList[i].company_sub_no? initialState.totalList[i].company_sub_no :'',
                가맹: initialState.totalList[i].mrch_cd? initialState.totalList[i].mrch_cd :'',
                아아디: initialState.totalList[i].id? initialState.totalList[i].id :'',
                닉네임: initialState.totalList[i].nickname? initialState.totalList[i].nickname :'',
                이름: initialState.totalList[i].name? initialState.totalList[i].name :'',
                회원구분: initialState.totalList[i].user_gb_cd? initialState.totalList[i].user_gb_cd :'',
                로그구분: initialState.totalList[i].log_gb_cd? initialState.totalList[i].log_gb_cd :'',
                베팅상세: initialState.totalList[i].bet_detail? initialState.totalList[i].bet_detail :'',
                이전금액: initialState.totalList[i].before_amt? initialState.totalList[i].before_amt :'',
                요청금액: initialState.totalList[i].request_amt? initialState.totalList[i].request_amt :'',
                현재금액: initialState.totalList[i].now_amt? initialState.totalList[i].now_amt :'',
                일시: initialState.totalList[i].updatedAt? initialState.totalList[i].updatedAt :'',
                관련번호: initialState.totalList[i].involvement_no? initialState.totalList[i].involvement_no :'',
                메모: initialState.totalList[i].memo? initialState.totalList[i].memo :'',
                수행아이디: initialState.totalList[i].reg_id? initialState.totalList[i].reg_id :'',
                수행아이피: initialState.totalList[i].reg_ip? initialState.totalList[i].reg_ip :''
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '머니내역');
        XLSX.writeFile(workBook, '머니내역.xlsx');

    }

    const userGbCdChangeHandler = (e) => {
        setUserGbCd(e.currentTarget.value);

        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: e.currentTarget.value,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }


    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
        setMaxDate('#3fadf7')
        setDate365('#3fadf7')
        setDate180('#3fadf7')
        setDate90('#3fadf7')
        setDate60('#3fadf7')
        setDate30('#3fadf7')
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
        setMinDate('#3fadf7')
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
        setMaxDate('#3fadf7')
        setDate365('#3fadf7')
        setDate180('#3fadf7')
        setDate90('#3fadf7')
        setDate60('#3fadf7')
        setDate30('#3fadf7')
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
        setMinDate('#3fadf7')
    }


    const addMaxDate = () => {
        setStartDate(moment().add(-730, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setMaxDate('red');
        setDate365('#3fadf7')
        setDate180('#3fadf7')
        setDate90('#3fadf7')
        setDate60('#3fadf7')
        setDate30('#3fadf7')
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
        setMinDate('#3fadf7')

        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-730, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const add365Date = () => {
        setStartDate(moment().add(-365, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setMaxDate('#3fadf7')
        setDate365('red')
        setDate180('#3fadf7')
        setDate90('#3fadf7')
        setDate60('#3fadf7')
        setDate30('#3fadf7')
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
        setMinDate('#3fadf7')

        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-365, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const add180Date = () => {
        setStartDate(moment().add(-180, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setMaxDate('#3fadf7')
        setDate365('#3fadf7')
        setDate180('red')
        setDate90('#3fadf7')
        setDate60('#3fadf7')
        setDate30('#3fadf7')
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
        setMinDate('#3fadf7')

        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-180, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const add90Date = () => {
        setStartDate(moment().add(-90, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setMaxDate('#3fadf7')
        setDate365('#3fadf7')
        setDate180('#3fadf7')
        setDate90('red')
        setDate60('#3fadf7')
        setDate30('#3fadf7')
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
        setMinDate('#3fadf7')

        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-90, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const add60Date = () => {
        setStartDate(moment().add(-60, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setMaxDate('#3fadf7')
        setDate365('#3fadf7')
        setDate180('#3fadf7')
        setDate90('#3fadf7')
        setDate60('red')
        setDate30('#3fadf7')
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
        setMinDate('#3fadf7')

        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-60, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const add30Date = () => {
        setStartDate(moment().add(-30, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setMaxDate('#3fadf7')
        setDate365('#3fadf7')
        setDate180('#3fadf7')
        setDate90('#3fadf7')
        setDate60('#3fadf7')
        setDate30('red')
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
        setMinDate('#3fadf7')

        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-30, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const add7Date = () => {
        setStartDate(moment().add(-7, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setMaxDate('#3fadf7')
        setDate365('#3fadf7')
        setDate180('#3fadf7')
        setDate90('#3fadf7')
        setDate60('#3fadf7')
        setDate30('#3fadf7')
        setDate7('red')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
        setMinDate('#3fadf7')

        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-7, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const add1Date = () => {
        setStartDate(moment().add(-1, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setMaxDate('#3fadf7')
        setDate365('#3fadf7')
        setDate180('#3fadf7')
        setDate90('#3fadf7')
        setDate60('#3fadf7')
        setDate30('#3fadf7')
        setDate7('#3fadf7')
        setDate1('red')
        setDate0('#3fadf7')
        setMinDate('#3fadf7')

        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-1, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const add0Date = () => {
        setStartDate(moment().format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setMaxDate('#3fadf7')
        setDate365('#3fadf7')
        setDate180('#3fadf7')
        setDate90('#3fadf7')
        setDate60('#3fadf7')
        setDate30('#3fadf7')
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('red')
        setMinDate('#3fadf7')

        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const addMinDate = () => {
        setStartDate(moment().add(-24, "hour").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setMaxDate('#3fadf7')
        setDate365('#3fadf7')
        setDate180('#3fadf7')
        setDate90('#3fadf7')
        setDate60('#3fadf7')
        setDate30('#3fadf7')
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
        setMinDate('red')

        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-24, "hour").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const insertSelect01 = (value) => {
        setUserGbCd(value);
        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: value,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const dateTypeChangeHandler = (type) => {
        setDateType(type)

        dispatch(getMoneyList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: type,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    return <>
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">머니내역</font>&nbsp;
                    <select
                        className="form-select sm-select"
                        defaultValue={120}
                        style={{
                            width: "100px"
                        }}
                        value={initialState.pageSize === "999"? "모두" : initialState.pageSize}
                        onChange={(e) => {
                            dispatch(getMoneyList({
                                page: initialState.page,
                                pageSize: parseInt(e.target.value),
                                order: initialState.order,
                                query: searchQuery,
                                startDate: startDate? startDate : initialState.startDate,
                                endDate: endDate? endDate : initialState.endDate,
                                userGbCd: initialState.userGbCd,
                                searchGbCd: initialState.searchGbCd,
                                userId: userState.userId,
                                sessionId: userInfo.id,
                                sessionLevel: userInfo.level
                            }))
                        }}
                    >
                        <option value={30}>30개</option>
                        <option value={60}>60개</option>
                        <option value={120}>120개</option>
                        <option value={320}>320개</option>
                        <option value={500}>500개</option>
                        <option value={1000}>1000개</option>
                    </select>
                    <table width={"89%"}>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "80px"
                                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색
                                </button>
                                &nbsp;
                                <button style={{
                                    width: "120px", backgroundColor :"gray"
                                }} type="submit" className="btn btn-primary" onClick={onClickReset}>새로고침(자동보류)</button>
                            </td>
                        </tr>
                    </table>

                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex", border: "1px solid lightgray"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex", border: "1px solid lightgray", width: "800px"
                    }}>
                        <button style={{
                            backgroundColor: "#5dc434",
                            border: "none",
                            color: "white",
                            fontSize: "12px",
                            height: "20px",
                            width: "fit-content",
                            marginRight: "10px"
                        }}
                                onClick={exportExcel}
                        >엑셀 다운로드
                        </button>
                        <button style={{ backgroundColor: maxDate, border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={addMaxDate}>최대</button>
                        <button style={{ backgroundColor: date365, border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add365Date}>365일</button>
                        <button style={{ backgroundColor: date180, border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add180Date}>180일</button>
                        <button style={{ backgroundColor: date90, border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add90Date}>90일</button>
                        <button style={{ backgroundColor: date60, border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add60Date}>60일</button>
                        <button style={{ backgroundColor: date30, border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add30Date}>30일</button>
                        <button style={{ backgroundColor: date7, border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add7Date}>일주일</button>
                        <button style={{ backgroundColor: date1, border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add1Date}>어제</button>
                        <button style={{ backgroundColor: date0, border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add0Date}>오늘</button>
                        <button style={{ backgroundColor: minDate, border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={addMinDate}>지난24시간</button>

                        <input className="form-control sm-date" type="date" value={startDate} onChange={startDateHandler} style={{width:"110px"}}/>
                        &nbsp;~&nbsp;
                        <input className="form-control sm-date" type="date" value={endDate} onChange={endDateHandler} style={{width:"110px"}}/>
                        &nbsp;
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex", border: "1px solid lightgray", width: "170px"
                    }}>
                        <ArrowsExpand/>정렬 :
                        <input className="form-check-input"  type="radio" name="dataType" id="inlineRadio2" value={dateType} style={{ cursor: "pointer" }}
                               onChange={() => dateTypeChangeHandler(1)} defaultChecked={true}/> 최근 우선
                        <input className="form-check-input"  type="radio" name="dataType" id="inlineRadio2" value={dateType} style={{ cursor: "pointer" }}
                               onChange={() => dateTypeChangeHandler(2)} /> 과거 우선
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex", border: "1px solid lightgray", width: "440px"
                    }}>
                        <ArrowsExpand/>구분 :&nbsp;&nbsp;
                        <button className="ms-2" style={{
                            backgroundColor: "#CECECE",
                            border: "1px solid black",
                            fontSize: "10px",
                            height: "16px",
                            width: "fit-content"
                        }}
                                onClick={() => insertSelect01("01")}
                        >충/환전
                        </button>
                        <button className="ms-2" style={{
                            backgroundColor: "#CECECE",
                            border: "1px solid black",
                            fontSize: "10px",
                            height: "16px",
                            width: "fit-content"
                        }}
                                onClick={() => insertSelect01("02")}
                        >충전
                        </button>
                        <button className="ms-2" style={{
                            backgroundColor: "#CECECE",
                            border: "1px solid black",
                            fontSize: "10px",
                            height: "16px",
                            width: "fit-content"
                        }}
                                onClick={() => insertSelect01("03")}
                        >환전
                        </button>
                        &nbsp;&nbsp;
                        <select
                            className="form-select sm-select"
                            defaultValue={''}
                            style={{
                                width: "250px"
                            }}
                            onChange={userGbCdChangeHandler} value={userGbCd}
                        >
                            <option key={''} value={''}>::::선택하세요:::::</option>
                            {userGbList.map((item, index) => (
                                <option key={item.cmmn_cd_dtl_id} value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex", border: "1px solid lightgray"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex", border: "1px solid lightgray", width: "500px"
                    }}>
                        <select
                            className="form-select sm-select"
                            style={{
                                width: "100px"
                            }}
                            defaultValue={userId && userId !== ""? '2': '1'}
                            value={initialState.searchGb === ""? "1" : initialState.searchGb}
                            onChange={e =>
                                dispatch(getMoneyList({
                                    page: initialState.page,
                                    pageSize: initialState.pageSize,
                                    order: initialState.order,
                                    query: initialState.query,
                                    startDate: startDate? startDate : initialState.startDate,
                                    endDate: endDate? endDate : initialState.endDate,
                                    userGbCd: initialState.userGbCd,
                                    searchGbCd: e.target.value,
                                    userId: userState.userId,
                                    sessionId: userInfo.id,
                                    sessionLevel: userInfo.level
                                }))
                            }
                        >
                            <option value={'1'}>닉네임</option>
                            <option value={'2'}>아이디</option>
                            <option value={'3'}>가맹점명</option>
                            <option value={'4'}>관련번호</option>
                            <option value={'5'}>전체</option>
                        </select>
                        &nbsp;
                        <input style={{
                            width: "220px"
                        }} type="text" className="form-control" id="search_edit" placeholder={userId && userId != '' ? userId : '검색조건을 입력하세요.'} onBlur={(e) => {
                            changeQuery(e.target.value)
                        }} onKeyDown={inputPress} />
                    </div>
                </div>

            </div>
            <br></br>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table width={"99%"} border="1px"
                           className="table table-bordered table-sm text-center align-middle">
                        <tr>
                            <td align={"left"} colSpan="10">
                                <font color="red">* 조회 기간 내 통계</font>
                                &nbsp;&nbsp;
                            </td>
                        </tr>
                        {initialState.personalList && (
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} height={"30px"} colSpan="2">게임머니(보유금)</td>
                            <td align={"center"} colSpan="3">
                                <font color="blue">
                                    <NumericFormat className="ms-1" value={initialState.personalList ? initialState.personalList.game_money_amt : 0} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </td>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} height={"30px"} colSpan="2">포인트(보유금)</td>
                            <td align={"center"} colSpan="3">
                                <font color="blue">
                                    <NumericFormat className="ms-1" value={initialState.personalList ? initialState.personalList.point : 0} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </td>
                        </tr>
                        )}
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} height={"30px"} colSpan="2">충전</td>
                            <td align={"center"} colSpan="3">
                                <font color="blue">
                                    <NumericFormat className="ms-1" value={initialState.countList.deposit_amt} displayType={'text'} thousandSeparator={true}/> /
                                    <NumericFormat className="ms-1" value={initialState.countList.deposit_cnt} displayType={'text'} thousandSeparator={true}/>
                                </font>회
                            </td>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} colSpan="2">베팅</td>
                            <td align={"center"} colSpan="3">
                                <font color="blue">
                                    <NumericFormat className="ms-1" value={initialState.countList.betting_amt} displayType={'text'} thousandSeparator={true}/> /
                                    <NumericFormat className="ms-1" value={initialState.countList.betting_cnt} displayType={'text'} thousandSeparator={true}/>
                                </font>회
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} height={"30px"} colSpan="2">환전</td>
                            <td align={"center"} colSpan="3">
                                <font color="red">
                                    <NumericFormat className="ms-1" value={initialState.countList.withdraw_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                                <font color="blue"> /
                                    <NumericFormat className="ms-1" value={initialState.countList.withdraw_cnt} displayType={'text'} thousandSeparator={true}/>
                                </font>회
                            </td>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} colSpan="2">적중</td>
                            <td align={"center"} colSpan="3">
                                <font color="red">
                                    <NumericFormat className="ms-1" value={initialState.countList.mark_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} height={"30px"} colSpan="2">마진</td>
                            <td align={"center"} colSpan="3">
                                <font color="red">
                                    <NumericFormat className="ms-1" value={initialState.countList.margin_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </td>
                            <td style={{backgroundColor: "lightgray"}} align={"center"} colSpan="2">베팅마진</td>
                            <td align={"center"} colSpan="3">
                                <font color="blue">
                                    <NumericFormat className="ms-1" value={initialState.countList.betting_margin_amt} displayType={'text'} thousandSeparator={true}/>
                                </font>
                            </td>
                        </tr>
                    </table>

                </div>

            </div>
            <br></br>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                [현재 페이지 총합 : <font color="red">
                <NumericFormat className="ms-1" value={initialState.countList.total_amt} displayType={'text'} thousandSeparator={true}/>
            </font>]
            </div>
        </div>
        <MoneyList moneyList={initialState.moneyList} onRefresh={onRefresh} />
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default MoneyListContainer