import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const userInfoCombineSlice = createSlice({
    name: 'userInfoCombine',
    initialState: {
        userInfoCombine: [],
        isOpen : true,
        isOpen02 : false,
        isOpen03 : false,
        isOpen04 : false,
        isOpen05 : false,
        isOpen06 : false,
        isOpen07 : false,
        isOpen08 : false,
        isOpen09 : false,
        isOpen10 : false,
    },
    reducers: {
        setIsOpen(state,action){
            state.isOpen = action.payload;
        },
        setIsOpen02(state,action){
            state.isOpen02 = action.payload;
        },
        setIsOpen03(state,action){
            state.isOpen03 = action.payload;
        },
        setIsOpen04(state,action){
            state.isOpen04 = action.payload;
        },
        setIsOpen05(state,action){
            state.isOpen05 = action.payload;
        },
        setIsOpen06(state,action){
            state.isOpen06 = action.payload;
        },
        setIsOpen07(state,action){
            state.isOpen07 = action.payload;
        },
        setIsOpen08(state,action){
            state.isOpen08 = action.payload;
        },
        setIsOpen09(state,action){
            state.isOpen09 = action.payload;
        },
        setIsOpen10(state,action){
            state.isOpen10 = action.payload;
        },
        setIsUserId(state,action){
            state.userId = action.payload;
        },
        setIsUserNo(state,action){
            state.userNo = action.payload;
        },
        setIsUserNickname(state,action){
            state.nickName = action.payload;
        },
    }
});

export const { setIsOpen,setIsOpen02,setIsOpen03,setIsOpen04,setIsOpen05,setIsOpen06,setIsOpen07,setIsOpen08,setIsOpen09,setIsOpen10, setIsUserId, setIsUserNo, setIsUserNickname } = userInfoCombineSlice.actions;

export default userInfoCombineSlice.reducer;