import React, {useState, useEffect} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {
    setSearchIdArr,
    setSearchIdSort,
    setSearchNicknameArr,
    setSearchNicknameSort
} from "../../../redux/reducers/user/MemberReducer";

const MemberSearchModal03 = (props) => {

    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo)
    const {open, onClose, initialState, onRefresh} = props
    //회원등급
    const [userGbCd, setUserGbCd] = useState('')
    const [userGbList, setUserGbList] = useState([]);

    const getCmmnCd = () => {
        if (userGbList.length === 0) {
            axios.post('/admin/cmmnCdList', {
                cmmnCdId: '0004',
            }).then((res) => {
                setUserGbList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])

    const handleCancel = () => {
        onClose()
    }

    const handleSearch = () => {
        onClose()
        onRefresh()
    }


    const idChangeHandler = (e) => {
        dispatch(setSearchNicknameArr(e.currentTarget.value));
    }

    const sortChangeHandler = (value) => {
        dispatch(setSearchNicknameSort(value));
    }


    const userGbCdChangeHandler = (e, initialState) => {
        setUserGbCd(e.currentTarget.value);
        //alert("initialState :: "+initialState);


    }

    const handleRegister = () => {
        axios.post('/admin/member/changePntGbCd', {
            pntGbCd: userGbCd,
            updateList: initialState.totalList,
            adminNo: userInfo.id,
            reg_ip: userInfo.ip
        }).then((res) => {
            alert(res.data.isUpdated? "레벨을 변경했습니다." : "레벨을 실패했습니다.")
            onClose()
            onRefresh();
        })
    }


    return (
        <div className="member_search_select_modal_background">
            <div className="member_search_select_modal_box" >
                <div className="member_search_select_modal_body">

                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table   width={"99%"}>
                            <tr>
                                <td style={{backgroundColor:"orange"}} align={"left"} height={"30px"}>
                                    <b><font color={"white"} size={"2"}>&nbsp;&nbsp; 레벨설정</font></b>
                                </td>
                                <td style={{backgroundColor:"orange"}} align={"right"}>
                                    <button style={{ backgroundColor: "lightgray", border: "none", color: "black" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={handleCancel}><b>X</b></button>
                                    &nbsp;&nbsp;
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%", backgroundColor:"lightgray"}}>
                            <tr>
                                <td style={{backgroundColor:"white"}} width={"20%"} align={"center"}>
                                    레벨선택
                                </td>
                                <td style={{backgroundColor:"white"}} width={"80%"} align={"left"}>
                                    &nbsp;&nbsp;
                                    <select
                                        className=""
                                        style={{
                                            width: "100px"
                                        }}
                                        onChange={(e) => userGbCdChangeHandler(e, initialState)}
                                        value={userGbCd}
                                    >
                                            <option key={""} value={""}>선택하세요</option>
                                        {userGbList.map((item, index) => (
                                            <option key={item.cmmn_cd_dtl_id} value={item.cmmn_cd_dtl_id}>{item.cmmn_cd_dtl_nm}</option>
                                        ))}
                                    </select>
                                    &nbsp;&nbsp;
                                    <button style={{ backgroundColor: "lightskyblue", border: "none", color: "white" ,fontSize: "11px", height: "25px", width: "40px", marginRight: "5px"}} onClick={handleRegister}>변경</button>
                                </td>
                            </tr>
                        </table>
                    </div>


                </div>
            </div>
        </div>

    )
}
export default MemberSearchModal03;