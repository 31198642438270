import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {NumericFormat} from "react-number-format";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";
const WithdrawList = ({withdrawList, onRefresh, updateState, updateStateMulti, initialState, updateMarking}) => {

    const dispatch = useDispatch()
    const [selectedNos, setSelectedNos] = useState([]);
    const [isEditing00, setIsEditing00] = useState(false);
    const [isEditing01, setIsEditing01] = useState(true);



    const allCheckHandler = (e) => {
        if(withdrawList.length > 0 && e.currentTarget.value) {
            const checks = [];
            let total = 0;
            for(let i = 0; i < withdrawList.length; i++) {

                if(withdrawList[i].withdraw_state_cd === 2 || withdrawList[i].withdraw_state_cd === 3 || withdrawList[i].withdraw_state_cd === 4)
                {
                    continue;
                }
                checks.push(withdrawList[i].no)
                total += withdrawList[i].deposit_amt
            }
            setSelectedNos(e.target.checked? checks : [])
        }
    }

    const checkHandler = (checked, no, state_cd) => {

        if (state_cd == 2 || state_cd == 3 || state_cd ==4)
        {
            return;
        }

        let selectedNos2 = []
        if(checked) {
            selectedNos2 = [...selectedNos, no]
            setSelectedNos([...selectedNos, no])
        }
        else {
            selectedNos2 = selectedNos.filter((id) => id !== no)
            setSelectedNos(selectedNos.filter((id) => id !== no))
        }
        let total = 0;
        for(let i = 0; i < selectedNos2.length; i++) {
            total += withdrawList.filter((id) => id.no === selectedNos2[i])[0]
        }
    }

    const withdrawState = (state, no) => {
        switch(state) {
            //대기
            case 0 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td valign={"middle"} align={"center"}>
                                    대기
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(2, [no])}}>완료처리</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(1, [no])}}>확인중</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(3, [no])}}>취소</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //보류
            case 1 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td valign={"middle"} align={"center"}>
                                    확인중
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(2, [no])}}>완료처리</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(3, [no])}}>취소</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //입금완료
            case 2 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td valign={"middle"} align={"center"}>
                                    완료
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(5, [no])}}>확인중</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(4, [no])}}>취소</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //취소완료
            case 3 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td valign={"middle"} align={"center"}>
                                    취소
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    취소 건 조작불가
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //완료후취소
            case 4 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td valign={"middle"} align={"center"}>
                                    취소
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    취소 건 조작불가
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //완료후확인중
            case 5 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td valign={"middle"} align={"center"}>
                                    확인중
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(2, [no])}}>완료처리</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(3, [no])}}>취소</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            default : return state
        }
    }

    const start01EditingHandler = () => {
        setIsEditing01(false);
    };
    const start02EditingHandler = () => {
        setIsEditing01(true);
    };
    const start03EditingHandler = () => {
        setIsEditing00(false);
    };
    const start04EditingHandler = () => {
        setIsEditing00(true);
    };


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={() => {updateStateMulti(2, selectedNos)}}>선택 항목 환전 처리</button>
                    <font color="gray">[전체 :<NumericFormat className="ms-1" value={initialState.countList.withdraw_cnt} displayType={'text'} thousandSeparator={true}/>건 (<NumericFormat className="ms-1" value={initialState.countList.withdraw_amt} displayType={'text'} thousandSeparator={true}/>원)]</font>
                    &nbsp;&nbsp;
                    <font color="red">[대기 :<NumericFormat className="ms-1" value={initialState.countList.state01_cnt} displayType={'text'} thousandSeparator={true}/>건 (<NumericFormat className="ms-1" value={initialState.countList.state01_amt} displayType={'text'} thousandSeparator={true}/>원)]</font>
                    &nbsp;&nbsp;
                    <font color="blue">[완료 :<NumericFormat className="ms-1" value={initialState.countList.state02_cnt} displayType={'text'} thousandSeparator={true}/>건 (<NumericFormat className="ms-1" value={initialState.countList.state02_amt} displayType={'text'} thousandSeparator={true}/>원)]</font>
                    &nbsp;&nbsp;
                    <font color="#006400">[확인중 :<NumericFormat className="ms-1" value={initialState.countList.state04_cnt} displayType={'text'} thousandSeparator={true}/>건 (<NumericFormat className="ms-1" value={initialState.countList.state04_amt} displayType={'text'} thousandSeparator={true}/>원)]</font>
                    &nbsp;&nbsp;
                    <font color="gray">[취소 :<NumericFormat className="ms-1" value={initialState.countList.state03_cnt} displayType={'text'} thousandSeparator={true}/>건 (<NumericFormat className="ms-1" value={initialState.countList.state03_amt} displayType={'text'} thousandSeparator={true}/>원)]</font>
                </div>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead >
                        <tr>
                            <th style={{width: "2%"}} rowSpan="2">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" style={{cursor: "pointer"}}
                                       checked={withdrawList.length === selectedNos.length} onChange={allCheckHandler}
                                />
                            </th>
                            <th style={{width: "2%"}} rowSpan="2">고유번호</th>
                            <th style={{width: "3%"}} rowSpan="2">사이트코드</th>
                            <th style={{width: "3%"}} rowSpan="2">소속</th>
                            <th style={{width: "3%"}} rowSpan="2">그룹</th>
                            <th style={{width: "5%"}} rowSpan="2">닉네임</th>
                            <th style={{width: "3%"}} rowSpan="2">등급</th>
                            <th style={{width: "3%"}} rowSpan="2">포인트등급</th>
                            <th style={{width: "3%"}} rowSpan="2">전체베팅차단</th>
                            <th style={{width: "3%"}} rowSpan="2">입금방식</th>
                            <th style={{width: "3%"}} rowSpan="2">기준일충전</th>
                            <th style={{width: "3%"}} rowSpan="2">기준일환전</th>
                            <th style={{width: "3%"}} rowSpan="2">
                                {isEditing00 && (
                                    <span>전체</span>
                                )}
                                {isEditing00 && (
                                    <button style={{
                                        backgroundColor: "red",
                                        border: "none",
                                        color: "black",
                                        fontSize: "12px",
                                        height: "29px",
                                        width: "fit-content",
                                        marginRight: "5px"
                                    }}
                                            onClick={() => {start03EditingHandler()}}
                                    >축소
                                    </button>
                                )}
                                {!isEditing00 && (
                                    <span>마지막 환전 이후 롤링%<br></br>전체</span>
                                )}
                                {!isEditing00 && (
                                    <button style={{
                                        backgroundColor: "green",
                                        border: "none",
                                        color: "black",
                                        fontSize: "12px",
                                        height: "29px",
                                        width: "fit-content",
                                        marginRight: "5px"
                                    }}
                                            onClick={() => {start04EditingHandler()}}
                                    >확장
                                    </button>
                                )}
                            </th>
                            {isEditing00 && (
                                <th style={{width: "10%"}}>
                                    마지막 환전 이후 롤링%
                                </th>
                            )}


                            <th style={{width: "3%"}} rowSpan="2">
                                {isEditing01 && (
                                    <span>전체</span>
                                )}
                                {isEditing01 && (
                                    <button style={{
                                        backgroundColor: "red",
                                        border: "none",
                                        color: "black",
                                        fontSize: "12px",
                                        height: "29px",
                                        width: "fit-content",
                                        marginRight: "5px"
                                    }}
                                            onClick={() => {start01EditingHandler()}}
                                    >축소
                                    </button>
                                )}
                                {!isEditing01 && (
                                    <span>마지막 충전 대비 롤링%<br></br>전체</span>
                                )}
                                {!isEditing01 && (
                                    <button style={{
                                        backgroundColor: "green",
                                        border: "none",
                                        color: "black",
                                        fontSize: "12px",
                                        height: "29px",
                                        width: "fit-content",
                                        marginRight: "5px"
                                    }}
                                            onClick={() => {start02EditingHandler()}}
                                    >확장
                                    </button>
                                )}
                            </th>
                            {isEditing01 && (
                                <th style={{width: "10%"}}>
                                    마지막 충전 대비 롤링%
                                </th>
                            )}
                            <th style={{width: "3%"}} rowSpan="2">마진</th>
                            <th style={{width: "3%"}} rowSpan="2">출금전</th>
                            <th style={{width: "3%"}} rowSpan="2">출금후</th>
                            <th style={{width: "3%"}} rowSpan="2">출금액</th>
                            <th style={{width: "3%"}} rowSpan="2">신청시점진행중베팅금</th>
                            <th style={{width: "3%"}} rowSpan="2">은행명</th>
                            <th style={{width: "5%"}} rowSpan="2">계좌번호</th>
                            <th style={{width: "3%"}} rowSpan="2">예금주</th>
                            <th style={{width: "5%"}} rowSpan="2">도메인/IP</th>
                            <th style={{width: "3%"}} rowSpan="2">신청일시처리일시</th>
                            <th style={{width: "3%"}} rowSpan="2">마지막환전</th>
                            <th style={{width: "3%"}} rowSpan="2">계좌수정</th>
                            <th style={{width: "5%"}} rowSpan="2">상태</th>
                        </tr>
                        <tr>
                            {isEditing00 && (
                                <th>
                                    <table>
                                        <tr>
                                            {isEditing00 && (
                                                <th style={{width: "15%"}}>스포츠</th>
                                            )}
                                            {isEditing00 && (
                                                <th style={{width: "15%"}}>미니게임</th>
                                            )}
                                            {isEditing00 && (
                                                <th style={{width: "15%"}}>토큰</th>
                                            )}
                                            {isEditing00 && (
                                                <th style={{width: "15%"}}>인플레이</th>
                                            )}
                                            {isEditing00 && (
                                                <th style={{width: "15%"}}>Kg카지노</th>
                                            )}
                                        </tr>
                                    </table>
                                </th>
                            )}

                            {isEditing01 && (
                                <th>
                                    <table>
                                        <tr>
                                            {isEditing01 && (
                                                <th style={{width: "15%"}}>스포츠</th>
                                            )}
                                            {isEditing01 && (
                                                <th style={{width: "15%"}}>미니게임</th>
                                            )}
                                            {isEditing01 && (
                                                <th style={{width: "15%"}}>토큰</th>
                                            )}
                                            {isEditing01 && (
                                                <th style={{width: "15%"}}>인플레이</th>
                                            )}
                                            {isEditing01 && (
                                                <th style={{width: "15%"}}>Kg카지노</th>
                                            )}
                                        </tr>
                                    </table>
                                </th>
                            )}
                        </tr>
                        </thead>
                        {!withdrawList.length && (
                            <tbody key={withdrawList.no}>
                            <tr>
                                <td colSpan="32">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {withdrawList.map((withdrawList, index) => (
                            <tbody key={withdrawList.no}>
                            <tr>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <input className="form-check-input" type="checkbox" style={{cursor: "pointer"}}
                                           checked={selectedNos.filter((no) => no === withdrawList.no).length > 0}
                                           onChange={(e) => {checkHandler(e.currentTarget.checked, withdrawList.no, withdrawList.withdraw_state_cd)
                                           }}/>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' ), cursor: "pointer"} }
                                    onClick={() => {updateMarking(withdrawList.no, withdrawList.marking_yn)}}>
                                    {withdrawList.no}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <label className="form-check-label sm-label" htmlFor="flexRadioDefault3" style={{
                                        color: "white",
                                        backgroundColor: "red"
                                    }}>
                                        {withdrawList.site_cd}
                                    </label>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    {withdrawList.store_cd}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    {withdrawList.user_group_cd}
                                </td>
                                <td valign={"middle"} align={"center"} style={{backgroundColor:withdrawList.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: withdrawList.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}>
                                    <font color={withdrawList.font_color}>{withdrawList.nickname}</font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <font color="green">{withdrawList.user_gb_cd}</font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <font color="green">{withdrawList.pnt_gb_cd}</font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <font color="green">{withdrawList.bet_block_cd}</font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <font color="green">{withdrawList.rcpt_meth_cd}</font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <font color="red">{withdrawList.standard_deposit}</font>
                                    <br></br>
                                    <NumericFormat value={withdrawList.standard_deposit_amt} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <font color="red">{withdrawList.standard_withdraw}</font>
                                    <br></br>
                                    <NumericFormat value={withdrawList.standard_withdraw_amt} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td valign={"middle"} align={"center"} style={{backgroundColor:"yellow"}} >
                                    <font color="blue">{withdrawList.all_withdraw_ratio}%</font>
                                </td>
                                {isEditing00 && (
                                    <td valign={"middle"} align={"center"} style={{backgroundColor:"yellow"}} >

                                        <table className="commonT table table-bordered table-sm text-center">
                                            <tr>

                                                {isEditing00 && (
                                                    <td valign={"middle"} align={"center"}  style={{width: "15%"}}>
                                                        <font color="gray">{withdrawList.sports_withdraw_ratio}%</font>
                                                    </td>
                                                )}
                                                {isEditing00 && (
                                                    <td valign={"middle"} align={"center"}  style={{width: "15%"}}>
                                                        <font color="gray">{withdrawList.mini_withdraw_ratio}%</font>
                                                    </td>
                                                )}
                                                {isEditing00 && (
                                                    <td valign={"middle"} align={"center"} style={{width: "15%"}} >
                                                        <font color="gray">{withdrawList.token_withdraw_ratio}%</font>
                                                    </td>
                                                )}
                                                {isEditing00 && (
                                                    <td valign={"middle"} align={"center"} style={{width: "15%"}} >
                                                        <font color="gray">{withdrawList.inplay_withdraw_ratio}%</font>
                                                    </td>
                                                )}
                                                {isEditing00 && (
                                                    <td valign={"middle"} align={"center"}  style={{width: "15%"}}>
                                                        <font color="gray">{withdrawList.casino_withdraw_ratio}%</font>
                                                    </td>
                                                )}
                                            </tr>
                                        </table>
                                    </td>
                                )}

                                <td valign={"middle"} align={"center"} style={{backgroundColor:"yellow"}}  >
                                    <font color="blue">
                                        <NumericFormat value=
                                                           {
                                                               Math.round(
                                                                   withdrawList.sports_withdraw_ratio+
                                                                   withdrawList.mini_withdraw_ratio+
                                                                   withdrawList.token_withdraw_ratio+
                                                                   withdrawList.inplay_withdraw_ratio+
                                                                   withdrawList.casino_withdraw_ratio)
                                                           } displayType={'text'} thousandSeparator={true}/>%
                                    </font>
                                </td>
                                {isEditing01 && (
                                    <td valign={"middle"} align={"center"} style={{backgroundColor:"yellow"}} >

                                        <table className="commonT table table-bordered table-sm text-center">
                                            <tr>

                                                {isEditing01 && (
                                                    <td valign={"middle"} align={"center"}  style={{width: "15%"}}>
                                                        <font color="gray">{withdrawList.sports_withdraw_ratio}%</font>
                                                    </td>
                                                )}
                                                {isEditing01 && (
                                                    <td valign={"middle"} align={"center"}  style={{width: "15%"}}>
                                                        <font color="gray">{withdrawList.mini_withdraw_ratio}%</font>
                                                    </td>
                                                )}
                                                {isEditing01 && (
                                                    <td valign={"middle"} align={"center"} style={{width: "15%"}} >
                                                        <font color="gray">{withdrawList.token_withdraw_ratio}%</font>
                                                    </td>
                                                )}
                                                {isEditing01 && (
                                                    <td valign={"middle"} align={"center"} style={{width: "15%"}} >
                                                        <font color="gray">{withdrawList.inplay_withdraw_ratio}%</font>
                                                    </td>
                                                )}
                                                {isEditing01 && (
                                                    <td valign={"middle"} align={"center"}  style={{width: "15%"}}>
                                                        <font color="gray">{withdrawList.casino_withdraw_ratio}%</font>
                                                    </td>
                                                )}
                                            </tr>
                                        </table>
                                    </td>
                                )}

                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <font color="blue"><NumericFormat value={withdrawList.profit_amt} displayType={'text'} thousandSeparator={true}/></font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <font color="blue"><NumericFormat value={withdrawList.before_amt} displayType={'text'} thousandSeparator={true}/></font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <font color="blue"><NumericFormat value={withdrawList.result_amt} displayType={'text'} thousandSeparator={true}/></font>
                                </td>
                                <td valign={"middle"} align={"center"} style={{backgroundColor:"yellow"}} >
                                    <font color="red">-<NumericFormat value={withdrawList.withdraw_amt} displayType={'text'} thousandSeparator={true}/></font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <font color="blue"><NumericFormat value={withdrawList.progress_amt} displayType={'text'} thousandSeparator={true}/></font>
                                </td>
                                <td valign={"middle"} align={"center"} style={{backgroundColor:"yellow"}} >
                                    <font color="black">{withdrawList.bank}</font>
                                </td>
                                <td valign={"middle"} align={"center"} style={{backgroundColor:"yellow"}} >
                                    <font color="black">{withdrawList.account}</font>
                                </td>
                                <td valign={"middle"} align={"center"} style={{backgroundColor:"yellow"}} >
                                    <font color="black">{withdrawList.account_name}</font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <font color="black">{withdrawList.domain}</font>
                                    <br></br>
                                    <font color="blue">
                                        <a href={"https://www.ipaddress.com/ipv4/" + withdrawList.use_ip} target="_blank"><span style={{ color: "#1F68EE" }}>{withdrawList.use_ip}</span></a>
                                    </font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    {withdrawList.request_dt}
                                    <br></br>
                                    {withdrawList.complete_dt}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    {withdrawList.last_withdraw} 분전
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    {withdrawList.account_upd_dt}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:withdrawList.marking_yn == 1 ? 'lightcyan' : ( withdrawList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    {withdrawState(withdrawList.withdraw_state_cd, withdrawList.no)}
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>

                </div>

            </div>
        </div>

    )
}

export default WithdrawList;