import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {NumericFormat} from "react-number-format";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import MemberInformationSimpleModal from "../modal/user/simple/MemberInformationSimpleModal";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";
import {insertAdminLog} from "../../lib/api";
const DepositList = ({depositList, onRefresh, updateState, updateStateMulti, initialState, updateMarking}) => {

    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo);

    const [selectedNos, setSelectedNos] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [openId, setOpenId] = useState(null);
    const [xPos, setXPos] = useState(0.0);

    //그룹
    const [userGroupCd, setUserGroupCd] = useState('')
    const [userGroupList, setUserGroupList] = useState([]);



    const getCmmnCd = () => {
        if (userGroupList.length === 0) {
            axios.post('/partner/member/getGroupSetting', {
                cmmnCdId: '0000',
            }).then((res) => {
                setUserGroupList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])

    const handelClickOpen = (id, x) => {
        setOpenId(id)
        setXPos(x)
        setIsOpen(true)
    }


    const allCheckHandler = (e) => {
        if(depositList.length > 0 && e.currentTarget.value) {
            const checks = [];
            let total = 0;
            for(let i = 0; i < depositList.length; i++) {

                if(depositList[i].deposit_state_cd === 2 || depositList[i].deposit_state_cd === 3 || depositList[i].deposit_state_cd === 4)
                {
                    continue;
                }
                checks.push(depositList[i].no)
                total += depositList[i].deposit_amt
            }
            setSelectedNos(e.target.checked? checks : [])
        }
    }

    const checkHandler = (checked, no, state_cd) => {

        if (state_cd == 2 || state_cd == 3 || state_cd ==4)
        {
            return;
        }

        let selectedNos2 = []
        if(checked) {
            selectedNos2 = [...selectedNos, no]
            setSelectedNos([...selectedNos, no])
        }
        else {
            selectedNos2 = selectedNos.filter((id) => id !== no)
            setSelectedNos(selectedNos.filter((id) => id !== no))
        }
        let total = 0;
        for(let i = 0; i < selectedNos2.length; i++) {
            total += depositList.filter((id) => id.no === selectedNos2[i])[0]
        }
    }


    const depositState = (state, no) => {
        switch(state) {
            //대기
            case 0 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td valign={"middle"} align={"center"}>
                                    대기
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(2, [no])}}>입금처리</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(1, [no])}}>보류처리</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(3, [no])}}>취소처리</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //보류
            case 1 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td valign={"middle"} align={"center"}>
                                    보류
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(2, [no])}}>입금처리</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(3, [no])}}>취소처리</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //입금완료
            case 2 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td valign={"middle"} align={"center"}>
                                    완료
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button style={{ backgroundColor: "red", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "60px", marginRight: "5px"}} onClick={() => {updateState(4, [no])}}>입금취소</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //취소완료
            case 3 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td valign={"middle"} align={"center"}>
                                    취소
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            //완료->취소
            case 4 :
                return (
                    <div className="d-flex justify-content-center">
                        <table>
                            <tr>
                                <td valign={"middle"} align={"center"}>
                                    취소
                                </td>
                            </tr>
                        </table>
                    </div>
                )
            default : return state
        }
    }

    const depositForce = (state) => {
        switch(state) {
            //관리자 강제신청
            case 'Y' :
                return (
                    <label className="form-check-label sm-label" htmlFor="flexRadioDefault3" style={{
                        color: "yellow",
                        backgroundColor: "deeppink"
                    }}>
                        관리자강제신청
                    </label>
                )
            default :
                return ('')
        }
    }


    const userGroupCdChangeHandler = (e, id) => {
        // alert("e :: "+e.target.value);
        // alert("id :: "+id);
        axios.post('/admin/member/updateOneUserGroupCd', {
            userGroupCd: e.currentTarget.value,
            id: id,
            reg_id: userInfo.id,
            upd_id: userInfo.id,
            reg_ip: userInfo.ip,
            upd_ip: userInfo.ip
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }

    const allBlockYnChangeHandler = (e, id, value) => {
        // alert("id :: "+id);
        axios.post('/admin/member/updateOneAllBlockYn', {
            allBlockYn: (value === 'Y' ? 'N' : 'Y'),
            id: id,
            reg_id: userInfo.id,
            upd_id: userInfo.id,
            reg_ip: userInfo.ip,
            upd_ip: userInfo.ip
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }


    //입금자명 수정
    const depositNmChangeHandler = (e, no, state) => {


        if( state == 2 )
        {
            alert("이미 입금처리 되었습니다. 입금을 취소한 후 입금자명을 변경해 주세요");
            return;
        }
        if( state == 3 || state == 4 )
        {
            alert("이미 충전이 완료되었거나 취소된 건이라 입금자명을 수정할 수 없습니다.\n" +
                "금액 수정 후 바로 충전 버튼을 눌렀다면, 새로고침하셔서 충전된 금액을 확인해 주세요.");
            window.location.reload()
            return;
        }

        axios.post('/admin/depositList/updateOneDepositNm', {
            depositNm: e.currentTarget.value,
            no: no,
            reg_id: userInfo.id,
            upd_id: userInfo.id,
            reg_ip: userInfo.ip,
            upd_ip: userInfo.ip
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }



    const depositAmtChangeHandler = (e, no, state) => {

        if( e.currentTarget.value < 1 )
        {
            alert("금액이 0원 입니다. 금액을 확인한 후 변경해 주세요.");
            window.location.reload()
            return;
        }

        if( state == 2 )
        {
            alert("이미 입금처리 되었습니다. 입금을 취소한 후 금액을 변경해 주세요");
            return;
        }
        if( state == 3 || state == 4 )
        {
            alert("이미 충전이 완료되었거나 취소된 건이라 금액을 수정할 수 없습니다.\n" +
                "금액 수정 후 바로 충전 버튼을 눌렀다면, 새로고침하셔서 충전된 금액을 확인해 주세요.");
            window.location.reload()
            return;
        }

        axios.post('/admin/depositList/updateOneDepositAmt', {
            depositAmt: e.currentTarget.value,
            no: no,
            reg_id: userInfo.id,
            upd_id: userInfo.id,
            reg_ip: userInfo.ip,
            upd_ip: userInfo.ip
        })
            .then((res) => {
                if (res.data.isUpdated) {
                    //alert('복구완료')
                    onRefresh()
                } else {
                    alert('수정실패')
                }
            })
    }

    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }

    const convertMinutesToDaysHoursMinutes = (totalMinutes) => {
        if(totalMinutes === 0){
            return '';
        }
        const days = Math.floor(((totalMinutes / (60 *60 )) % 24 )) === 0 ? "" : Math.floor(((totalMinutes / (60 *60 )) % 24 ));
        const hours = Math.floor(((totalMinutes / (60 )) % 60 )) === 0 ? "" : Math.floor(((totalMinutes / (60 )) % 60 ));
        const minutes = Math.floor((totalMinutes) % 60 ) === 0 ? "" : Math.floor((totalMinutes) % 60 );
        const daysNm = days === "" ?"":"시간";
        const hourNm = hours === "" ?"":"분";
        const minutesNm = minutes === "" ?"":"초";

        return days+daysNm+hours+hourNm+minutes+minutesNm;
    };



    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={() => {updateStateMulti(2, selectedNos)}}>선택 항목 입금 처리</button>
                    <font color="gray">[전체 :<NumericFormat className="ms-1" value={initialState.countList.deposit_cnt} displayType={'text'} thousandSeparator={true}/>건 (<NumericFormat className="ms-1" value={initialState.countList.deposit_amt} displayType={'text'} thousandSeparator={true}/>원)]</font>
                    &nbsp;&nbsp;
                    <font color="red">[충전대기 :<NumericFormat className="ms-1" value={initialState.countList.state01_cnt} displayType={'text'} thousandSeparator={true}/>건 (<NumericFormat className="ms-1" value={initialState.countList.state01_amt} displayType={'text'} thousandSeparator={true}/>원)]</font>
                    &nbsp;&nbsp;
                    <font color="blue">[충전완료 :<NumericFormat className="ms-1" value={initialState.countList.state02_cnt} displayType={'text'} thousandSeparator={true}/>건 (<NumericFormat className="ms-1" value={initialState.countList.state02_amt} displayType={'text'} thousandSeparator={true}/>원)]</font>
                    &nbsp;&nbsp;
                    <font color="#006400">[충전보류 :<NumericFormat className="ms-1" value={initialState.countList.state04_cnt} displayType={'text'} thousandSeparator={true}/>건 (<NumericFormat className="ms-1" value={initialState.countList.state04_amt} displayType={'text'} thousandSeparator={true}/>원)]</font>
                    &nbsp;&nbsp;
                    <font color="gray">[충전취소 :<NumericFormat className="ms-1" value={initialState.countList.state03_cnt} displayType={'text'} thousandSeparator={true}/>건 (<NumericFormat className="ms-1" value={initialState.countList.state03_amt} displayType={'text'} thousandSeparator={true}/>원)]</font>
                </div>
                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    {isOpen && (<MemberInformationSimpleModal setIsOpen={setIsOpen} isOpen={isOpen} userId={openId} xPos={xPos} />)}
                    <table className="commonT table table-bordered table-sm text-center">
                        <thead >
                        <tr>
                            <th style={{width: "2%"}}>
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" style={{cursor: "pointer"}}
                                       checked={depositList.length === selectedNos.length} onChange={allCheckHandler}
                                />
                            </th>
                            <th style={{width: "2%"}}>순서</th>
                            <th style={{width: "2%"}}>고유번호</th>
                            <th style={{width: "3%"}}>사이트코드</th>
                            <th style={{width: "3%"}}>소속</th>
                            <th style={{width: "3%"}}>가맹점명</th>
                            <th style={{width: "3%"}}>도메인</th>
                            <th style={{width: "3%"}}>그룹</th>
                            <th style={{width: "3%"}}>아이디</th>
                            <th style={{width: "3%"}}>닉네임</th>
                            <th style={{width: "3%"}}>회원등급</th>
                            <th style={{width: "3%"}}>포인트등급</th>
                            <th style={{width: "3%"}}>전체베팅차단</th>
                            <th style={{width: "3%"}}>기준일충전</th>
                            <th style={{width: "3%"}}>기준일환전</th>
                            <th style={{width: "3%"}}>가입입금자명</th>
                            <th style={{width: "3%"}}>입금자명</th>
                            <th style={{width: "3%"}}>전용계좌</th>
                            <th style={{width: "3%"}}>마지막충전</th>
                            <th style={{width: "3%"}}>당일첫충전</th>
                            <th style={{width: "3%"}}>충전금액</th>
                            <th style={{width: "3%"}}>현재입금방식</th>
                            <th style={{width: "3%"}}>코인</th>
                            <th style={{width: "3%"}}>분류</th>
                            <th style={{width: "3%"}}>IP</th>
                            <th style={{width: "3%"}}>신청일</th>
                            <th style={{width: "3%"}}>처리일</th>
                            <th style={{width: "3%"}}>포인트지급</th>
                            <th style={{width: "4%"}}>상태</th>
                        </tr>
                        </thead>
                        {!depositList.length && (
                            <tbody key={depositList.no}>
                            <tr>
                                <td colSpan="30">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {depositList.map((depositList, index) => (
                            <tbody key={depositList.no}>
                            <tr>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <input className="form-check-input" type="checkbox" style={{cursor: "pointer"}}
                                           checked={selectedNos.filter((no) => no === depositList.no).length > 0}
                                           onChange={(e) => {checkHandler(e.currentTarget.checked, depositList.no, depositList.deposit_state_cd)
                                           }}/>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), cursor: "pointer"}}
                                    onClick={() => {updateMarking(depositList.no, depositList.marking_yn)}}>
                                    {initialState.page == 1 ? initialState.totalCount-index : initialState.totalCount-( ((initialState.page-1) * initialState.pageSize)+index+1 )+1 }
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    {depositList.no}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    <label className="form-check-label sm-label" htmlFor="flexRadioDefault3" style={{
                                        color: "white",
                                        backgroundColor: "red"
                                    }}>
                                        {depositList.site_cd}
                                    </label>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    {depositList.store_cd}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    {depositList.mrch_cd}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    <a style={{cursor: 'pointer'}}  onClick={(e) => {
                                        window.open("https://"+depositList.domain,"_blank", "popup")
                                    }} >
                                        {depositList.domain}
                                    </a>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    <select
                                        className="form-select sm-select"
                                        style={{
                                            width: "90px"
                                        }}
                                        onChange={(e) => userGroupCdChangeHandler(e, depositList.id)}
                                        value={depositList.user_group_id}
                                    >
                                        <option key={""} value={""}>[그룹없음]</option>
                                        {userGroupList.map((item, index) => (
                                            <option key={item.no} value={item.no}>{item.group_nm}</option>
                                        ))}
                                    </select>
                                </td>

                                <td valign={"middle"} align={"center"} style={{backgroundColor:depositList.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: depositList.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}>
                                    <font color={depositList.font_color}>{depositList.id}</font>
                                </td>
                                <td valign={"middle"} align={"center"} style={{backgroundColor:depositList.bg_color, cursor: "pointer"}} onClick={(e) => {
                                    dispatch(openUserSimpleInfo({
                                        isOpen: true,
                                        openId: depositList.id,
                                        xPos: e.currentTarget.getBoundingClientRect().x + e.currentTarget.offsetWidth
                                    }))
                                }}>
                                    <font color={depositList.font_color}>{depositList.nickname}</font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    <font color="green">{depositList.user_gb_cd}</font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    {depositList.pnt_gd_cd}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    <a style={{ cursor: "pointer" }}  onClick={(e) => allBlockYnChangeHandler(e, depositList.id, depositList.all_block_id)}>
                                        <font color={depositList.all_block_id === 'Y' ? 'green' : 'red'}
                                              size={depositList.all_block_id === 'Y' ? 1 : 2}
                                        >
                                            {depositList.all_block_yn}
                                        </font>
                                    </a>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    <font color="red">{depositList.standard_deposit}</font><br></br>
                                    <NumericFormat value={depositList.standard_deposit_amt} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    <font color="red">{depositList.standard_withdraw}</font><br></br>
                                    <NumericFormat value={depositList.standard_withdraw_amt} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    {depositList.reg_deposit_nm}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    <input style={{
                                        width: "70px"
                                    }} type="text" className="form-control" id="deposit_nm" placeholder={depositList.deposit_nm}
                                           onBlur={(e) => depositNmChangeHandler(e, depositList.no, depositList.deposit_state_cd)}
                                    />
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    {depositList.exclusive_account_cd}<br></br>
                                    {depositList.exclusive_account_no}<br></br>
                                    {depositList.exclusive_account_nm}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    {convertMinutesToDaysHoursMinutes(Math.abs(depositList.last_deposit))}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    {(depositList.day_deposit_yn === 'Y' ? '[당일 첫 충전]' : '-')}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    <input style={{
                                        width: "70px"
                                    }} type="text" className="form-control" id="deposit_amt" placeholder={addComma(depositList.deposit_amt)}
                                           onBlur={(e) => depositAmtChangeHandler(e, depositList.no, depositList.deposit_state_cd)}
                                    />
                                    {depositForce(depositList.force_yn)}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    <font color="green">{depositList.rcpt_meth_cd}</font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    <font color="green">{depositList.coin}</font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    <font color="green">{depositList.game_type_cd}</font>
                                    {depositForce(depositList.force_yn)}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' )}}
                                >
                                    <font color="blue">
                                        <a href={"https://www.ipaddress.com/ipv4/" + depositList.use_ip} target="_blank"><span style={{ color: "#1F68EE" }}>{depositList.use_ip}</span></a>
                                    </font>
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    {depositList.request_dt}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    {depositList.complete_dt}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    {depositList.result_point}
                                </td>
                                <td valign={"middle"} align={"center"}
                                    style={{backgroundColor:depositList.marking_yn == 1 ? 'lightcyan' : ( depositList.marking_yn == 2 ? 'orange' : 'white' ), }}
                                >
                                    {depositState(depositList.deposit_state_cd, depositList.no)}
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>

                </div>

            </div>
        </div>

    )
}

export default DepositList;