import {createSlice} from "@reduxjs/toolkit"
import {PURGE} from "redux-persist";

const initialState = {
    isLoading: false,
    userNo: -1,
    id: null,
    isLogin: false,
    level: null,
    error: null,
    ip: null,
    xPos: 0,
    openId: null,
    isOpen: false,
}

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        login(state, action) {
            state.userNo = action.payload.userNo;
            state.id = action.payload.id;
        },
        setUserInfo(state, action) {
            if(action.payload.isLogin) {
                state.isLogin = action.payload.isLogin;
                state.userNo = action.payload.userNo;
                state.id = action.payload.id;
                state.level = action.payload.level;
                state.ip = action.payload.ip;
            }
            else {
                state.isLogin = action.payload.isLogin;
            }

        },
        failedLogin(state, action) {
            state.isLoading = false;
            state.error = action.payload
        },
        openUserSimpleInfo(state, action) {
            state.isOpen = action.payload.isOpen
            state.openId = action.payload.openId
            state.xPos = action.payload.xPos
        },
        closeUserSimpleInfo(state, action) {
            state.isOpen = false
            state.openId = null
            state.xPos = 0

        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => initialState);
    }
})

export const { login, setUserInfo, failedLogin , openUserSimpleInfo, closeUserSimpleInfo} = userInfoSlice.actions
export default userInfoSlice.reducer