import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import moment from 'moment';
import {setDisableYn, setMemberYnText, setMemberYnTextColor} from "../../../../redux/reducers/user/BlackMemberAddModalReducer";

const MemberInformationDetailModal = (props) => {


    return (
        <div className="common">
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table width={"99%"}>
                    <tr>
                        <td align={"right"}>
                            <button style={{
                                backgroundColor: "#5dc434",
                                border: "none",
                                color: "white",
                                fontSize: "12px",
                                height: "20px",
                                width: "fit-content",
                                marginRight: "10px"
                            }}>회원 수정
                            </button>

                        </td>
                    </tr>
                </table>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex"
            }}>
                <table className="commonT table table-bordered table-sm text-center">
                    <thead>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <b>게임 종류별 개인 <font color={"red"}>1폴더</font> 롤링 설정</b>
                        </td>
                        <td colSpan="3" align={"left"}>
                            <b>게임 종류별 개인 <font color={"red"}>2폴더</font> 롤링 설정</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                        </td>
                        <td colSpan="3" align={"left"}>
                            <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "lightgray"}}>종류</td>
                        <td style={{width: "15%",backgroundColor: "lightgray"}}>1폴더 롤링 비율(본인)</td>
                        <td style={{width: "25%",backgroundColor: "lightgray"}}>1폴더 롤링 비율(하부 추천인)
                            <br></br>* 하부 추천인이 베팅할 때 본인에게 쌓일 롤링 비율</td>
                        <td style={{width: "10%",backgroundColor: "lightgray"}}>종류</td>
                        <td style={{width: "15%",backgroundColor: "lightgray"}}>1폴더 롤링 비율(본인)</td>
                        <td style={{width: "25%",backgroundColor: "lightgray"}}>2폴더 롤링 비율(하부 추천인)
                            <br></br>* 하부 추천인이 베팅할 때 본인에게 쌓일 롤링 비율</td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}><font color={"red"}>스포츠</font></td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}><font color={"red"}>스포츠</font></td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}><font color={"red"}>미니게임</font></td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}><font color={"red"}>미니게임</font></td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상 농구</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상 농구</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상개경주</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상개경주</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상경마</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상경마</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상축구</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상축구</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>넥스트 링</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>넥스트 링</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>넥스트 바카라</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>넥스트 바카라</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>로투스바카라1</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>로투스바카라1</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>로투스홀짝</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>로투스홀짝</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>스페셜</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>스페셜</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>스피드키노</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>스피드키노</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>실시간</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>실시간</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>일반게임</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>일반게임</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>크로스</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>크로스</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>키노사다리</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>키노사다리</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워볼</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워볼</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워볼 조합</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워볼 조합</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워사다리</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워사다리</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워사다리 조합</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워사다리 조합</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>토큰</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>토큰</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                        </td>
                        <td colSpan="3" align={"left"}>
                            <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={"6"}><br></br></td>
                    </tr>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <b>게임 종류별 개인 <font color={"red"}>3폴더</font> 롤링 설정</b>
                        </td>
                        <td colSpan="3" align={"left"}>
                            <b>게임 종류별 개인 <font color={"red"}>4폴더 이상</font> 롤링 설정</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                        </td>
                        <td colSpan="3" align={"left"}>
                            <font color={"red"}>* 스포츠/미니게임과 각 세부 제한들은 -1이 아닌 값을 먼저 따르고, 둘 다 -1이 아니라면 작은 값을 따름.</font>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "lightgray"}}>종류</td>
                        <td style={{width: "15%",backgroundColor: "lightgray"}}>1폴더 롤링 비율(본인)</td>
                        <td style={{width: "25%",backgroundColor: "lightgray"}}>1폴더 롤링 비율(하부 추천인)
                            <br></br>* 하부 추천인이 베팅할 때 본인에게 쌓일 롤링 비율</td>
                        <td style={{width: "10%",backgroundColor: "lightgray"}}>종류</td>
                        <td style={{width: "15%",backgroundColor: "lightgray"}}>1폴더 롤링 비율(본인)</td>
                        <td style={{width: "25%",backgroundColor: "lightgray"}}>2폴더 롤링 비율(하부 추천인)
                            <br></br>* 하부 추천인이 베팅할 때 본인에게 쌓일 롤링 비율</td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}><font color={"red"}>스포츠</font></td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}><font color={"red"}>스포츠</font></td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}><font color={"red"}>미니게임</font></td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}><font color={"red"}>미니게임</font></td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상 농구</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상 농구</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상개경주</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상개경주</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상경마</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상경마</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상축구</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>가상축구</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>넥스트 링</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>넥스트 링</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>넥스트 바카라</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>넥스트 바카라</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>로투스바카라1</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>로투스바카라1</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>로투스홀짝</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>로투스홀짝</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>스페셜</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>스페셜</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>스피드키노</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>스피드키노</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>실시간</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>실시간</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>일반게임</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>일반게임</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>크로스</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>크로스</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>키노사다리</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>키노사다리</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워볼</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워볼</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워볼 조합</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워볼 조합</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워사다리</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워사다리</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워사다리 조합</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>파워사다리 조합</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>토큰</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "10%",backgroundColor: "white"}} align={"left"}>토큰</td>
                        <td style={{width: "15%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                        <td style={{width: "25%",backgroundColor: "white"}} align={"left"}>
                            <input className="form-check-input sm-input" type="text"
                                   style={{width: "80px"}} placeholder="-1.00" /> %
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3" align={"left"}>
                            <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                        </td>
                        <td colSpan="3" align={"left"}>
                            <font color={"red"}>* '-1'로 설정하면, 기본 제한을 따름</font>
                        </td>
                    </tr>

                    </thead>
                </table>
            </div>
        </div>




    )
}
export default MemberInformationDetailModal;