import React from "react";
const HomePage = () => {
    return (
        <div className="common">
            <div className="mb-3">
                <h6><strong>홈</strong></h6>
            </div>
            <div><p style={{ fontSize: "12px" }}>＊<strong>자산정보</strong> (충전과 환전은 처리일 기준) : 조회 기준일시: 23/09/08 09:41</p></div>
            <div className="homet1">
                <table className="table table-bordered table-sm text-center align-middle">
                    <thead>
                    <tr>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>회원보유 게임머니<br/>(유료회원/무료회원)</td>
                        <td style={{ border: "1px solid #D7D4CF" ,verticalAlign: "middle"}}><span style={{ color: "#1F68EE" }}>398,596</span>/<span style={{ color: "#1F68EE" }}>0</span></td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF" }}>회원보유 포인트<br/>(유료회원/무료회원)</td>
                        <td style={{ border: "1px solid #D7D4CF" ,verticalAlign: "middle"}}><span style={{ color: "#1F68EE" }}>141,530</span>/<span style={{ color: "#1F68EE" }}>0</span></td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>09/08(금) 충전금</td>
                        <td style={{ border: "1px solid #D7D4CF" ,verticalAlign: "middle"}}><span style={{ color: "#1F68EE" }}>1,890,000</span>/<span style={{ color: "#1F68EE" }}>14</span>건</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>09/08(금)환전금</td>
                        <td style={{ border: "1px solid #D7D4CF" ,verticalAlign: "middle"}}><span style={{ color: "#FF0000" }}>-880,000</span><span style={{ color: "#000000" }}>/</span><span style={{ color: "#1F68EE" }}>3</span>건</td>
                    </tr>
                    <tr>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>진행중인 베팅금</td>
                        <td style={{ border: "1px solid #D7D4CF"}}><span style={{ color: "#1F68EE" }}>875,566</span>/<span style={{ color: "#1F68EE" }}>10</span>건</td>
                        <td style={{ backgroundColor: "#E6E6E6",border: "1px solid #D7D4CF" }}>일반게임 진행중 베팅</td>
                        <td style={{ border: "1px solid #D7D4CF" }}>/건</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>지동등록게임 진행중 베팅</td>
                        <td style={{ border: "1px solid #D7D4CF"}}>/건</td>
                        <td style={{ border: "1px solid #D7D4CF"}}></td>
                        <td style={{ border: "1px solid #D7D4CF"}}></td>
                    </tr>
                    </thead>
                </table>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <div style={{ flex: "1", marginRight: "5px" ,justifyContent: "flex-end" }}>
                    <p style={{ fontSize: "12px", margin: "0" }}>최근 10일 충전현황 (본사누적 <span style={{ color: "#1F68EE" }}>0</span>원,파트너사누적 <span style={{ color: "#1F68EE" }}>52,855,000</span>원,전체누적 <span style={{ color: "#1F68EE" }}>52,855,000</span>원)</p>
                    <p style={{ fontSize: "12px", margin: "0" }}>최근 10일 환전현황 (본사누적 <span style={{ color: "#1F68EE" }}>0</span>원,파트너사누적 <span style={{ color: "#1F68EE" }}>49,410,000</span>원,전체누적 <span style={{ color: "#1F68EE" }}>49,410,000</span>원)</p>
                    <p style={{ fontSize: "12px", margin: "0" }}>최근 10일 수익현황 (본사누적 <span style={{ color: "#1F68EE" }}>0</span>원,파트너사누적 <span style={{ color: "#1F68EE" }}>3,445,000</span>원,전체누적 <span style={{ color: "#1F68EE" }}>3,445,000</span>원)</p>
                </div>
                <div style={{ flex: "1", display: "flex", justifyContent: "flex-end" }}>
                    <button style={{ marginLeft: "5px" ,position: "relative", top: "-10px" , backgroundColor: "#5DC434", border: "none", color: "white" ,fontSize: "12px"}}>이번 달</button>
                    <button style={{ marginLeft: "5px" ,position: "relative", top: "-10px" , backgroundColor: "#5DC434", border: "none", color: "white" ,fontSize: "12px"}}>한달전</button>
                    <button style={{ marginLeft: "5px" ,position: "relative", top: "-10px" , backgroundColor: "#5DC434", border: "none", color: "white" ,fontSize: "12px"}}>두달전</button>
                    <button style={{ marginLeft: "5px" ,position: "relative", top: "-10px" , backgroundColor: "#5DC434", border: "none", color: "white" ,fontSize: "12px"}}>세달전</button>
                    <button style={{ marginLeft: "40px" ,position: "relative", top: "-10px" , backgroundColor: "#5DC434", border: "none", color: "white" ,fontSize: "12px"}}>1~10</button>
                    <button style={{ marginLeft: "5px" ,position: "relative", top: "-10px" , backgroundColor: "#5DC434", border: "none", color: "white" ,fontSize: "12px"}}>11~20</button>
                    <button style={{ marginLeft: "5px" ,position: "relative", top: "-10px" , backgroundColor: "#5DC434", border: "none", color: "white" ,fontSize: "12px"}}>21~말일</button>
                    <button style={{ marginLeft: "5px" ,position: "relative", top: "-10px" , backgroundColor: "#5DC434", border: "none", color: "white" ,fontSize: "12px"}}>1~15</button>
                    <button style={{ marginLeft: "5px" ,position: "relative", top: "-10px" , backgroundColor: "#5DC434", border: "none", color: "white" ,fontSize: "12px"}}>16~말일</button>
                </div>
            </div>
            <div className="homet1">{/*2번테이블*/}
                <table className="table table-bordered table-sm text-center align-middle calculate">
                    <thead>
                    <tr>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}} rowSpan="2">날짜</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}} colSpan="5">본사</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}} colSpan="5">파트너</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}} colSpan="5">토탈</td>
                    </tr>
                    <tr>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>충전(<span style={{ color: "#1F68EE" }}>0</span>)<br/>평균0건</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>환전(<span style={{ color: "#1F68EE" }}>0</span>)<br/>평균0건</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>수익(<span style={{ color: "#1F68EE" }}>0</span>)<br/>평균<span style={{ color: "#1F68EE" }}>0</span></td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>가입/삭제/가입뱃/실벳</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>입id/환id</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>충전(<span style={{ color: "#1F68EE" }}>52,855,000</span>)<br/>평균 36건</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>환전(<span style={{ color: "#1F68EE" }}>49,410,000</span>)<br/>평균 11건</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>수익(<span style={{ color: "#1F68EE" }}>3,445,000</span>)<br/>평균 <span style={{ color: "#1F68EE" }}>382,778</span></td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>가입/삭제/가입뱃/실벳</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>입id/환id</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>충전(<span style={{ color: "#1F68EE" }}>52,855,000</span>)<br/>평균 36건</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>환전(<span style={{ color: "#1F68EE" }}>49,410,000</span>)<br/>평균 11건</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>수익(<span style={{ color: "#1F68EE" }}>3,445,000</span>)<br/>평균 <span style={{ color: "#1F68EE" }}>382,778</span></td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>가입/삭제/가입뱃/실벳</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>입id/환id</td>
                    </tr>
                    </thead>
                        <tbody>
                        <tr>
                            <td>09/08(금)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                        </tr>
                        <tr>
                            <td>09/07(목)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                        </tr>
                        <tr>
                            <td>09/06(수)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                        </tr>
                        <tr>
                            <td>09/05(화)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>4,020,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>6,880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#E63841" }}>-2,860,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                            <td><span style={{ color: "#1F68EE" }}>4,020,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>6,880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#E63841" }}>-2,860,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                        </tr>
                        <tr>
                            <td>09/04(월)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                        </tr>
                        <tr>
                            <td>09/03(일)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                        </tr>
                        <tr>
                            <td>09/02(토)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                        </tr>
                        <tr>
                            <td>09/01(금)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                        </tr>
                        <tr>
                            <td>08/31(목)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                        </tr>
                        <tr>
                            <td>08/30(수)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span> / 0 건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,890,000</span> / 14건</td>
                            <td><span style={{ color: "#1F68EE" }}>880,000</span> / 3건</td>
                            <td style={{ backgroundColor: "#FDFDE7" }}><span style={{ color: "#1F68EE" }}>1,010,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>0</span> / <span style={{ color: "#1F68EE" }}>15</span></td>
                            <td style={{ backgroundColor: "#E7FDEC" }}><span style={{ color: "#1F68EE" }}>13</span> / <span style={{ color: "#1F68EE" }}>3</span></td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: "#FDFDE7" }}>총10일</td>
                            <td style={{ backgroundColor: "#FDFDE7" }} colSpan="5">환전율 0.00%</td>
                            <td style={{ backgroundColor: "#FDFDE7" }} colSpan="5">환전율 93.48%</td>
                            <td style={{ backgroundColor: "#FDFDE7" }} colSpan="5">환전율 93.48%</td>
                        </tr>
                        </tbody>
                </table>
            </div>
            <div className="homet1">{/*3번테이블*/}
                <table className="table table-bordered table-sm text-center align-middle calculate">
                    <thead>
                    <tr>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}} rowSpan="3">날짜</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}} colSpan="10">본사</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}} colSpan="10">파트너</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}} colSpan="10">토탈</td>
                    </tr>
                    <tr>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}} colSpan="2">일반베팅</td>
                        <td style={{ backgroundColor: "#D7FFBE" ,border: "1px solid #D7D4CF"}} colSpan="2">Kgon카지노</td>
                        <td style={{ backgroundColor: "#FFCCE6" ,border: "1px solid #D7D4CF"}} colSpan="2">인플레이</td>
                        <td style={{ backgroundColor: "#E8FB9D" ,border: "1px solid #D7D4CF"}} colSpan="2">토큰</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF" ,verticalAlign: "middle"}} rowSpan="2">포인트<br/>지급<br/><span style={{ color: "#1F68EE" }}>0</span></td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF" ,verticalAlign: "middle"}} rowSpan="2">총마진<br/><span style={{ color: "#1F68EE" }}>0</span></td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}} colSpan="2">일반베팅</td>
                        <td style={{ backgroundColor: "#D7FFBE" ,border: "1px solid #D7D4CF"}} colSpan="2">Kgon카지노</td>
                        <td style={{ backgroundColor: "#FFCCE6" ,border: "1px solid #D7D4CF"}} colSpan="2">인플레이</td>
                        <td style={{ backgroundColor: "#E8FB9D" ,border: "1px solid #D7D4CF"}} colSpan="2">토큰</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF" ,verticalAlign: "middle"}} rowSpan="2">포인트<br/>지급<br/><span style={{ color: "#E63841" }}>-2,000</span></td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF" ,verticalAlign: "middle"}} rowSpan="2">총마진<br/><span style={{ color: "#1F68EE" }}>100,000</span></td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}} colSpan="2">일반베팅</td>
                        <td style={{ backgroundColor: "#D7FFBE" ,border: "1px solid #D7D4CF"}} colSpan="2">Kgon카지노</td>
                        <td style={{ backgroundColor: "#FFCCE6" ,border: "1px solid #D7D4CF"}} colSpan="2">인플레이</td>
                        <td style={{ backgroundColor: "#E8FB9D" ,border: "1px solid #D7D4CF"}} colSpan="2">토큰</td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF" ,verticalAlign: "middle"}} rowSpan="2">포인트<br/>지급<br/><span style={{ color: "#E63841" }}>-2,000</span></td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF" ,verticalAlign: "middle"}} rowSpan="2">전체마진<br/><span style={{ color: "#1F68EE" }}>100,000</span></td>
                    </tr>
                    <tr>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>베팅<br/><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>마진<br/><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        <td style={{ backgroundColor: "#D7FFBE" ,border: "1px solid #D7D4CF"}}>베팅<br/><span style={{ color: "#1F68EE" }}>2,000</span></td>
                        <td style={{ backgroundColor: "#D7FFBE" ,border: "1px solid #D7D4CF"}}>마진<br/><span style={{ color: "#1F68EE" }}>2,000</span></td>
                        <td style={{ backgroundColor: "#FFCCE6" ,border: "1px solid #D7D4CF"}}>베팅<br/><span style={{ color: "#1F68EE" }}>3,000</span></td>
                        <td style={{ backgroundColor: "#FFCCE6" ,border: "1px solid #D7D4CF"}}>마진<br/><span style={{ color: "#1F68EE" }}>3,000</span></td>
                        <td style={{ backgroundColor: "#E8FB9D" ,border: "1px solid #D7D4CF"}}>베팅<br/><span style={{ color: "#1F68EE" }}>0</span></td>
                        <td style={{ backgroundColor: "#E8FB9D" ,border: "1px solid #D7D4CF"}}>마진<br/><span style={{ color: "#1F68EE" }}>0</span></td>

                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>베팅<br/><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>마진<br/><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        <td style={{ backgroundColor: "#D7FFBE" ,border: "1px solid #D7D4CF"}}>베팅<br/><span style={{ color: "#1F68EE" }}>2,000</span></td>
                        <td style={{ backgroundColor: "#D7FFBE" ,border: "1px solid #D7D4CF"}}>마진<br/><span style={{ color: "#1F68EE" }}>2,000</span></td>
                        <td style={{ backgroundColor: "#FFCCE6" ,border: "1px solid #D7D4CF"}}>베팅<br/><span style={{ color: "#1F68EE" }}>3,000</span></td>
                        <td style={{ backgroundColor: "#FFCCE6" ,border: "1px solid #D7D4CF"}}>마진<br/><span style={{ color: "#1F68EE" }}>3,000</span></td>
                        <td style={{ backgroundColor: "#E8FB9D" ,border: "1px solid #D7D4CF"}}>베팅<br/><span style={{ color: "#1F68EE" }}>0</span></td>
                        <td style={{ backgroundColor: "#E8FB9D" ,border: "1px solid #D7D4CF"}}>마진<br/><span style={{ color: "#1F68EE" }}>0</span></td>

                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>베팅<br/><span style={{ color: "#1F68EE" }}>10,000</span></td>
                        <td style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF"}}>마진<br/><span style={{ color: "#1F68EE" }}>10,000</span></td>
                        <td style={{ backgroundColor: "#D7FFBE" ,border: "1px solid #D7D4CF"}}>베팅<br/><span style={{ color: "#1F68EE" }}>20,000</span></td>
                        <td style={{ backgroundColor: "#D7FFBE" ,border: "1px solid #D7D4CF"}}>마진<br/><span style={{ color: "#1F68EE" }}>20,000</span></td>
                        <td style={{ backgroundColor: "#FFCCE6" ,border: "1px solid #D7D4CF"}}>베팅<br/><span style={{ color: "#1F68EE" }}>30,000</span></td>
                        <td style={{ backgroundColor: "#FFCCE6" ,border: "1px solid #D7D4CF"}}>마진<br/><span style={{ color: "#1F68EE" }}>30,000</span></td>
                        <td style={{ backgroundColor: "#E8FB9D" ,border: "1px solid #D7D4CF"}}>베팅<br/><span style={{ color: "#1F68EE" }}>0</span></td>
                        <td style={{ backgroundColor: "#E8FB9D" ,border: "1px solid #D7D4CF"}}>마진<br/><span style={{ color: "#1F68EE" }}>0</span></td>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>09/08(금)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#E63841" }}>-100</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        </tr>
                        <tr>
                            <td>09/07(목)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#E63841" }}>-100</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        </tr>
                        <tr>
                            <td>09/06(수)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#E63841" }}>-100</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        </tr>
                        <tr>
                            <td>09/05(화)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#E63841" }}>-100</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        </tr>
                        <tr>
                            <td>09/04(월)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#E63841" }}>-100</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        </tr>
                        <tr>
                            <td>09/03(일)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#E63841" }}>-100</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        </tr>
                        <tr>
                            <td>09/02(토)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#E63841" }}>-100</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        </tr>
                        <tr>
                            <td>09/01(금)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#E63841" }}>-100</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        </tr>
                        <tr>
                            <td>08/31(목)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#E63841" }}>-100</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        </tr>
                        <tr>
                            <td>08/30(수)</td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>2,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>3,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>0</span></td>
                            <td><span style={{ color: "#E63841" }}>-100</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#E7FDEC"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FFE8F8"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td style={{ backgroundColor: "#FAFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                            <td><span style={{ color: "#E63841" }}>-1,000</span></td>
                            <td style={{ backgroundColor: "#FDFDE7"}}><span style={{ color: "#1F68EE" }}>1,000</span></td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: "#FDFDE7" }}>총10일</td>
                            <td style={{ backgroundColor: "#FDFDE7" }} colSpan="30"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    )
}
export default HomePage;