import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination2";
import ExchangeRequestComponents from "../../components/exchange/ExchangeRequestComponents";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {ArrowsExpand} from "react-bootstrap-icons";
import {NumericFormat} from "react-number-format";
import {insertAdminLog} from "../../lib/api";
import {getExchangeRequest} from "../../redux/reducers/exchange/exchangeRequest";
import {getRtmSttlItm} from "../../redux/reducers/rtmSttl/rtmSttlItm";

const ExchangeRequestContainer = ({userId}) => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const userInfo = useSelector((state) => state.userInfo)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.exchangeRequest)
    const userState = useSelector((state) => state.userInfoCombine)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [userGbCd, setUserGbCd] = useState("");
    const [dateType, setDateType] = useState(0);

    const [userGbList, setUserGbList] = useState([]);
    const [selectedNos, setSelectedNos] = useState([]);
    const [withdrawAmt, setWithdrawAmt] = useState(0);

    const periodOption = [
        {value: "", name: "전체"},
        {value: '1', name: "24시간 이내"},
        {value: '2', name: "일주일 이내"},
        {value: '3', name: "30일 이내"},
        {value: '4', name: "60일 이내"},
        {value: '5', name: "90일 이내"},
        {value: '6', name: "180일 이내"},
    ]
    const gbOption = [
        {value: "", name: "전체"},
        {value: '1', name: "충/환전"},
        {value: '2', name: "일반게임"},
        {value: '3', name: "미니게임"},
        {value: '4', name: "토큰게임"},
        {value: '5', name: "Inplay게임"},
        {value: '6', name: "조정/전환"},
    ]
    const pageOption = [
        {value: 20, name: "20개씩"},
        {value: 50, name: "50개씩"},
        {value: 100, name: "100개씩"},
        {value: 200, name: "200개씩"},
        {value: 999, name: "모두"},
    ]

    useEffect(() => {
        dispatch(getExchangeRequest({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: (userId && userId !== "")? '2': initialState.searchGbCd,
            selectedNos: selectedNos,
            userId: userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getExchangeRequest({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            selectedNos: selectedNos,
            userId: userState.userId,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const withdrawAmtChangeHandler = (e) => {
        setWithdrawAmt(e.currentTarget.value);
        let price = e.target.value;
        price = Number(price.replaceAll(',', ''));
        if(isNaN(price)) {
            setWithdrawAmt(0);
        } else {
            setWithdrawAmt(price.toLocaleString('ko-KR'));
        }
    }

    const updateState = () => {
        if (window.confirm("환전신청 하시겠습니까?"))
        {

            let price = withdrawAmt;
            if( price != null && parseInt(price) > 0 )
            {
                price = Number(withdrawAmt.replaceAll(',', ''));
            }
            else
            {
                price = 0;
            }

            axios.post('/partner/exchange/saveWithdraw', {
                adminNo: userInfo.no,
                adminId: userInfo.id,
                adminIp: userInfo.ip,
                withdrawAmt: price,
                bank_cd: initialState.countList.bank,
                account: initialState.countList.account,
                account_name: initialState.countList.account_name
            }).then((res) => {
                alert(res.data.isSaved? "환전신청완료" : "환전신청실패")
                //getAllList()
                window.location.reload();
                //onRefresh();
                //window.location.href = "/money/withdraw_history"
            })




        }
    }



    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">총판/가맹 정산금 환전 신청</font>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray"
                }}>
                    <div className="container-fluid m-1 p-0" style={{
                        display: "flex"
                    }}>
                        <div className="input-group me-1" style={{
                            display: "flex",
                            width: "500px"
                        }}>
                            <font size="4" color="#228b22">정산 아이디 : {initialState.countList.org_nm} [{initialState.countList.nm}] ({initialState.countList.id})</font>
                        </div>
                    </div>
                    <div className="container-fluid m-1 p-0" style={{
                        display: "flex"
                    }}>
                        <div className="input-group me-1" style={{
                            display: "flex",
                            width: "700px"
                        }}>
                            <b><font size="5" color="#228b22">정산금 잔액 : </font><font size="5" color="blue">
                                <NumericFormat className="ms-1" value={initialState.countList.money_amt} displayType={'text'} thousandSeparator={true}/>
                                원</font></b>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray"
                }}>
                    <div className="container-fluid m-1 p-0" style={{
                        display: "flex"
                    }}>
                        <div className="input-group me-1" style={{
                            display: "flex",
                            width: "700px"
                        }}>
                            <font size="4" color="#228b22">환전 계좌 : {initialState.countList.account} [{initialState.countList.bank}]</font>
                        </div>
                    </div>
                    <div className="container-fluid m-1 p-0" style={{
                        display: "flex"
                    }}>
                        <div className="input-group me-1" style={{
                            display: "flex",
                            width: "100px"
                        }}>
                            <font size="4" color="black">환전 신청금 : </font>
                        </div>
                        <div className="input-group me-1" style={{
                            display: "flex",
                            width: "180px"
                        }}>
                            <input className="form-check-input sm-input" type="text" style={{width: "150px"}}
                                   placeholder={0} value={withdrawAmt} onChange={withdrawAmtChangeHandler} /> (단위 : 원)
                            &nbsp;
                        </div>
                        <div className="input-group me-1" style={{
                            display: "flex",
                            width: "100px"
                        }}>
                            <button style={{
                                width: "100px"
                            }} type="submit" className="btn btn-primary" onClick={() => {updateState()}}><b>환전신청</b></button>
                        </div>
                    </div>
                <div>
                </div>
                </div>
            </div>
        </div>
        <br></br>
        <ExchangeRequestComponents exchangeRequestList={initialState.exchangeRequest} />
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default ExchangeRequestContainer;