import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    totalList : [],
    totalCount : 0,
    gridList : [],
    gridCount : 0,
    isOpen : true,
    input:{
        page: 1,
        pageSize: 20,
        userId: "",
        userInfoLvl: "",
    }
}

export const statsSlice = createSlice({
    name: 'RecommendKeyListReducer',
    initialState,
    reducers: {
        getRecommendKeyListReducer(state, action){
            console.log("#reducers getRecommendKeyListReducer start#",
                action.payload.input.pageSize,
                action.payload.input.page)
            ;
            state.input.page = action.payload.input.pageSize === 999? 1: action.payload.input.page;
            state.input.pageSize = action.payload.input.pageSize;
        },
        setRecommendKeyListReducer(state, action) {
            console.log("#reducers setRecommendKeyListReducer start#",action.payload.gridList);
            if((state.input.page -1) * state.input.pageSize > action.payload.totalCount) {
                state.input.page = 1
            }
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.gridCount = action.payload.gridCount;
            state.gridList = action.payload.gridList;
        },
        failedGetRecommendKeyListReducer(state, action) {
            state.blackTypeCd = action.payload;
        },
        setIsOpen(state, action){
            state.isOpen = action.payload;
        }
    }
});

export const {
    getRecommendKeyListReducer,
    setRecommendKeyListReducer,
    failedGetRecommendKeyListReducer,
    setIsOpen,
} = statsSlice.actions;

export default statsSlice.reducer;