import React, {useEffect, useState} from "react";
import axios from "axios";
import BettingContainer from "../../containers/betting/BettingContainer";
import {useParams} from "react-router-dom";
const BettingList = () => {
    const param = useParams()
    return (
        <BettingContainer userId={param.userId} />
    )
}
export default BettingList;