import React, {useState, useEffect} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux"
import moment from 'moment';
import OrgSelect from "../../org/OrgSelect";
import {setSelectOrgId} from "../../../redux/reducers/user/MessageReducer";
import {getMemberUser} from "../../../redux/reducers/user/MemberReducer";

const MemberExcelModal02 = (props) => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo)
    const setParentParam = (id) =>{
        dispatch(setSelectOrgId(id))
    }


    return (
        <div className="member02_modal_background" >
            <div className="member02_modal_box" style={{overflowY:"scroll"}}>
                <div className="mebmer02_modal_body" >

                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <br></br>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table   width={"99%"}>
                            <tr>
                                <td align={"right"}>
                                    <button style={{ backgroundColor: "#3fadf7", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} >닫기</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%"}}>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>정렬기준</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"left"} width={"68%"}>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }} />
                                    기본 정렬 (삭제여부/회원구분/가입최근)
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }} />
                                    가입일 최근 순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }} />
                                    가입일 오래된 순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }} />
                                    최근활동 최근 순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }} />
                                    최근활동 오래된 순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }} />
                                    마진 많은 순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }} />
                                    마진 적은 순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }} />
                                    충전 최근순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }} />
                                    충전 오래된순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }} />
                                    충전 많은순
                                    <br></br>
                                    <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" style={{ cursor: "pointer" }} />
                                    충전 적은순
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%"}}>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>소속</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"left"} width={"68%"}>
                                   todo
                                    <br></br>
                                    * 소속을 선택하지 않을 경우 전체 회원 대상
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%"}}>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>대기 회원</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>무료 회원</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>유료 회원</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>VIP 회원</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>가라 회원</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>정산용 아이디</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>관심 회원</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />1&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />2&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />3&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />4&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />5&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />6&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />7&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />8&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />9&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />10&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />11&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />12&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />13&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />14&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />15&nbsp;
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />16&nbsp;
                                    <br></br>
                                    관심회원을 체크하면 관심회원이 아닌 경우를 제외하게 됩니다.
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>관심회원 제외</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />
                                    <br></br>
                                    이 항목을 체크하시면 위 항목의 선택과 무관하게 관심회원은 제외됩니다.
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%"}}>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>정상 회원</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>정지 회원</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%"}}>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>삭제 회원</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>미접속 일수</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input sm-input" type="text" style={{ width: "30px" }} placeholder="" />
                                    일 이상 미접속(0일경우 조건 무시)
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>로그인 횟수</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input sm-input" type="text" style={{ width: "30px" }} placeholder="" />
                                    회 미만
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>가입일 XX일 이전</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input sm-input" type="text" style={{ width: "30px" }} placeholder="" />
                                    일 이전 가입
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>가입일 XX일 이후 접속이 없는 경우</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"center"} width={"68%"}>
                                    <input className="form-check-input sm-input" type="text" style={{ width: "30px" }} placeholder="" />일
                                    <br></br>
                                    가입 이후 일정 시간 활동하다가 더이상 활동이 없는 경우
                                    <br></br>
                                    예> 30으로 설정하시면, 가입 후 30일 이내에 활동이 있었더라도 30일 이후는 발길을 끊은 경우 입니다
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%"}}>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"30%"}>엑셀 다운로드 비밀번호</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"white"}} align={"left"} width={"68%"}>
                                    <input className="form-check-input sm-input" type="text" style={{ width: "200px" }} placeholder="" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex"
                    }}>
                        <table className="table table-bordered table-sm text-center align-middle" style={{width : "99%"}}>
                            <tr>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"49.5%"} height={"40px"}>다운로드 받기</td>
                                <td width={"1%"}></td>
                                <td style={{backgroundColor:"lightgray"}} align={"center"} width={"49.5%"}>다운로드 기록보기</td>
                            </tr>
                        </table>
                    </div>




                </div>
            </div>
        </div>

    )
}
export default MemberExcelModal02;