import React, {useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";

const AdminChangePw = ({userNo}) => {

    const [pw, setPw] = useState("");
    const [confirmPw, setConfirmPw] = useState("");
    const userInfo = useSelector((state) => state.userInfo)

    const changePw = () => {
        if(pw === ""){
            alert("비밀번호를 4~10 조합으로 입력해주세요1")
        } else if (pw.length < 3){
            alert("비밀번호를 4~10 조합으로 입력해주세요2")
        } else {
            if(pw !== confirmPw){
                alert("비밀번호와 바밀번호확인이 다릅니다")
            } else {
                axios.post('/partner/admin/change_pw', {
                    user_no: userNo,
                    pw: pw,
                }).then((res) => {
                    if(res.data.isUpdated) {
                        alert("변경완료")
                    }
                    else {
                        alert("변경실패")
                    }
                })
            }
        }
    }

    const onPwHandler = (e) => {
        setPw(e.target.value);
    }
    const onConfirmPwHandler = (e) => {
        setConfirmPw(e.target.value);
    }


    return (

        <div className="modal fade" id="changePw" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" >{userInfo.id} 비밀번호 변경</h1>
                    </div>
                    <div className="modal-body">
                        <div className="mb-2">
                            <label className="col-form-label">새 비밀번호</label>
                            <input className="form-control" maxLength="10" type="text"  value={pw} onChange={onPwHandler} />
                        </div>
                        <div className="mb-2">
                            <label className="col-form-label">비밀번호 확인</label>
                            <input className="form-control" maxLength="10" type="text" value={confirmPw} onChange={onConfirmPwHandler} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">취소</button>
                        <button type="button" className="btn btn-primary" onClick={changePw}>등록</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AdminChangePw;