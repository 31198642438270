import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination2";
import {getWithdrawList, setSelectedNos, setSelectedNos3, setSelectedNos4} from "../../redux/reducers/deposit/WithdrawListReducer";
import WithdrawList from "../../components/deposit/WithdrawList";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";
import axios from "axios";
import {ArrowsExpand} from "react-bootstrap-icons";
import {NumericFormat} from "react-number-format";
import OrgSelect from "../../components/org/OrgSelect";
import {setSelectOrgId} from "../../redux/reducers/user/MessageReducer";
import InterestUser from "../../components/common/InterestUserFull";
import {getDepositList} from "../../redux/reducers/deposit/DepositListReducer";
import {insertAdminLog} from "../../lib/api";

const WithdrawListContainer = ({userId}) => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const userInfo = useSelector((state) => state.userInfo)
    const [changeFieldList, setChangeFieldList] = useState([]);
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.withdrawList)
    const userState = useSelector((state) => state.userInfoCombine)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [userGbCd, setUserGbCd] = useState("");

    const [dateType, setDateType] = useState(0);

    const [date7, setDate7] = useState('#3fadf7')
    const [date1, setDate1] = useState('#3fadf7')
    const [date0, setDate0] = useState('red')

    /*조회조건 start*/
    const [searchCd01, setSearchCd01] = useState(0); //정렬
    const [searchCd02, setSearchCd02] = useState(); //상태
    const [searchCd03, setSearchCd03] = useState(); //구분
    const [searchCd04, setSearchCd04] = useState(); //회원구분
    const [searchCd05, setSearchCd05] = useState(); //처리
    const [searchCd06, setSearchCd06] = useState(); //현재입금방식
    const [searchCd07, setSearchCd07] = useState(); //충전신청/완료처리 금액
    const [searchCd08, setSearchCd08] = useState(); //전용계좌
    const [searchCd09, setSearchCd09] = useState(); //관리자강제신청여부
    const [searchCd10, setSearchCd10] = useState(); //마킹여부
    const [searchCd11, setSearchCd11] = useState(); //사이트코드
    const [searchCd12, setSearchCd12] = useState(); //총판/가맹점
    const [searchCd13, setSearchCd13] = useState(); //전체P레벨
    const [searchCd14, setSearchCd14] = useState(); //회원그룹
    const [searchCd15, setSearchCd15] = useState(); //관심회원
    const [searchCd16, setSearchCd16] = useState(); //첫충정만보기/가입후첫충전만보기

    const [keyCd, setKeyCd] = useState("");         //셀렉트박스별 검색 종류
    const [valueCd, setValueCd] = useState("");     //검색 값

    const [userGbList, setUserGbList] = useState([]);   //회원그룹DB맵핑
    /*조회조건 end*/

    const getCmmnCd = () => {
        if(changeFieldList.length === 0){
            axios.post('/partner/admin/cmmnCdList', {
                cmmnCdId : '0028',
            }).then((res) => {
                setChangeFieldList(res.data.cmmnCdList);
            })
        }

        if(userGbList.length === 0){
            axios.post('/partner/member/getGroupSetting', {
                cmmnCdId : '0000',
            }).then((res) => {
                setUserGbList(res.data.cmmnCdList);
            })
        }
    }
    useEffect(getCmmnCd, [])

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        setSearchQuery(userId)
        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))

        //관리자활동 로그 추가
        insertAdminLog({
            id: userInfo.id,
            logContent: "환전내역"
        }).then((res) => {
            if(!res.data.isInserted) {
                alert("로그삽입실패 개발자문의")
            }
        })
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getWithdrawList({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const changeQuery = (value) => {
        setValueCd(value)
        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: value,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const onClickReset = (e) => {
        window.location.reload();
    }

    const onRefresh = () => {
        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }


    //공통정렬처리
    const dateTypeChangeHandler = (type) => {
        setDateType(type)
        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: type,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))

    }

    const search01ChangeHandler = (type) => {
        setSearchCd01(type)

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: type,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const search03ChangeHandler = (type) => {
        setSearchCd03(type)

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: type,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const search04ChangeHandler = (type) => {
        setSearchCd04(type)

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: type,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const search05ChangeHandler = (type) => {
        setSearchCd05(type)

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: type,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const search06ChangeHandler = (type) => {
        setSearchCd06(type)

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: type,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const search09ChangeHandler = (type) => {
        setSearchCd09(type)

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: type,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const search10ChangeHandler = (type) => {
        setSearchCd10(type)

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: type,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }


    const exportExcel = ()=>{
        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.

        for (let i = 0; i < initialState.totalList.length; i++) {

            let jsonObject = { // JSON 객체를 생성합니다.
                고유번호: initialState.totalList[i].no? initialState.totalList[i].no :'',
                사이트코드: initialState.totalList[i].site_cd? initialState.totalList[i].site_cd :'',
                소속: initialState.totalList[i].store_cd? initialState.totalList[i].store_cd :'',
                그룹: initialState.totalList[i].user_group_cd? initialState.totalList[i].user_group_cd :'',
                닉네임: initialState.totalList[i].nickname? initialState.totalList[i].nickname :'',
                등급: initialState.totalList[i].user_gb_cd? initialState.totalList[i].user_gb_cd :'',
                포인트등급: initialState.totalList[i].pnt_gb_cd? initialState.totalList[i].pnt_gb_cd :'',
                전체베팅차단: initialState.totalList[i].bet_block_cd? initialState.totalList[i].bet_block_cd :'',
                입금방식: initialState.totalList[i].rcpt_meth_cd? initialState.totalList[i].rcpt_meth_cd :'',
                기준일충전: (initialState.totalList[i].standard_deposit && initialState.totalList[i].standard_deposit_amt ) ? initialState.totalList[i].standard_deposit + " / "+initialState.totalList[i].standard_deposit_amt :'',
                기준일환전: (initialState.totalList[i].standard_withdraw && initialState.totalList[i].standard_withdraw_amt ) ? initialState.totalList[i].standard_withdraw + " / "+initialState.totalList[i].standard_withdraw_amt :'',
                전체마지막환전이후롤링: initialState.totalList[i].all_withdraw_ratio? initialState.totalList[i].all_withdraw_ratio :'',
                전체마지막충전대비롤링: initialState.totalList[i].all_withdraw_ratio? initialState.totalList[i].all_withdraw_ratio :'',
                스포츠: initialState.totalList[i].sports_withdraw_ratio? initialState.totalList[i].sports_withdraw_ratio :'',
                미니게임: initialState.totalList[i].mini_withdraw_ratio? initialState.totalList[i].mini_withdraw_ratio :'',
                토큰: initialState.totalList[i].token_withdraw_ratio? initialState.totalList[i].token_withdraw_ratio :'',
                인플레이: initialState.totalList[i].inplay_withdraw_ratio? initialState.totalList[i].inplay_withdraw_ratio :'',
                Kg카지노: initialState.totalList[i].casino_withdraw_ratio? initialState.totalList[i].casino_withdraw_ratio :'',
                마진: initialState.totalList[i].profit_amt? initialState.totalList[i].profit_amt :'',
                출금전: initialState.totalList[i].before_amt? initialState.totalList[i].before_amt :'',
                출금후: initialState.totalList[i].result_amt? initialState.totalList[i].result_amt :'',
                출금액: initialState.totalList[i].withdraw_amt? "-"+initialState.totalList[i].withdraw_amt :'',
                신청시점진행중베팅금: initialState.totalList[i].progress_amt? initialState.totalList[i].progress_amt :'',
                은행명: initialState.totalList[i].bank? initialState.totalList[i].bank :'',
                계좌번호: initialState.totalList[i].account? initialState.totalList[i].account :'',
                예금주: initialState.totalList[i].account_name? initialState.totalList[i].account_name :'',
                도메인IP: (initialState.totalList[i].domain && initialState.totalList[i].use_ip)  ? initialState.totalList[i].domain+" / "+initialState.totalList[i].use_ip :'',
                신청일시처리일시: initialState.totalList[i].request_dt? initialState.totalList[i].request_dt + " / "+initialState.totalList[i].complete_dt :'',
                마지막환전: initialState.totalList[i].last_withdraw? initialState.totalList[i].last_withdraw+"분전" :'',
                계좌수정: initialState.totalList[i].account_upd_dt? initialState.totalList[i].account_upd_dt :'',
                상태: initialState.totalList[i].state_cd? initialState.totalList[i].state_cd :''
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '환전내역');
        XLSX.writeFile(workBook, '환전내역.xlsx');

    }

    const userGbCdChangeHandler = (e) => {
        alert('userGbCd000 >>>>>>>>>>>> '+e.currentTarget.value);
        setUserGbCd(e.currentTarget.value);

        alert('userGbCd >>>>>>>>>>>> '+userGbCd);
        onRefresh();
    }


    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('#3fadf7')
    }

    const add7Date = () => {
        setStartDate(moment().add(-7, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setDate7('red')
        setDate1('#3fadf7')
        setDate0('#3fadf7')

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-7, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const add1Date = () => {
        setStartDate(moment().add(-1, "day").format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setDate7('#3fadf7')
        setDate1('red')
        setDate0('#3fadf7')

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().add(-1, "day").format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }
    const add0Date = () => {
        setStartDate(moment().format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        setDate7('#3fadf7')
        setDate1('#3fadf7')
        setDate0('red')

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const updateState = (state, withdrawNos) => {

        if( state == 3 || state == 4 )
        {
            if (window.confirm("취소 하시겠습니까?\n" +
                "원복이 불가능 하므로 신중하게 입력하세요 (1/3)")) {
                if (window.confirm("취소 하시겠습니까?\n" +
                    "원복이 불가능 하므로 신중하게 입력하세요 (2/3)")) {
                    if (window.confirm("취소 하시겠습니까?\n" +
                        "원복이 불가능 하므로 신중하게 입력하세요 (3/3)")) {
                        if(withdrawNos.length > 0) {
                            axios.post('/admin/withdrawList/change_state', {
                                adminNo: userInfo.id,
                                withdrawNos: withdrawNos,
                                state: state,
                                reg_ip: userInfo.ip
                            }).then((res) => {
                                alert(res.data.isUpdated? "변경완료" : "변경실패")
                                //getAllList()
                                //window.location.reload();
                                onRefresh();
                                //window.location.reload();
                            })
                        }
                        else
                        {
                            alert("항목을 선택해주세요");
                            return;
                        }
                    }
                }
            }
        }
        else
        {
            if (window.confirm("변경 하시겠습니까?"))
            {
                if(withdrawNos.length > 0) {
                    axios.post('/admin/withdrawList/change_state', {
                        adminNo: userInfo.id,
                        withdrawNos: withdrawNos,
                        state: state,
                        reg_ip: userInfo.ip
                    }).then((res) => {
                        alert(res.data.isUpdated? "변경완료" : "변경실패")
                        //getAllList()
                        //window.location.reload();
                        onRefresh();
                        //window.location.reload();
                    })
                }
                else
                {
                    alert("항목을 선택해주세요");
                    return;
                }
            }
        }

        // if (window.confirm("현재 검색결과 회원들을 " + typeNm + " 설정 하시겠습니까?\n" +
        //     "원복이 불가능 하므로 신중하게 입력하세요 (1/3)")) {
        //     if (window.confirm("현재 검색결과 회원들을 " + typeNm + " 설정 하시겠습니까?\n" +
        //         "원복이 불가능 하므로 신중하게 입력하세요 (2/3)")) {
        //         if (window.confirm("현재 검색결과 회원들을 " + typeNm + " 설정 하시겠습니까?\n" +
        //             "원복이 불가능 하므로 신중하게 입력하세요 (3/3)")) {

    }


    const updateStateMulti = (state, withdrawNos) => {

        let tmpResult = false;
        if(withdrawNos.length < 1) {
            alert("항목을 선택해주세요");
            return;
        }
        let arr = withdrawNos.sort();
        for(let i=0;i<arr.length;i++) {
            if (window.confirm("완료에 대한 최종 확인입니다.\n정말 "+arr[i]+"번 항목을 완료 처리하시겠습니까?")) {
                if(withdrawNos.length > 0) {
                    axios.post('/admin/withdrawList/change_state', {
                        adminNo: userInfo.id,
                        withdrawNos: arr[i],
                        state: state,
                        reg_ip: userInfo.ip
                    }).then((res) => {
                    })
                    continue;
                }
                else
                {
                    alert("변경실패")
                    continue;
                }
            }
        }

        window.location.reload();

    }




    const setParentParam = (id) =>{
        setSearchCd07(id)
        dispatch(setSelectOrgId(id))

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: id,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const checkHandler3 = (checked, no) => {
        if(checked) {
            dispatch(setSelectedNos3([...initialState.selectedNos3, no]))
            dispatch(getWithdrawList({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                searchGbCd: initialState.searchGbCd,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                searchCd11: initialState.searchCd11,
                searchCd12: initialState.searchCd12,
                searchCd13: initialState.searchCd13,
                searchCd14: initialState.searchCd14,
                searchCd15: initialState.searchCd15,
                searchCd16: initialState.searchCd16,
                keyCd: initialState.keyCd,
                valueCd: initialState.valueCd,
                userId: userState.userId,
                selectedNos: initialState.selectedNos,
                selectedNos3: [...initialState.selectedNos3, no],
                selectedNos4: initialState.selectedNos4,
                sessionId: userInfo.id,
                sessionLevel: userInfo.level
            }))
        }
        else {
            dispatch(setSelectedNos3(initialState.selectedNos3.filter((id) => id !== no)))
            dispatch(getWithdrawList({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                searchGbCd: initialState.searchGbCd,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                searchCd11: initialState.searchCd11,
                searchCd12: initialState.searchCd12,
                searchCd13: initialState.searchCd13,
                searchCd14: initialState.searchCd14,
                searchCd15: initialState.searchCd15,
                searchCd16: initialState.searchCd16,
                keyCd: initialState.keyCd,
                valueCd: initialState.valueCd,
                userId: userState.userId,
                selectedNos: initialState.selectedNos,
                selectedNos3: initialState.selectedNos3.filter((id) => id !== no),
                selectedNos4: initialState.selectedNos4,
                sessionId: userInfo.id,
                sessionLevel: userInfo.level
            }))

        }
    }


    const onCheckedColor = (selectedNos) => {

        //alert("selectedNos :: "+JSON.stringify(selectedNos));
        dispatch(setSelectedNos([selectedNos]))
        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: initialState.selectedNos4,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))

        /*
        const fixInput = {...input}
        fixInput.interestUser = selectedNos
        dispatch(getActiveMembers(fixInput))
         */
    }


    const checkHandler4 = (checked, no) => {
        if(checked) {
            dispatch(setSelectedNos4([...initialState.selectedNos4, no]))
            dispatch(getWithdrawList({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                searchGbCd: initialState.searchGbCd,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                searchCd11: initialState.searchCd11,
                searchCd12: initialState.searchCd12,
                searchCd13: initialState.searchCd13,
                searchCd14: initialState.searchCd14,
                searchCd15: initialState.searchCd15,
                searchCd16: initialState.searchCd16,
                keyCd: initialState.keyCd,
                valueCd: initialState.valueCd,
                userId: userState.userId,
                selectedNos: initialState.selectedNos,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: [...initialState.selectedNos4, no],
                sessionId: userInfo.id,
                sessionLevel: userInfo.level
            }))
        }
        else {
            dispatch(setSelectedNos4(initialState.selectedNos4.filter((id) => id !== no)))
            dispatch(getWithdrawList({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: searchQuery,
                startDate: startDate? startDate : initialState.startDate,
                endDate: endDate? endDate : initialState.endDate,
                userGbCd: initialState.userGbCd,
                searchGbCd: initialState.searchGbCd,
                searchCd01: initialState.searchCd01,
                searchCd02: initialState.searchCd02,
                searchCd03: initialState.searchCd03,
                searchCd04: initialState.searchCd04,
                searchCd05: initialState.searchCd05,
                searchCd06: initialState.searchCd06,
                searchCd07: initialState.searchCd07,
                searchCd08: initialState.searchCd08,
                searchCd09: initialState.searchCd09,
                searchCd10: initialState.searchCd10,
                searchCd11: initialState.searchCd11,
                searchCd12: initialState.searchCd12,
                searchCd13: initialState.searchCd13,
                searchCd14: initialState.searchCd14,
                searchCd15: initialState.searchCd15,
                searchCd16: initialState.searchCd16,
                keyCd: initialState.keyCd,
                valueCd: initialState.valueCd,
                userId: userState.userId,
                selectedNos: initialState.selectedNos,
                selectedNos3: initialState.selectedNos3,
                selectedNos4: initialState.selectedNos4.filter((id) => id !== no),
                sessionId: userInfo.id,
                sessionLevel: userInfo.level
            }))
        }
    }

    const allCheckHandler4 = (e) => {
        const checks = [];
        checks.push('01','02','03','04','05','06','07','08','09','10','11','12','13')
        dispatch(setSelectedNos4(checks))

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: checks,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const allDisableCheckHandler4 = (e) => {
        const checks = [];
        dispatch(setSelectedNos4(checks))

        dispatch(getWithdrawList({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
            userGbCd: initialState.userGbCd,
            searchGbCd: initialState.searchGbCd,
            searchCd01: initialState.searchCd01,
            searchCd02: initialState.searchCd02,
            searchCd03: initialState.searchCd03,
            searchCd04: initialState.searchCd04,
            searchCd05: initialState.searchCd05,
            searchCd06: initialState.searchCd06,
            searchCd07: initialState.searchCd07,
            searchCd08: initialState.searchCd08,
            searchCd09: initialState.searchCd09,
            searchCd10: initialState.searchCd10,
            searchCd11: initialState.searchCd11,
            searchCd12: initialState.searchCd12,
            searchCd13: initialState.searchCd13,
            searchCd14: initialState.searchCd14,
            searchCd15: initialState.searchCd15,
            searchCd16: initialState.searchCd16,
            keyCd: initialState.keyCd,
            valueCd: initialState.valueCd,
            userId: userState.userId,
            selectedNos: initialState.selectedNos,
            selectedNos3: initialState.selectedNos3,
            selectedNos4: checks,
            sessionId: userInfo.id,
            sessionLevel: userInfo.level
        }))
    }

    const updateMarking = (withdrawNo, markingYn) => {

        //alert("markingYn >>>>>>>>>>> "+markingYn);

        let markingCd = 0;
        switch (markingYn) {
            case 0 : markingCd = 1; break;
            case 1 : markingCd = 2; break;
            case 2 : markingCd = 0; break;
        }

        //alert("markingCd >>>>>>>>>>> "+markingCd);

        axios.post('/admin/withdrawList/change_marking', {
            adminNo: userInfo.id,
            withdrawNo: withdrawNo,
            markingCd: markingCd,
            reg_ip: userInfo.ip
        }).then((res) => {
            //alert(res.data.isUpdated? "변경완료" : "변경실패")
            //getAllList()
            //window.location.reload();
            onRefresh();
            //window.location.reload();
        })
    }




    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <font color={"blue"} size="3">환전내역</font>
                    &nbsp;
                    <select
                        className="form-select sm-select"
                        defaultValue={120}
                        style={{
                            width: "70px"
                        }}
                        value={initialState.pageSize === "999"? "모두" : initialState.pageSize}
                        onChange={(e) => {
                            dispatch(getWithdrawList({
                                page: initialState.page,
                                pageSize: parseInt(e.target.value),
                                order: initialState.order,
                                query: searchQuery,
                                startDate: startDate ? startDate : initialState.startDate,
                                endDate: endDate ? endDate : initialState.endDate,
                                userGbCd: initialState.userGbCd,
                                searchGbCd: initialState.searchGbCd,
                                searchCd01: initialState.searchCd01,
                                searchCd02: initialState.searchCd02,
                                searchCd03: initialState.searchCd03,
                                searchCd04: initialState.searchCd04,
                                searchCd05: initialState.searchCd05,
                                searchCd06: initialState.searchCd06,
                                searchCd07: initialState.searchCd07,
                                searchCd08: initialState.searchCd08,
                                searchCd09: initialState.searchCd09,
                                searchCd10: initialState.searchCd10,
                                searchCd11: initialState.searchCd11,
                                searchCd12: initialState.searchCd12,
                                searchCd13: initialState.searchCd13,
                                searchCd14: initialState.searchCd14,
                                searchCd15: initialState.searchCd15,
                                searchCd16: initialState.searchCd16,
                                keyCd: initialState.keyCd,
                                valueCd: initialState.valueCd,
                                userId: userState.userId,
                                selectedNos: initialState.selectedNos,
                                selectedNos3: initialState.selectedNos3,
                                selectedNos4: initialState.selectedNos4,
                                sessionId: userInfo.id,
                                sessionLevel: userInfo.level
                            }))
                        }}
                    >
                        <option value={30}>30개</option>
                        <option value={60}>60개</option>
                        <option value={120}>120개</option>
                        <option value={240}>240개</option>
                        <option value={360}>360개</option>
                        <option value={500}>500개</option>
                        <option value={1000}>1000개</option>
                        <option value={5000}>5000개</option>
                        <option value={10000}>10000개</option>
                    </select>
                    <table   width={"91%"}>
                        <tr>
                            <td align={"right"}>
                                <button style={{
                                    width: "80px"
                                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                                &nbsp;
                                <button style={{
                                    width: "120px", backgroundColor :"gray"
                                }} type="submit" className="btn btn-primary" onClick={onClickReset}>새로고침(자동보류)</button>
                            </td>
                        </tr>
                    </table>

                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray"
                }}>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray" , width:"480px"
                    }}>
                        <button style={{ backgroundColor: "#5dc434", border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "10px"}} onClick={exportExcel}>엑셀 다운로드</button>
                        <button style={{ backgroundColor: date7, border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add7Date}>일주일</button>
                        <button style={{ backgroundColor: date1, border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content", marginRight: "5px"}} onClick={add1Date}>어제</button>
                        <button style={{ backgroundColor: date0, border: "none", color: "white" ,fontSize: "12px", height: "20px", width: "fit-content"}} onClick={add0Date}>오늘</button>
                        &nbsp;&nbsp;
                        <input className="form-control sm-date" type="date" value={startDate} onChange={startDateHandler} style={{width:"110px"}}/>
                        &nbsp;~&nbsp;
                        <input className="form-control sm-date" type="date" value={endDate} onChange={endDateHandler} style={{width:"110px"}}/>
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"350px"
                    }}>
                        <ArrowsExpand />정렬 :
                        <input className="form-check-input"  type="radio" name="inlineRadio01" id="inlineRadio01" value={dateType} style={{ cursor: "pointer" }}
                               onChange={() => dateTypeChangeHandler(1)} defaultChecked={true}/> 신청일 기준
                        <input className="form-check-input"  type="radio" name="inlineRadio01" id="inlineRadio01" value={dateType} style={{ cursor: "pointer" }}
                               onChange={() => dateTypeChangeHandler(2)} /> 처리일 기준
                        <input className="form-check-input"  type="radio" name="inlineRadio01" id="inlineRadio01" value={dateType} style={{ cursor: "pointer" }}
                               onChange={() => dateTypeChangeHandler(3)} /> 금액 큰 순
                        <input className="form-check-input"  type="radio" name="inlineRadio01" id="inlineRadio01" value={dateType} style={{ cursor: "pointer" }}
                               onChange={() => dateTypeChangeHandler(4)} /> 금액 적은 순
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"270px"
                    }}>
                        <ArrowsExpand />상태 :
                        <ArrowsExpand />상태 :
                        <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" value={searchCd01} style={{ cursor: "pointer" }}
                               onChange={() => search01ChangeHandler(0)} defaultChecked={true}/> 전체
                        <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" value={searchCd01} style={{ cursor: "pointer" }}
                               onChange={() => search01ChangeHandler(9)} /> 대기
                        <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" value={searchCd01} style={{ cursor: "pointer" }}
                               onChange={() => search01ChangeHandler(2)} /> 완료
                        <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" value={searchCd01} style={{ cursor: "pointer" }}
                               onChange={() => search01ChangeHandler(1)} /> 확인중
                        <input className="form-check-input"  type="radio" name="inlineRadio02" id="inlineRadio02" value={searchCd01} style={{ cursor: "pointer" }}
                               onChange={() => search01ChangeHandler(3)} /> 취소
                    </div>
                    <div className="container-fluid p-0 mx-1" style={{
                        display: "flex",border:"1px solid lightgray", width:"270px"
                    }}>
                        [ 회원구분 :
                        <input className="form-check-input"  type="radio" name="inlineRadio4" id="inlineRadio4" value={searchCd03} style={{ cursor: "pointer" }}
                               onChange={() => search03ChangeHandler("")} defaultChecked={true}/> 전체
                        <input className="form-check-input"  type="radio" name="inlineRadio4" id="inlineRadio4" value={searchCd03} style={{ cursor: "pointer" }}
                               onChange={() => search03ChangeHandler("01")} /> 일반회원
                        <input className="form-check-input"  type="radio" name="inlineRadio4" id="inlineRadio4" value={searchCd03} style={{ cursor: "pointer" }}
                               onChange={() => search03ChangeHandler("05")} /> 가라회원
                    </div>
                </div>

            </div>

            <div className="container-fluid p-0 mx-1" style={{
                display: "flex",border:"1px solid lightgray"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"300px", height:"23px"
                }}>
                    [ 관리자강제신청여부 :
                    <input className="form-check-input"  type="radio" name="inlineRadio9" id="inlineRadio9" value={searchCd09} style={{ cursor: "pointer" }}
                           onChange={() => search09ChangeHandler("")} defaultChecked={true}/> 전체&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio9" id="inlineRadio9" value={searchCd09} style={{ cursor: "pointer" }}
                           onChange={() => search09ChangeHandler('Y')}/> 유저신청&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio9" id="inlineRadio9" value={searchCd09} style={{ cursor: "pointer" }}
                           onChange={() => search09ChangeHandler('N')}/> 관리자강제신청 ]
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"200px"
                }}>
                    [ 마킹여부 :&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio10" id="inlineRadio10" value={searchCd10} style={{ cursor: "pointer" }}
                           onChange={() => search10ChangeHandler("")} defaultChecked={true} /> 전체&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio10" id="inlineRadio10" value={searchCd10} style={{ cursor: "pointer" }}
                           onChange={() => search10ChangeHandler('Y')}/> 마킹&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio10" id="inlineRadio10" value={searchCd10} style={{ cursor: "pointer" }}
                           onChange={() => search10ChangeHandler('N')}/> 마킹없음 ]
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"450px"
                }}>
                    <ArrowsExpand />현재입금방식 :
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("")} defaultChecked={true}/> 전체&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("01")} /> 현금&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("02")} /> 가상&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("03")} /> 가상2&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio05" id="inlineRadio05" value={searchCd05} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("99")} />코인(전체)&nbsp;&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("04")} /> 코인[A]&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("05")} /> 코인[B]&nbsp;
                    <input className="form-check-input"  type="radio" name="inlineRadio5" id="inlineRadio5" value={searchCd04} style={{ cursor: "pointer" }}
                           onChange={() => search04ChangeHandler("06")} /> 코인[C]
                </div>

            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex",border:"1px solid lightgray"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"400px", height:"23px"
                }}>
                    검색
                    <div style={{zIndex : 999}}>
                        <OrgSelect setChildParam = {setParentParam} id = {1}/>
                    </div>
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"120px"
                }}>
                    <input className="form-check-input" type="checkbox" name="inlineRadio5" id="inlineRadio1" /> 하위 포함 검색
                    &nbsp;
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"390px"
                }}>

                    <select
                        className="form-select sm-select"
                        defaultValue={999}
                        style={{
                            width: "150px"
                        }}
                        value={initialState.searchGb === ""? "0" : initialState.searchGb}
                        onChange={e =>
                            dispatch(getWithdrawList({
                                page: initialState.page,
                                pageSize: initialState.pageSize,
                                order: initialState.order,
                                query: searchQuery,
                                startDate: startDate ? startDate : initialState.startDate,
                                endDate: endDate ? endDate : initialState.endDate,
                                userGbCd: initialState.userGbCd,
                                searchGbCd: initialState.searchGbCd,
                                searchCd01: initialState.searchCd01,
                                searchCd02: initialState.searchCd02,
                                searchCd03: initialState.searchCd03,
                                searchCd04: initialState.searchCd04,
                                searchCd05: initialState.searchCd05,
                                searchCd06: initialState.searchCd06,
                                searchCd07: initialState.searchCd07,
                                searchCd08: initialState.searchCd08,
                                searchCd09: initialState.searchCd09,
                                searchCd10: initialState.searchCd10,
                                searchCd11: initialState.searchCd11,
                                searchCd12: initialState.searchCd12,
                                searchCd13: initialState.searchCd13,
                                searchCd14: initialState.searchCd14,
                                searchCd15: initialState.searchCd15,
                                searchCd16: initialState.searchCd16,
                                keyCd: e.target.value,
                                valueCd: initialState.valueCd,
                                userId: userState.userId,
                                selectedNos: initialState.selectedNos,
                                selectedNos3: initialState.selectedNos3,
                                selectedNos4: initialState.selectedNos4,
                                sessionId: userInfo.id,
                                sessionLevel: userInfo.level
                            }))
                        }
                    >
                        <option value={"01"}>닉네임 또는 아이디</option>
                        <option value={"02"}>코인사명</option>
                        <option value={"03"}>코인아이디</option>
                        <option value={"04"}>기준일 충전금액(~이상)</option>
                        <option value={"05"}>기준일 충전금액(~미만)</option>
                        <option value={"06"}>닉네임</option>
                        <option value={"07"}>아이디</option>
                        <option value={"08"}>이름</option>
                        <option value={"09"}>가입입금자명</option>
                        <option value={"10"}>입금자명</option>
                        <option value={"11"}>금액</option>
                        <option value={"12"}>가맹점명</option>
                        <option value={"13"}>IP</option>
                        <option value={"14"}>충전번호</option>
                        <option value={"15"}>정산코드 조회</option>
                    </select>
                    &nbsp;
                    <input style={{
                        width: "180px"
                    }} type="text" className="form-control" id="search_edit" placeholder="검색조건을 입력하세요." onBlur={(e) => {
                        changeQuery(e.target.value)
                    }} onKeyDown={inputPress} />
                </div>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"870px", height:"23px"
                }}>
                    P등급 :
                    <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "10px", height: "16px", width: "fit-content"}} onClick={allCheckHandler4}>전체 P등급</button>
                    <button className="ms-2" style={{ backgroundColor: "#CECECE", border: "1px solid black",fontSize: "10px", height: "16px", width: "fit-content"}} onClick={allDisableCheckHandler4}>전체 P해제</button>
                    &nbsp;&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '01').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '01') }}/>
                    1-1 [1]&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '02').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '02') }}/>
                    1-2 [1]&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '03').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '03') }}/>
                    1-3 [1]&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '04').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '04') }}/>
                    1-4 [1]&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '05').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '05') }}/>
                    1-5 [1]&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '06').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '06') }}/>
                    2 [2]&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '07').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '07') }}/>
                    2-3 [2]&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '08').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '08') }}/>
                    3 [3]&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '09').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '09') }}/>
                    4 [4]&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '10').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '10') }}/>
                    5 [5]&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '11').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '11') }}/>
                    6 [6]&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '12').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '12') }}/>
                    6-2 [1]&nbsp;
                    <input className="form-check-input" type="checkbox" name="inlineRadio14" id="inlineRadio14"
                           style={{cursor:"pointer"}}
                           checked={initialState.selectedNos4.filter((no) => no === '13').length > 0}
                           onChange={(e) => {checkHandler4(e.currentTarget.checked, '13') }}/>
                    6-3 [1]&nbsp;
                </div>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex",border:"1px solid lightgray"
            }}>
                <div className="container-fluid p-0 mx-1" style={{
                    display: "flex",border:"1px solid lightgray", width:"850px", height:"23px"
                }}>
                    <ArrowsExpand />회원그룹 :
                    {userGbList.map(item => (
                        <React.Fragment key={item.no}>
                            &nbsp;<input className="form-check-input" type="checkbox" name="inlineRadio1" id="inlineRadio1"
                                         style={{cursor:"pointer"}}
                                         checked={initialState.selectedNos3.filter((no) => no === item.no).length > 0}
                                         onChange={(e) => {checkHandler3(e.currentTarget.checked, item.no) }}/>
                            <font  style={{backgroundColor: "lightgray"  }} color={ "yellow"}>
                                {item.no}
                            </font>&nbsp;{item.group_nm}&nbsp;&nbsp;
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="container-fluid p-0 mx-1" style={{
                display: "flex",border:"1px solid lightgray"
            }}>
                <div style={{display: "flex", paddingRight: "5px", paddingLeft: "5px"}}>
                    <InterestUser onCheckedColor={onCheckedColor} />
                </div>
            </div>
            <br></br>
        </div>
        <WithdrawList withdrawList={initialState.withdrawList} onRefresh={onRefresh} updateState={updateState} updateStateMulti={updateStateMulti} initialState={initialState} updateMarking={updateMarking}/>
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default WithdrawListContainer