import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {NumericFormat} from "react-number-format";
import {openUserSimpleInfo} from "../../redux/reducers/userInfo";
const PointList = ({RtmSttlItmList, onRefresh}) => {
    const dispatch = useDispatch()


    return (
        <div className="common">
            <div style={{
                paddingLeft: "1px"
            }}>


                <div className="commonT container-fluid p-0 mx-1" style={{
                    display: "flex"
                }}>
                    <table className="table table-bordered table-sm text-center align-middle calculate">
                        <thead>
                        <tr>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>순서</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>부본사</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>총판/가맹</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>아이디</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>닉네임</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>회원구분</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>로그구분</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>베팅상세</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>정산비율설정</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>입/출/베팅금액</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>적용비율</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>추가금액</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>머니구분</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>일시</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>관련번호</th>
                            <th style={{ backgroundColor: "#E6E6E6" ,border: "1px solid #D7D4CF",verticalAlign: "middle"}}>메모</th>
                        </tr>
                        </thead>
                        {!RtmSttlItmList.length && (
                            <tbody key={RtmSttlItmList.no}>
                            <tr>
                                <td colSpan="16">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {RtmSttlItmList.map((RtmSttlItmList, index) => (
                            <tbody key={RtmSttlItmList.no}>
                            <tr>
                                <td align={"center"}>{RtmSttlItmList.no}</td>
                                <td align={"center"}>{RtmSttlItmList.store_nm}</td>
                                <td align={"center"}>{RtmSttlItmList.psnl_nm} / {RtmSttlItmList.mrch_nm}</td>
                                <td align={"center"}>{RtmSttlItmList.id}</td>
                                <td align={"center"}>{RtmSttlItmList.nickname}</td>
                                <td align={"center"}>{RtmSttlItmList.user_gb_cd}</td>
                                <td align={"center"}>-</td>
                                <td align={"center"}>-</td>
                                <td align={"center"}>{RtmSttlItmList.ratio_rtm}</td>
                                <td align={"center"}>
                                    <NumericFormat className="ms-1" value={RtmSttlItmList.money} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td align={"center"}>{RtmSttlItmList.ratio_deposit_withdraw}</td>
                                <td align={"center"}>-</td>
                                <td align={"center"}>-</td>
                                <td align={"center"}>{RtmSttlItmList.createdAt}</td>
                                <td align={"center"}>{RtmSttlItmList.reference_no}</td>
                                <td align={"center"}>-</td>

                            </tr>
                            </tbody>
                        ))}
                    </table>

                </div>

            </div>
        </div>

    )
}

export default PointList;