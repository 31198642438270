import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    isOpenColor: false,//관심회원등록 모달
    pageName : "",

    user : {},
    cmCd0029: [],
    cmCd0033:[],

    orgNm1:"",
    orgNm2:"",
    orgNm3:"",

    input: {
        userId :''
    },

    //게임머니지급
    payGameMoney_memo : "",
    payGameMoney_gameMoneyAmt : 0,

    //포인트지급
    payPoint_pointTypeCd : "11",
    payPoint_point : 0,
    payPoint_memo : "",

    //충전강제신청
    regForceDeposite_depositAmt : 0,

    //환전강제신청
    regForceWithdraw_withdrawAmt : 0,

    //kg카지노머니지급
    payKgCasinoMoney_kgCasinoMoneyAmt : 0,
    payKgCasinoMoney_kgCasinoMoneyMemo : "",

    //복권지급
    payLottery_lotteryTypeCd : "01",
    payLottery_lotteryName : "",
    payLottery_sheets : 0,

    //쿠폰지급
    payCoupon_payCouponAmt : 0,
    payCoupon_payCouponEtc : "",
    payCoupon_sheet : 0,

    //마진목록
    marginList: [],
    searchStartDate: ""

}

export const statsSlice = createSlice({
    name: 'initialState.user?',
    initialState,
    reducers: {
        getMemberInformationSimpleModalReducer(state, action) {
            console.log("#reducers getMemberInformationSimpleModalList start#", action.payload.input);
        },
        setMemberInformationSimpleModalReducer(state, action) {
            console.log("#reducers setMemberInformationSimpleModalList start#", action.payload.userDetail);
            state.user = action.payload.userDetail;
            state.cmCd0029 = action.payload.cmCd0029;
            state.cmCd0033 = action.payload.cmCd0033;
            state.orgNm1 = action.payload.orgNm1;
            state.orgNm2 = action.payload.orgNm2;
            state.orgNm3 = action.payload.orgNm3;
            state.marginList = action.payload.marginList;
        },
        failedGetMemberInformationSimpleModalReducer(state, action) {
            console.log("#reducers failedGetMemberInformationSimpleModalReducer start#");
        },
        setPayGameMoney_memo(state, action){
            state.payGameMoney_memo = action.payload;
        },
        setPayGameMoney_gameMoneyAmt(state, action){
            state.payGameMoney_gameMoneyAmt = action.payload;
        },
        setPayPoint_pointTypeCd(state, action){
            state.payPoint_pointTypeCd = action.payload;
        },
        setPayPoint_point(state, action){
            state.payPoint_point = action.payload;
        },
        setPayPoint_memo(state, action){
            state.payPoint_memo = action.payload;
        },
        setRegForceDeposite_depositAmt(state, action){
            state.regForceDeposite_depositAmt = action.payload;
        },
        setRegForceWithdraw_withdrawAmt(state, action){
            state.regForceWithdraw_withdrawAmt = action.payload;
        },
        setPayKgCasinoMoney_kgCasinoMoneyAmt(state, action){
            state.payKgCasinoMoney_kgCasinoMoneyAmt = action.payload;
        },
        setPayKgCasinoMoney_kgCasinoMoneyMemo(state, action){
            state.payKgCasinoMoney_kgCasinoMoneyMemo = action.payload;
        },
        setPayLottery_lotteryTypeCd(state, action){
            state.payLottery_lotteryTypeCd = action.payload;
        },
        setPayLottery_lotteryName(state, action){
            state.payLottery_lotteryName = action.payload;
        },
        setPayLottery_sheets(state, action){
            state.payLottery_sheets = action.payload;
        },
        setPayCoupon_payCouponAmt(state, action){
            state.payCoupon_payCouponAmt = action.payload;
        },
        setPayCoupon_payCouponEtc(state, action){
            state.payCoupon_payCouponEtc = action.payload;
        },
        setPayCoupon_sheet(state, action){
            state.payCoupon_sheet = action.payload;
        },
        setIsModalOpen(state, action){
            state.isModalOpen = action.payload;
        },
        setPageName(state, action) {
            state.pageName = action.payload;
        },
        setIsOpenColor(state,action){
            state.isOpenColor = action.payload;
        },
    }
});

export const {
    getMemberInformationSimpleModalReducer,
    setMemberInformationSimpleModalReducer,
    failedGetMemberInformationSimpleModalReducer,
    setIsOpenColor,
    setIsModalOpen,
    setPageName,
    setPayGameMoney_memo,
    setPayGameMoney_gameMoneyAmt,
    setPayPoint_pointTypeCd,
    setPayPoint_point,
    setPayPoint_memo,
    setRegForceDeposite_depositAmt,
    setRegForceWithdraw_withdrawAmt,
    setPayKgCasinoMoney_kgCasinoMoneyAmt,
    setPayKgCasinoMoney_kgCasinoMoneyMemo,
    setPayLottery_lotteryTypeCd,
    setPayLottery_lotteryName,
    setPayLottery_sheets,
    setPayCoupon_payCouponAmt,
    setPayCoupon_payCouponEtc,
    setPayCoupon_sheet,
} = statsSlice.actions;

export default statsSlice.reducer;