import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const exchangeRequestSlice = createSlice({
    name: 'exchangeRequest',
    initialState: {
        isLoading: false,
        error: null,
        exchangeRequest: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        sortColumn: "",
        sortOrder: "",
        startDate: moment().subtract(364,"day").format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "",
        sessionId: "",
        countList: {},
    },
    reducers: {
        getExchangeRequest(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.sortColumn = action.payload.sortColumn;
            state.sortOrder = action.payload.sortOrder;
            state.sessionId = action.payload.sessionId;
        },
        setExchangeRequest(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.exchangeRequest = action.payload.result;
            state.totalList = action.payload.totalList;
            state.countList = action.payload.countList;
        },
        failedGetExchangeRequest(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { getExchangeRequest, setExchangeRequest, failedGetExchangeRequest } = exchangeRequestSlice.actions;

export default exchangeRequestSlice.reducer;