
import React from "react";

const InplayUserByGameStcsPage = () => {
    return (
        <div>
            <h7>[inplay]회원별게임통계</h7>
        </div>

    )
}
export default InplayUserByGameStcsPage;