import React from 'react';
import WithdrawListContainer from "../../containers/deposit/WithdrawListContainer";
import {useParams} from "react-router-dom";


const WithdrawList = () => {
    const param = useParams()
    return (
        <div>
            <WithdrawListContainer userId={param.userId}/>
        </div>
    );
};

export default WithdrawList;